import classes from "./PageProgress.module.css";

interface Props {
  name: string;
  onClick: () => void;
  isActive?: boolean;
  progress?: "waiting" | "in-progress" | "completed";
  disabled?: boolean;
}

function PageProgress(props: Props) {
  const containerCSSClass = `${classes.container}${
    props.disabled ? ` ${classes.disabled}` : ""
  }`;

  const headingCSSClass = `${classes.heading}${
    props.isActive ? ` ${classes.active}` : ""
  }`;

  return (
    <div className={containerCSSClass} onClick={props.onClick}>
      <h5 style={{fontSize:"0.84em"}} className={headingCSSClass}>{props.name}</h5>
      <div className={classes.bar}>
        <div className={classes[props.progress || "waiting"]}></div>
      </div>
    </div>
  );
}

export default PageProgress;
