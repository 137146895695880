import { useForm } from "react-hook-form";
import InputField from "../../../users/components/inputField";
import {useLoginMutation} from "../../../../apis/authService";
import lang from "../../../../utils/language/french"
import React from "react";

const LoginForm = ({user, setShowAuthModal}) => {
  const [isLoading, seIsLoading] = React.useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: user.email
    },
  });
  const loginMutation = useLoginMutation();
  const onSubmit = async (data) => {
    seIsLoading(true);
    try {
     const userRes =  await loginMutation.mutateAsync(data);
      seIsLoading(false);
      setShowAuthModal(userRes.user.id)
    } catch (err) {
      seIsLoading(false);
      console.error(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <InputField
          key="email"
          label={lang.common.email}
          name="email"
          type="email"
          placeholder={lang.common.email}
          register={register}
          errors={errors}
          validation={{
            required: "L'e-mail est requis",
            pattern: {
              value: /^\S+@\S+\.\S+$/,
              message: "Format d'e-mail invalide",
            },
          }}
        />
      </div>
      <div className="mb-3">
        <InputField
          label={lang.admin.password}
          name="password"
          type="password"
          placeholder={lang.admin.passwordPlaceholder}
          register={register}
          errors={errors}
          validation={{
            required: "Le mot de passe est requis",
            minLength: {
              value: 8,
              message: "Le mot de passe doit comporter au moins 8 caractères",
            },
          }}
        />
      </div>
      <button type="submit" className="btn btn-primary w-100 mt-3">
        {isLoading ? <div style={{height:'22px', width:'22px'}} className="spinner-border text-light" />: lang.admin.login}
      </button>
    </form>
  );
};

export default LoginForm;
