import { useState } from "react";
import validator from "validator";

import { credentialsActions } from "../../../../store/userDataSlices/credentialsSlice";
import { useAppDispatch } from "../../../../store/stateHooks";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import TextField from "../../../UI/TextField";
import QuestionError from "../../../UI/QuestionError";

interface Props {
  value?: string;
}

function Email(props: Props) {
  const [error, setError] = useState<string>();
  const dispatch = useAppDispatch();

  function registerAnswerHandler(value: string) {
    dispatch(credentialsActions.setEmail(value));
  }

  function checkAnswerHandler(value?: string) {
    setError(undefined);
    if (!value) {
      return;
    }

    if (!validator.isEmail(value)) {
      setError("Veuillez fournir un e-mail valide.");
      return;
    }

    registerAnswerHandler(value);
  }

  return (
    <QuestionBox targetState="credentials" action="setEmail" scrollToIt={true}>
      <FieldLabel>Votre e-mail</FieldLabel>
      <TextField
        placeHolder="E-mail"
        type="email"
        getState={checkAnswerHandler}
        value={props.value ? props.value : undefined}
      >
        {error && <QuestionError error={error} />}
      </TextField>
    </QuestionBox>
  );
}

export default Email;
