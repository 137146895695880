import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ReactComponent as SouscrireSvg } from '../../../icons/Souscrire.svg';
import { ReactComponent as JeSouhSvg } from '../../../icons/JeSouh.svg';
import { ReactComponent as PrendreSvg } from '../../../icons/Prendre.svg';

export default function OfferPropoAssu() {
    return (
        <>
            <Box
                sx={{
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "#37474F",
                    pb: 0.9,
                    textAlign: "left",
                    mt: 5,
                }}
            >
                À propos FMA assurances
            </Box>
            <Box sx={{
                color: "#69757B",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: "left",
                mt: 2,
            }}>
                <Typography>
                    L'offre d'assurance avec le code , intitulée "" proposée par ,
                    offre une couverture pour divers avantages. Le prix mensuel de
                    cette assurance est de €.
                </Typography>
                <Typography sx={{ my: 2 }}>
                    offre une couverture complète pour les particuliers,
                    garantissant protection et tranquillité d'esprit. Avec ce plan
                    d'assurance, vous pouvez profiter d'une large gamme d'avantages
                    adaptés à vos besoins. Que ce soit pour les frais médicaux,
                    l'hospitalisation ou d'autres services de santé, vous couvre.
                </Typography>
                <Typography>
                    Le plan d'assurance propose un prix mensuel compétitif de€, ce
                    qui en fait une option abordable pour les particuliers
                    recherchant une couverture fiable. , un fournisseur d'assurance
                    réputé, veille à ce que vous receviez le soutien et l'assistance
                    dont vous avez besoin chaque fois que vous en avez besoin.
                </Typography>
                <Typography sx={{ my: 2 }}>
                    Avec , vous pouvez faire face en toute confiance à toutes les
                    circonstances imprévues, sachant que vous bénéficiez d'une
                    couverture d'assurance fiable. Ce plan vise à vous offrir une
                    sécurité financière et à vous aider à maintenir votre bien-être
                    général.
                </Typography>
            </Box>
            <Stack
                direction={{ sm: 'column', md: 'row' }}
                spacing={3}>
                <Button
                    variant="contained"
                    sx={{ borderRadius: 12.5, height: '53px', px: 4, mt: { sm: 2, md: 0 } }}
                    color="primary"
                    startIcon={<SouscrireSvg />}>
                    Souscrire maintenant
                </Button>
                <Button
                    variant="contained"
                    sx={{ bgcolor: '#32DEAC', borderRadius: 12.5, height: '53px', px: 4, my: 1 }}
                    startIcon={<JeSouhSvg />}>
                    Je souhaite un rappel
                </Button>
                <Button
                    startIcon={<PrendreSvg />}
                    sx={{ borderRadius: 12.5, height: '53px', px: 4, mt: { sm: 2, md: 0 } }}>
                    Prendre rendez-vous
                </Button>
            </Stack>

        </>
    )
}
