import {
  IMembers,
  membersActions,
} from "../../../../store/userDataSlices/membersSlice";
import { useAppDispatch } from "../../../../store/stateHooks";
import getRadioFieldRender from "../../../helpers/getRadioFieldRender";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import RadioFieldGroup from "../../../UI/RadioFieldGroup";

interface Props {
  value?: "Homme" | "Femme" | "";
}

function PartnerGender(props: Props) {
  const dispatch = useAppDispatch();

  const genders = ["Homme", "Femme"];

  function answerHandler(value: string) {
    dispatch(
      membersActions.setPartnerGender(value as IMembers["partnerGender"])
    );
  }

  return (
    <QuestionBox
      targetState="members"
      action="setPartnerGender"
      scrollToIt={true}
    >
      <FieldLabel>Passons à votre conjoint(e), il/elle est</FieldLabel>
      <RadioFieldGroup
        type="vertical"
        items={genders}
        renderItem={getRadioFieldRender("vertical", [
          "manOutline",
          "womanOutline",
        ])}
        getSelectedValue={answerHandler}
        defaultSelected={props.value ? genders.indexOf(props.value) : undefined}
      />
    </QuestionBox>
  );
}

export default PartnerGender;
