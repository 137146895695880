export function getWarrantyLevelName(level) {
  switch (level) {
    case "Minimum":
      return 1;
    case "Moyen":
      return 2;
    case "Fort":
      return 3;
    case "Maximum":
      return 4;
    default:
      return 1;
  }
}
