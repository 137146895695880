import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ManageUsersQueriesTable from "./ManageUsersQueriesTable";
import { useAppSelector } from "../../../store/stateHooks";
import lang from "../../../utils/language/french"

function ManageUsersQueries() {
  const [data, setData] = useState([]);
  const SERVER_URL = process.env.REACT_APP_API_SERVER;
  const { auth } = useAppSelector((state) => state.authCredential);
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.data.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${SERVER_URL}/api/v1/queries/get-all-queries/${auth.data.admin_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.common.manageUsersQueries} </h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">{lang.common.home}</Link>
              </li>
              <li className="breadcrumb-item active">{lang.common.manageUsersQueries}</li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ManageUsersQueriesTable tbody={data} />
          </div>
        </div>
        {/* <!-- Admin Delete Confirmation Modal --> */}
        <div class="modal fade" id="deleteConfirmation">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body p-4 mt-2">
                <p style={{ fontWeight: "400", fontSize: "14px" }}>
                  Are you sure you want to delete this record?
                </p>
                <div className="mt-2">
                  <button type="button" class="btn btn-primary mx-1">
                    Yes! Delete
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger mx-2"
                    data-bs-dismiss="modal"
                    id="closeModal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ManageUsersQueries;
