import { PropsWithChildren } from "react";

import Icon from "./Icon";

import classes from "./FieldLabel.module.css";

function FieldLabel(props: PropsWithChildren) {
  return (
    <h3
      className={classes.container}
      style={{
        paddingBottom: "2rem",
        fontSize: "18.17px",
        fontWeight: "700",
        marginBottom: "0px",
      }}
    >
      <Icon className={classes.icon} name="arrowForwardOutline" />
      <span className={classes.heading}>{props.children}</span>
    </h3>
  );
}

export default FieldLabel;
