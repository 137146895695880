import { useState } from "react";

import Icon from "./Icon";
import classes from "./RadioField.module.css";

export type RadioFieldGetState = (state: {
  value: string;
  index: number;
  isSelected: boolean;
}) => void;

interface Props {
  type: "horizontal" | "vertical";
  text: string;
  index?: number;
  staySelected?: boolean;
  icon?: string;
  textAlign?: "center" | "right" | "left";
  onClick?: () => void;
  getState?: RadioFieldGetState;
}

function RadioField(props: Props) {
  const [isSelected, setIsSelected] = useState(props.staySelected || false);

  const { text, index, onClick, getState } = props;

  let containerCSSClass = `${classes.container} ${
    classes[`container-${props.type}`]
  }`;

  let textCSSClass = "";
  if (props.textAlign) {
    textCSSClass = `${classes[`text-${props.textAlign}`]}`;
  }

  // useEffect(() => {
  //   if (staySelected !== undefined) {
  //     setIsSelected(staySelected);
  //   }
  // }, [staySelected]);

  function clickHandler() {
    if (onClick) {
      onClick();
    }

    if (index !== undefined && getState) {
      getState({
        value: text,
        index,
        isSelected: true,
      });
    }
  }

  return (
    <div
      className={`${containerCSSClass}${
        props.textAlign ? " " + textCSSClass : ""
      } ${isSelected ? classes.selected : ""} radioOption`}
      onClick={clickHandler}
    >
      {props.icon && <Icon name={props.icon} className={classes.icon} />}
      <span className={classes.text}>{text}</span>
    </div>
  );
}

export default RadioField;
