import { useState } from "react";
import { RxDashboard } from "react-icons/rx";

import { MdLogout, MdOutlineQuiz } from "react-icons/md";
import userLogo from "../../Admin/assets/img/user.png";
import { CgProfile } from "react-icons/cg";
import { FaHome } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { removeSessionData } from "../../../apis/auth.atom";
import { useAppDispatch, useAppSelector } from "../../../store/stateHooks";
import lang from "../../../utils/language/french";
import { UserAuthActions } from "../../../store/appStateSlices/userAuthSlice";
import { navigationActions } from "../../../store/appStateSlices/navigationSlice";
import { SlArrowLeft } from "react-icons/sl";
import { RiArrowLeftSLine } from "react-icons/ri";
import { TbEdit } from "react-icons/tb";
import { MdOutlineLock } from "react-icons/md";
import { FaRegFileLines } from "react-icons/fa6";

import { RiUserLine } from "react-icons/ri";
import { BiFile } from "react-icons/bi";
import { FaArrowsRotate } from "react-icons/fa6";

const Sidebar = ({ isOpen, setIsOpen }) => {

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleSubMenu = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  function signOut() {
    const user = {
      auth: false,
    };
    dispatch(UserAuthActions.setUserAuth(user));
    removeSessionData();
    navigate("/");
  }
  const location = useLocation();
  function restartPage() {
    dispatch(navigationActions.setActiveFormPage(1));
  }
  return (
    <>

      <aside
        id="sidebar"
        className="sidebar d-flex flex-column mt-4"
      >

        <div className="toggleBtnScss" onClick={() => setIsOpen(!isOpen)}>
          {/* <i className="bi bi-list toggle-sidebar-btn"></i> */}
          <RiArrowLeftSLine className="" />
        </div>
        <div className="sideBarUSerProf">

          <img
            src={user.image == null ? userLogo : user.image}
            alt="Profile"
            className="rounded-circle "
          />
          <div className=" sideBarUSerInfo d-flex flex-column ">
            <span className="">
              {user.fullName}
            </span>
            <span className="emaiTextS">
              {user.email}
            </span>
          </div>
        </div>
        {/* desktop menu  */}
        <ul className="sidebar-nav flex-grow-1 desktopsideMenus" id="sidebar-nav">
          {sidebarcontent.map((menu) => (
            <li className="nav-item" key={menu.path}>
              {menu.submenu ? (
                <>
                  <div
                    // href="#!"
                    className={`nav-link dropsA ${activeMenu === menu.name ? "" : "collapsed"
                      }`}
                    onClick={() => toggleSubMenu(menu.name)}
                  >
                   <menu.icon style={{ fontSize: '20px' }} />
                    <span>{menu.name}</span>
                    <i
                      className={`bi ${activeMenu === menu.name
                        ? "bi-chevron-up text-white"
                        : "bi-chevron-down"
                        } ms-auto`}
                    ></i>
                  </div>
                  <ul
                    className={`submenu ${activeMenu === menu.name ? "active" : "collapse"
                      }`}
                  >
                    {menu.submenu.map((submenuItem) => (
                      <li className="mt-2">
                        <Link
                          to={submenuItem.path}
                          className={`nav-link ${location.pathname === submenuItem.path
                            ? ""
                            : "collapsed"
                            }`}
                        >
                         <submenuItem.icon style={{ fontSize: '20px' }} />
                          <span>{submenuItem.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )
                :
                <Link
                  to={menu.path}
                  onClick={() => menu.check && restartPage()}
                  className={`nav-link g-3 ${location.pathname === menu.path ? "" : "collapsed"
                    }`}
                >
                  {/* <i className={menu.icon} /> */}
                  <div>
                    <menu.icon style={{ fontSize: '20px' }} />
                  </div>

                  <span>{menu.name}</span>
                </Link>

              }



            </li>
          ))}
        </ul>

        {/*End desktop menu  */}

        {/*Mobile menu  */}

        <ul className="sidebar-nav flex-grow-1 mobilesideMenus mt-2" id="sidebar-nav">
          {sidebarcontent.map((menu) => (
            <li className="nav-item" key={menu.path} onClick={() => setIsOpen(!isOpen)}>
              <Link
                to={menu.path}
                onClick={() => menu.check && restartPage()}

                className={`nav-link g-3 ${location.pathname === menu.path ? "" : "collapsed"
                  }`}
              >
                {/* <i className={menu.icon} /> */}
                <div>
                  <menu.icon style={{ fontSize: '20px' }} />
                </div>

                <span>{menu.name}</span>
              </Link>
            </li>
          ))}
        </ul>

        {/*End Mobile menu  */}


        <div className="border-top mt-auto">
          <div
            className=" logouty w-100 text-start  d-flex align-items-center text-danger"
            onClick={() => signOut()}
          >
            <MdLogout className=" " />
            <span>{lang.common.signOut}</span>
          </div>
        </div>
      </aside>
    </>
  );
};

const sidebarcontent = [
  { name: "Mon offre", icon: BiFile , path: "/dashboard", check: false },
  { name: "Maison", icon: FaArrowsRotate, path: "/", check: true },
  // { name: "Profil", icon: CgProfile, path: "/profile", check: false },
  // { name: "mot de passe", icon: MdOutlineQuiz, path: "/reset-password", },
  {
    name: "Profil",
    icon: RiUserLine,
    submenu: [
      {
        name: "Modifier mon profil",
        icon: TbEdit,
        path: "/profile",
        check: false
      },
      {
        name: "Mot de Passe",
        icon: MdOutlineLock,
        path: "/reset-password",
        check: false
      },
    ],
  },

];

export default Sidebar;
