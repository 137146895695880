import { useEffect, useState } from "react";

import SectionHeading from "../../UI/SectionHeading";
import Name from "./questions/Name";
import Email from "./questions/Email";
import PhoneNumber from "./questions/PhoneNumber";
import Address from "./questions/Address";
import Insure from "./questions/Insure";
import InsureDate from "./questions/InsureDate";
import OldContractExpiryMonth from "./questions/OldContractExpiryMonth";
import FormBox from "../../UI/FormBox";
import { useAppDispatch, useAppSelector } from "../../../store/stateHooks";
import Button from "../../UI/Button";
import getCredentialsIsFulfilled from "../../helpers/getCredentialsIsFulfilled";
import { navigationActions } from "../../../store/appStateSlices/navigationSlice";
import moment from "moment";
import { credentialsActions } from "../../../store/userDataSlices/credentialsSlice";
import PageTopText from "../../UI/pageTopText";
import ClientsSatisfaits from "../../UI/clientsview/ClientsSatisfaits";
import Fag from "../../UI/Faq/Fag";
import KnowMore from "../../UI/KnowMore";
import Footer from "../../UI/Footer";
import PourquoiCesInfo from "./PourquoiCesInfo";
import { Box } from "@mui/material";

function GatherCredentials() {
  useEffect(() => {
    document.documentElement.style.setProperty("--white", "#fff");
  });

  const [isInsured, setIsInsured] = useState<boolean | null>(null);
  const [expiryMonth, setExpiryMonth] = useState<string>("");
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [expiryMonthIndex, setExpiryMonthIndex] = useState<number>(0);

  const subscriberData = useAppSelector((state) => state.userData.credentials);

  const dispatch = useAppDispatch();

  const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function insureHandler(value: boolean) {
    setIsInsured(value);
    setExpiryMonth("");
  }

  function expiryMonthHandler(value: string, index: number) {
    setExpiryMonth(value);
    setSelectedMonth(value);
    setExpiryMonthIndex(index);
    const currentDate = moment();
    const selectedMonthName = months[index];

    // Create a moment object with the selected month (French)
    const selectedDate = moment(selectedMonthName, "MMMM", "fr").year(
      currentDate.year()
    );
    // Adjust year if the selected month is before the current month
    if (selectedDate.isBefore(currentDate, "month")) {
      selectedDate.add(1, "year");
    }

    // Convert the moment date to a JavaScript Date object
    const finalDate = selectedDate.toDate();

    // Format the date in the desired format (ISO 8601)
    const formattedDate = moment(finalDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    // Dispatch the formatted date to the Redux store
    dispatch(credentialsActions.setInsureDate(formattedDate));
  }
  useEffect(() => {
    console.log("Rendered component");
  }, [expiryMonthIndex]);

  const insureDateRenderCondition = isInsured
    ? expiryMonth
    : isInsured === false;

  function dataGatheredHandler() {
    dispatch(navigationActions.setActiveAppPage(2));
  }

  return (
    <>
      <PageTopText />
      <Box sx={{ display: 'flex', flexDirection: {xs:'column', md: 'row'}, justifyContent: 'end' }}>
        <FormBox >
          <SectionHeading>Coordonnées</SectionHeading>
          <Name values={[subscriberData.firstName, subscriberData.lastName]} />
          {subscriberData.firstName && subscriberData.lastName && (
            <Email value={subscriberData.email} />
          )}
          {subscriberData.email && (
            <PhoneNumber value={subscriberData.phoneNumber} />
          )}
          {subscriberData.phoneNumber && (
            <Address
              value={
                subscriberData.postalCode && subscriberData.city
                  ? `${subscriberData.postalCode} - ${subscriberData.city}`
                  : undefined
              }
            />
          )}
          {subscriberData.city && subscriberData.postalCode && (
            <Insure getIsInsured={insureHandler} />
          )}
          {isInsured && <OldContractExpiryMonth onAnswer={expiryMonthHandler} />}
          {insureDateRenderCondition && (
            <InsureDate
              value={selectedMonth}
              expiryMonth={expiryMonthIndex}
              isInsured={isInsured}
            />
          )}
          {getCredentialsIsFulfilled(subscriberData) && (
            <Button scrollToIt={true} onClick={dataGatheredHandler}>
              Rechercher des offres
            </Button>
          )}
        </FormBox>
        <PourquoiCesInfo />
      </Box>
      <ClientsSatisfaits />
      <Fag />
      <KnowMore />
      <Footer />
    </>
  );
}

export default GatherCredentials;
