import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { api } from "../../../../../utils/storage";
import { useAppSelector } from "../../../../../store/stateHooks";
import { putAPI } from "../../../../../apis/Services";

function CommentComponent({ id, comments }) {
  const [comment, setComment] = useState(comments || "");
  const [isCommentModified, setIsCommentModified] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { auth } = useAppSelector((state) => state.authCredential);
  // Define the mutation for posting the comment
  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (newComment) =>
      putAPI(`${api.contact}/${auth.data.admin_id}`, {
        comments: newComment,
        id,
      }),
    {
      onSuccess: () => {
        setShowSuccessMessage(true);
      },
    }
  );

  useEffect(() => {
    // If the comment hasn't been modified, don't trigger the API call
    if (!comment || !isCommentModified) return;

    const timer = setTimeout(() => {
      mutate(comment);
    }, 2000);

    // Cleanup the timeout if the user types again before 2 seconds
    return () => clearTimeout(timer);
  }, [comment, mutate, isCommentModified]);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    setIsCommentModified(true);
  };

  // Remove the success message after 2 seconds
  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
    setComment(comments);
  }, [showSuccessMessage, comments]);

  return (
    <div className="card p-4 mb-2">
      <h5>Commentaire</h5>
      <textarea
        className="form-control"
        rows="6"
        value={comment}
        onChange={handleCommentChange}
      ></textarea>

      {/* Display loading state */}
      {isLoading && (
        <div className="d-flex align-items-center">
          <p>Envoi du commentaire...</p>
          <div
            style={{ height: 20, width: 20 }}
            className="spinner-border text-dark"
          />
        </div>
      )}

      {/* Display error message if the mutation fails */}
      {isError && (
        <p>Erreur lors de l'envoi du commentaire. Veuillez réessayer.</p>
      )}

      {/* Display success message */}
      {showSuccessMessage && <p>Commentaire envoyé avec succès !</p>}
    </div>
  );
}

export default CommentComponent;
