import { credentialsActions } from "../../../../store/userDataSlices/credentialsSlice";
import { useAppDispatch } from "../../../../store/stateHooks";
// import DateField from "../../../UI/DateField";
import DateFieldInsure from "../../../UI/DateFieldInsure";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import moment from "moment";
import { useEffect, useState } from "react";

interface Props {
  value?: string;
  expiryMonth?: number;
  isInsured?: boolean | null;
}
function InsureDate(props: Props) {
  const dispatch = useAppDispatch();
  const [expiryDate, setExpryDate] = useState(new Date().toString());
  const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = moment();
  const index: number = props.expiryMonth ?? 0;
  const selectedMonth = months[index];

  const selectedDate = moment(selectedMonth, "MMMM").year(currentDate.year());

  if (selectedDate.isBefore(currentDate, "month")) {
    selectedDate.year(currentDate.year() + 1);
  }
  // Get the final date
  var finalDate = selectedDate.toDate();

  // function answerHandler(value: string) {
  //   dispatch(credentialsActions.setInsureDate(value));
  // }

  useEffect(() => {
    dispatch(credentialsActions.setInsureDate(expiryDate.toString()));
  }, [expiryDate]);
  const changeMonthToDate = () => {
    if (props.value) {
      const currentYear = new Date().getFullYear();
      const currentMonthIndex = new Date().getMonth(); // 0 for Jan, 11 for Dec

      // Parse month name to get its index (e.g., "January" => 0, "December" => 11)
      const monthIndex = moment().month(props.value).month();

      // Determine if the month should belong to the next year
      const year =
        monthIndex < currentMonthIndex ? currentYear + 1 : currentYear;

      // Combine month with day and calculated year
      const formattedValue = `${props.value} 1, ${year}`;

      // Parse the formatted date
      const utcDate = moment(formattedValue, "MMMM D, YYYY", true)
        .utc()
        .toDate();

      return utcDate;
    }
  };

  return (
    <QuestionBox
      targetState="credentials"
      action="setInsureDate"
      scrollToIt={true}
    >
      <FieldLabel>A quelle date souhaitez-vous être assuré(e) ?</FieldLabel>
      <DateFieldInsure
        getState={(value) => setExpryDate(value)}
        minDate={finalDate}
        // isInsured={props.isInsured}
        state={changeMonthToDate()}
      />
    </QuestionBox>
  );
}

export default InsureDate;
