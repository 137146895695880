// toast.js
import { toast } from "react-toastify";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
//   style: {
//     display: "inline-block",
//     maxWidth: "100%", // Allows it to expand up to the width of the viewport
//     // wordWrap: "break-word",
//     // padding: "8px 16px",
//     boxSizing: "border-box",
//   },
};

const showToast = (message, type = "info") => {
  switch (type) {
    case "success":
      toast.success(message, toastOptions);
      break;
    case "error":
      toast.error(message, toastOptions);
      break;
    case "warn":
      toast.warn(message, toastOptions);
      break;
    case "info":
    default:
      toast.info(message, toastOptions);
      break;
  }
};

export default showToast;
