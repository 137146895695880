import { useState } from "react";
import { nanoid } from "nanoid";

import getRadioFieldRender from "../../../helpers/getRadioFieldRender";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import RadioField from "../../../UI/RadioField";
import RadioFieldGroup from "../../../UI/RadioFieldGroup";
import { useAppDispatch } from "../../../../store/stateHooks";
import {
  IMembers,
  membersActions,
} from "../../../../store/userDataSlices/membersSlice";

interface Props {
  value?: string;
}

function Activity(props: Props) {
  const activities = [
    "Salarié(e) non-cadre",
    "Recherche d'emploi",
    "Retraité(e)",
    "Sans profession",
    "Salarié(e) cadre",
    "Enseignant(e)",
    "Fonctionnaire d'état",
    "Fonctionnaire territorial",
    "Fonctionnaire hospitalier",
    "Chef(fe) d'entreprise",
    "Commerçant(e)",
    "Artisan",
    "Agriculteur(trice)",
    "Profession libérale",
    "Etudiant(e)",
  ];

  const defaultSelectedIndex = props.value
    ? activities.indexOf(props.value)
    : undefined;

  function getInitialState() {
    if (
      defaultSelectedIndex === undefined ||
      defaultSelectedIndex < 0 ||
      defaultSelectedIndex < 3
    ) {
      return false;
    }

    return true;
  }

  const [showMoreActivities, setShowMoreActivities] = useState(
    getInitialState()
  );

  const dispatch = useAppDispatch();

  function answerHandler(value: IMembers["activity"]) {
    dispatch(membersActions.setActivity(value));
  }

  function showMoreActivitiesHandler() {
    setShowMoreActivities(true);
  }

  return (
    <QuestionBox targetState="members" action="setActivity" scrollToIt={true}>
      <FieldLabel>Votre profession ou activité</FieldLabel>
      <RadioFieldGroup
        type="horizontal"
        items={activities.filter((act, i) =>
          !showMoreActivities ? i < 3 : act
        )}
        renderItem={getRadioFieldRender("horizontal")}
        getSelectedValue={answerHandler}
        defaultSelected={defaultSelectedIndex}
      >
        {!showMoreActivities && (
          <RadioField
            text="Autres professions..."
            type="horizontal"
            key={nanoid(6)}
            onClick={showMoreActivitiesHandler}
            textAlign="center"
          />
        )}
      </RadioFieldGroup>
    </QuestionBox>
  );
}

export default Activity;
