import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Footer from '../UI/Footer'
import KnowMore from '../UI/KnowMore'
import OffersNavbar from '../UI/OffersNavbar'
import Logo from "../UI/Logo";
import { Link } from 'react-router-dom'
export default function MentionsLegales() {
    const Informations = [
        {
            title: `Nom de l’entreprise :`,
            labels: `[Nom de l’entreprise]`,
        },
        {
            title: `Siège social :`,
            labels: `[Adresse complète de l’entreprise]`,
        },
        {
            title: `Téléphone :`,
            labels: `[Numéro de téléphone]`,
        },
        {
            title: `Email :`,
            labels: `[Adresse email de contact]`,
        },
        {
            title: `Responsable de la publication :`,
            labels: `[Nom et fonction de la personne]`,
        },

    ]

    const Hebergement = [

        {
            title: `Hébergeur :`,
            labels: `[Nom de l’hébergeur]`,
        },
        {
            title: `Adresse de l’hébergeur :`,
            labels: `[Adresse complète]`,
        },
        {
            title: `Téléphone de l’hébergeur :`,
            labels: `[Numéro de téléphone]`,
        },

    ]

    const mentionData = [
        {
            headText: `Propriété Intellectuelle`,
            subTitle: `Tous les contenus présents sur ce site (textes, images, logos, vidéos, etc.) sont la propriété exclusive de [Nom de l’entreprise] ou de ses partenaires. Toute reproduction, distribution, modification ou utilisation de ces contenus sans autorisation préalable est strictement interdite et peut constituer une contrefaçon sanctionnée par le Code de la propriété intellectuelle.`,
        },
        {
            headText: `Collecte et Traitement des Données Personnelles`,
            subTitle: `Conformément à la loi "Informatique et Libertés" et au Règlement Général sur la Protection des Données (RGPD), les informations personnelles collectées sur ce site sont traitées de manière confidentielle. Vous avez le droit d’accès, de rectification, et de suppression de vos données. Pour exercer ce droit, contactez-nous à l’adresse suivante : [Email de contact].`,
        },
        {
            headText: `Utilisation des Cookies`,
            subTitle: `Le site utilise des cookies pour améliorer l’expérience utilisateur et réaliser des statistiques de visite. En naviguant sur notre site, vous acceptez l’utilisation des cookies. Vous pouvez désactiver les cookies via les paramètres de votre navigateur.`,
        },
        {
            headText: `Responsabilité`,
            subTitle: `[Nom de l’entreprise] met tout en œuvre pour assurer la fiabilité des informations présentes sur ce site. Toutefois, l’entreprise ne peut être tenue responsable des erreurs ou omissions éventuelles, ni des dommages résultant de l’utilisation des informations présentes sur ce site ou de tout site tiers accessible via des liens hypertextes.`,
        },
        {
            headText: `Liens Hypertextes`,
            subTitle: `Le site peut contenir des liens hypertextes vers d’autres sites. [Nom de l’entreprise] décline toute responsabilité quant au contenu de ces sites tiers et aux éventuels dommages qu’ils pourraient causer.`,
        },
        {
            headText: `Droit Applicable et Juridiction`,
            subTitle: `Les présentes mentions légales sont régies par le droit français. En cas de litige, et à défaut de résolution amiable, les tribunaux français seront seuls compétents.`,
        }

    ]


    return (
        <>
            <nav style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                padding: ' 0.5rem 1.5rem',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 999,
                backgroundColor: 'rgb(255, 255, 255, 0.95)',
                boxShadow: '0 2px 4px -2px rgba(0, 0, 0, 0.1)',
            }} >
            <div >
                <Link to="/">
                    <Logo />
                </Link>
            </div>
        </nav >
            <Box sx={{ width: {sm: '100', md:'50%'}, mx: 'auto', my: 12, px: 3 }}>
                <Stack direction={'row'} spacing={1} sx={{
                    justifyContent: 'start',
                    alignItems: 'center'
                }}>
                    <Box sx={{ width: '7px', height: '35px', borderRadius: '100px', backgroundColor: '#006CB7' }}></Box>
                    <Typography variant='h5' sx={{ color: '#37474F', fontWeight: '700' }}>Mentions légales</Typography>
                </Stack>

                <Box mt={4}>
                    <Typography variant='body1' sx={{ mb: 1, color: '#37474F', fontWeight: '700', fontSize: '18px' }}>Informations sur l'Éditeur du Site</Typography>
                    {
                        Informations.map((item, index) => {
                            return (
                                <Stack direction={{ xs: 'column', sm: 'row' }} key={index}>
                                    <Typography variant='body1' sx={{ color: '#37474F', opacity: '65%', fontWeight: '400', fontSize: '15px' }}>{item.title}</Typography>

                                    <Typography variant='body1' sx={{ color: '#37474F', opacity: '65%', fontWeight: '400', fontSize: '15px' }}>{item.labels}</Typography>
                                </Stack>
                            )
                        })
                    }

                </Box>
                <Box mt={4}>
                    <Typography variant='body1' sx={{ mb: 1, color: '#37474F', fontWeight: '700', fontSize: '18px' }}>Hébergement du Site</Typography>
                    {
                        Hebergement.map((item, index) => {
                            return (
                                <Stack direction={'row'} key={index}>
                                    <Typography variant='body1' sx={{ color: '#37474F', opacity: '65%', fontWeight: '400', fontSize: '15px' }}>{item.title}</Typography>

                                    <Typography variant='body1' sx={{ color: '#37474F', opacity: '65%', fontWeight: '400', fontSize: '15px' }}>{item.labels}</Typography>
                                </Stack>
                            )
                        })
                    }

                </Box>



                <Box>
                    {
                        mentionData.map((item, index) => {
                            return (
                                <Box mt={4} key={index}>
                                    <Typography variant='body1' sx={{ mb: 1, color: '#37474F', fontWeight: '700', fontSize: '18px' }}>{item.headText}</Typography>
                                    <Typography variant='body1' sx={{ color: '#37474F', opacity: '65%', fontWeight: '400', fontSize: '15px' }}>{item.subTitle}</Typography>
                                </Box>
                            )
                        })
                    }
                </Box>


            </Box>
            <Box pt={5}>
                <KnowMore />
            </Box>
            <Footer />
        </>
    )
}
