import { nanoid } from "nanoid";
import { navigationActions } from "../../store/appStateSlices/navigationSlice";

import { useAppDispatch, useAppSelector } from "../../store/stateHooks";
import getCredentialsIsFulfilled from "../helpers/getCredentialsIsFulfilled";
import getMembersIsFulfilled from "../helpers/getMembersIsFulfilled";
import getWarrantiesIsFulfilled from "../helpers/getWarrantiesIsFulfilled";
import Icon from "./Icon";
import PageProgress from "./PageProgress";

import classes from "./ProgressBar.module.css";
import { useEffect, useState } from "react";

type FormPageNumber = 1 | 2 | 3;

function ProgressBar() {
  const activeFormPage = useAppSelector(
    (state) => state.appState.navigation.activeFormPage
  );

  const userData = useAppSelector((state) => state.userData);

  const dispatch = useAppDispatch();

  const pages = ["Adhérents", "Besoins", "Coordonnées"];

  function renderPageProgresses(width: any) {
    return pages.map((page, i) => {
      const pageNumber = (i + 1) as 1 | 2 | 3;

      let progress: "waiting" | "in-progress" | "completed" = "waiting";

      if (activeFormPage === pageNumber) {
        progress = "in-progress";
      } else if (activeFormPage > pageNumber) {
        progress = "completed";
      }

      const disableCondition = pageNumber > activeFormPage;

      function clickHandler() {
        if (disableCondition) {
          return;
        }

        dispatch(
          navigationActions.setActiveFormPage((i + 1) as FormPageNumber)
        );
      }

      if (width < 560) {
        if (progress === "in-progress") {
          return (
            <PageProgress
              key={nanoid(6)}
              name={page}
              onClick={clickHandler}
              progress={progress}
              isActive={pageNumber === activeFormPage}
              disabled={disableCondition}
            />
          );
        }
      } else {
        return (
          <PageProgress
            key={nanoid(6)}
            name={page}
            onClick={clickHandler}
            progress={progress}
            isActive={pageNumber === activeFormPage}
            disabled={disableCondition}
          />
        );
      }
    });
  }

  function navigationHandler(navigateTo: number, isNext = false) {
    return () => {
      if (isNext && !getToEnableNextBtn()) {
        return;
      }

      if (navigateTo > 3 || navigateTo < 1) {
        return;
      }

      dispatch(
        navigationActions.setActiveFormPage(navigateTo as FormPageNumber)
      );
    };
  }

  function getToEnableNextBtn(currentPage = activeFormPage) {
    switch (currentPage) {
      case 1:
        return getMembersIsFulfilled(userData.members);
      case 2:
        return getWarrantiesIsFulfilled(userData.warranties);
    }
  }

  const [windowWidth, setWindowWidth] = useState(0);
  useEffect(() => {
    const getWidth = window.innerWidth;
    setWindowWidth(getWidth);
    const resizeListener = () => {
      // change width from the state object
      // eslint-disable-next-line no-restricted-globals
      setWindowWidth(window.innerWidth);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);
  return (
    <div className={classes.container} style={{ justifyContent: activeFormPage !== 1 ? "space-between" : "center" }}>
      {
        activeFormPage !== 1 && (
          <button
            // disabled={activeFormPage === 1}
            type="button"
            className={classes["nav-btn"]}
            onClick={navigationHandler(activeFormPage - 1)}
          >
            <Icon name="chevronBackOutline" className={classes.icon} />
            <span className={classes.text}>Retour</span>
          </button>
        )}
      <div className={classes.progress}>
        {renderPageProgresses(windowWidth)}
      </div>
      {
        activeFormPage !== 1 && (
          <button
            disabled={!getToEnableNextBtn() || activeFormPage === 3}
            type="button"
            className={classes["nav-btn"]}
            onClick={navigationHandler(activeFormPage + 1, true)}
          >
            <span className={classes.text}>Suivante</span>
            <Icon name="chevronForwardOutline" className={classes.icon} />
          </button>
        )
      }

    </div>
  );
}

export default ProgressBar;
