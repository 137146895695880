import React, { useEffect, useState } from "react";
import lang from "../../utils/language/french";
function ChatBot() {
  function toggleChatBot() {
    // document.getElementById("chatbot_toggle").onclick = function () {
    if (document.getElementById("chatbot").classList.contains("collapsed")) {
      document.getElementById("chatbot").classList.remove("collapsed");
      document.getElementById("chatbot_toggle").children[0].style.display =
        "none";
      document.getElementById("chatbot_toggle").children[1].style.display = "";
    } else {
      document.getElementById("chatbot").classList.add("collapsed");
      setTimeout(() => {
        document.getElementById("chatbot_toggle").children[0].style.display =
          "";
        document.getElementById("chatbot_toggle").children[1].style.display =
          "none";
      }, 200);
    }
    // };
  }
  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      // send();
      displayDescription(3, 0);
    }
  };
  // =====================================
  const [checkDesc, setCheckDesc] = useState(0);
  const [queries, setQueries] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [listId, setListId] = useState(0);
  const [backListId, setBackListId] = useState(0);

  function displayDescription(status, id) {
    setCheckDesc(status);
    if (status === 2) {
      setBackListId(id);
    } else {
      setListId(id);
    }
  }
  // ==========================================
  const SERVER_URL = process.env.REACT_APP_API_SERVER;
  // On submit function
  const handelsubmit = async (event) => {
    event.preventDefault();

    const messages = document.getElementById("messages");
    messages.innerHTML = "";
    //======================|LOGIN API|==========================
    var BASE_URL = SERVER_URL + "/api/v1/queries/add-queries";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      email: email,
      queries: queries,
    });

    let resData = await fetch(BASE_URL, {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    }).then((res) => {
      return res.json();
    });
    var messageBox = document.getElementById("message-box");
    messageBox.scrollTo(0, 0);
    if (resData.status === 200) {
      messages.innerHTML = `
        <div class="alert alert-success alert-dismissible fade show">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        <strong>${lang.common.success}!</strong> ${resData.message}
      </div>`;

      setQueries("");
      setName("");
      setEmail("");
      event.target.reset();
      setTimeout(() => {
        messages.innerHTML = "";
      }, 10000);
    } else {
      messages.innerHTML = `
        <div class="alert alert-danger alert-dismissible fade show">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
        <strong>${lang.common.error}!</strong> ${resData.message}
      </div>`;
    }
  };
  return (
    <div className="title chatBotBox">
      <div id="chatbot" className="main-card collapsed">
        <button id="chatbot_toggle" onClick={toggleChatBot}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M15 4v7H5.17l-.59.59-.58.58V4h11m1-2H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm5 4h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1z" />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            style={{ display: "none" }}
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
          </svg>
        </button>
        <div className="main-title">
          <div>
            {/* <svg viewBox="0 0 640 512" title="robot">
              <path
                fill="currentColor"
                d="M32,224H64V416H32A31.96166,31.96166,0,0,1,0,384V256A31.96166,31.96166,0,0,1,32,224Zm512-48V448a64.06328,64.06328,0,0,1-64,64H160a64.06328,64.06328,0,0,1-64-64V176a79.974,79.974,0,0,1,80-80H288V32a32,32,0,0,1,64,0V96H464A79.974,79.974,0,0,1,544,176ZM264,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,264,256Zm-8,128H192v32h64Zm96,0H288v32h64ZM456,256a40,40,0,1,0-40,40A39.997,39.997,0,0,0,456,256Zm-8,128H384v32h64ZM640,256V384a31.96166,31.96166,0,0,1-32,32H576V224h32A31.96166,31.96166,0,0,1,640,256Z"
              />
            </svg> */}
            <svg
              width="25"
              height="37"
              viewBox="0 0 25 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7749 9.38137C17.0684 7.13141 15.4296 5.07618 13.1143 4.79088C10.7991 4.50558 8.68428 6.09825 8.39071 8.34821C8.09714 10.5982 9.736 12.6534 12.0512 12.9387C14.3664 13.224 16.4812 11.6313 16.7749 9.38137Z"
                fill="#fff"
              />
              <path
                d="M16.2535 18.8163C15.094 19.6369 13.8883 20.3258 12.701 20.9607C12.1346 21.2623 11.5716 21.5539 11.0169 21.8387C9.88522 21.2004 8.74546 20.444 7.64972 19.5311C3.48336 16.0551 -0.0424701 10.2952 0.000386639 0C5.13856 11.2238 9.13581 14.2113 13.8825 16.072C14.384 16.2678 14.8937 16.4524 15.4149 16.6326C15.4149 16.6326 15.6234 17.2021 16.2535 18.8163Z"
                fill="#fff"
              />
              <path
                d="M21.8769 11.407C21.5143 12.8636 20.8124 14.2819 19.8614 15.51C18.9152 16.7459 17.7487 17.7894 16.529 18.6708C16.4619 18.7192 16.3958 18.7676 16.3264 18.816C15.1669 19.6366 13.9611 20.3255 12.7739 20.9604C12.2075 21.262 11.6446 21.5536 11.0897 21.8384C9.18087 22.8177 7.36352 23.7373 5.81025 24.908C4.8118 25.6656 3.92456 26.5368 3.18442 27.5961C2.81376 28.124 2.47786 28.6958 2.17786 29.3059C1.87786 29.9149 1.61377 30.5621 1.34737 31.2353C1.18637 30.5329 1.1215 29.808 1.14003 29.074C1.15741 28.339 1.26166 27.5938 1.46436 26.8599C1.86513 25.3898 2.67245 23.9861 3.70565 22.8188C4.73768 21.6436 5.95851 20.6902 7.19556 19.873C7.37046 19.757 7.54652 19.6445 7.72259 19.533C8.79284 18.8588 9.877 18.2701 10.9345 17.7072C11.9735 17.1557 12.987 16.6255 13.9542 16.0751C15.285 15.3175 16.5291 14.5194 17.6282 13.5682C18.5792 12.7465 19.4248 11.8066 20.1487 10.7136C20.5112 10.1677 20.8483 9.58792 21.153 8.97444C21.4599 8.36321 21.739 7.7216 22.0286 7.05859C22.1607 7.7655 22.2081 8.48816 22.1838 9.21758C22.1618 9.9425 22.0599 10.6798 21.8769 11.407Z"
                fill="#fff"
              />
              <path
                d="M23.7118 17.1764C23.3493 18.633 22.6474 20.0513 21.6964 21.2794C20.75 22.5154 19.5837 23.5588 18.364 24.4402C18.2968 24.4886 18.2308 24.537 18.1613 24.5854C17.0019 25.406 15.7961 26.0949 14.6088 26.7298C14.0424 27.0314 13.4795 27.323 12.9247 27.6078C11.0159 28.5871 9.19847 29.5067 7.64521 30.6774C6.64677 31.435 5.75952 32.3062 5.01938 33.3655C4.64872 33.8934 4.31282 34.4652 4.01282 35.0753C3.71282 35.6843 3.44873 36.3315 3.18232 37.0047C3.02133 36.3023 2.95646 35.5773 2.975 34.8434C2.99237 34.1084 3.09662 33.3632 3.29932 32.6293C3.70009 31.1592 4.50741 29.7555 5.5406 28.5882C6.57264 27.413 7.79348 26.4596 9.03053 25.6424C9.20542 25.5265 9.38148 25.4139 9.55754 25.3025C10.6278 24.6293 11.712 24.0395 12.7694 23.4767C13.8084 22.9251 14.8219 22.3949 15.7891 21.8445C17.12 21.0869 18.364 20.2888 19.4632 19.3377C20.4142 18.5159 21.2597 17.576 21.9836 16.483C22.3462 15.9371 22.6833 15.3574 22.9879 14.7439C23.2949 14.1327 23.574 13.4911 23.8636 12.8281C23.9956 13.535 24.0431 14.2576 24.0187 14.987C23.998 15.712 23.8949 16.4481 23.7118 17.1764Z"
                fill="#fff"
              />
            </svg>
          </div>
          <span>{lang.Chatbot.Chatbot}</span>
        </div>
        {checkDesc === 0 ? (
          <div className="chat-area" id="message-box">
            {/* ============================================ */}
            {lang.Chatbot.faqQuestions.map((parentList, i) => {
              if (parentList.parentId === 0) {
                return (
                  <div className="chat-message-div" key={i}>
                    <div
                      role="button"
                      onClick={() => displayDescription(1, parentList.id)}
                    >
                      <div className="chat-message-received">
                        <h6 className="faq_title">{parentList.faqTitle}</h6>
                        <p className="faq_description">
                          {parentList.faqDescription}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        ) : checkDesc === 1 ? (
          <>
            <div className="chat-message-div">
              <div
                className="backBotton"
                onClick={() => displayDescription(0, 0)}
              >
                <i className="bi bi-chevron-left"></i> {lang.common.back}
              </div>
            </div>
            <div className="chat-area accordion" id="message-box">
              {lang.Chatbot.faqQuestions.map((parentList, index) => {
                if (parentList.parentId === listId) {
                  return (
                    <div className=" chat-message-received" key={index}>
                      <h6
                        className="faq_title my_fag_title"
                        id={`heading-${index}`}
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse-${index}`}
                        aria-expanded="false"
                        aria-controls={`collapse-${index}`}
                      >
                        {parentList.faqTitle}
                      </h6>
                      <div
                        id={`collapse-${index}`}
                        className="accordion-collapse collapse faq_description"
                        aria-labelledby={`heading-${index}`}
                        data-bs-parent="#message-box"
                      >
                        <div className="accordion-body">
                          <p className="faq_description">
                            {parentList.faqDescription}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </>
        ) : (
          checkDesc === 2 && (
            <>
              <div className="chat-message-div">
                <div
                  className="backBotton"
                  onClick={() => displayDescription(1, listId)}
                >
                  <i className="bi bi-chevron-left"></i> {lang.common.back}
                </div>
              </div>
              <div className="chat-area" id="message-box">
                {lang.Chatbot.faqQuestions.map((parentList) => {
                  if (parentList.parentId === backListId) {
                    return (
                      <div className="chat-message-div">
                        <div className="chat-message-received">
                          <h6 className="faq_title">{parentList.faqTitle}</h6>
                          <p className="faq_description">
                            {parentList.faqDescription}
                          </p>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </>
          )
        )}

        {checkDesc === 3 && (
          <>
            <div className="chat-message-div">
              <div
                className="backBotton"
                onClick={() => displayDescription(0, 0)}
              >
                <i className="bi bi-chevron-left"></i> {lang.common.back}
              </div>
            </div>
            <div className="chat-area" id="message-box">
              <div className="chat-message-div">
                <div className="chat-message-received">
                  <h6 className="faq_title">{lang.common.fullDetails}</h6>
                  <div id="messages"></div>
                  <form action="#" autoComplete="off" onSubmit={handelsubmit}>
                    <div className="form-group mb-2">
                      <label for="pwd">{`${lang.common.name}:`}</label>
                      <input
                        type="text"
                        required
                        className="form-control"
                        placeholder={lang.common.fullName}
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-2">
                      <label for="email">{`${lang.common.email}:`}</label>
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        required
                        className="form-control"
                        id="email"
                        placeholder={lang.common.enterEmail}
                        name="email"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label for="comment">{`${lang.common.query}:`}</label>
                      <textarea
                        className="form-control"
                        rows="5"
                        required
                        placeholder={lang.Chatbot.placeholderQuery}
                        id="query"
                        onChange={(e) => setQueries(e.target.value)}
                      >
                        {queries}
                      </textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      {lang.common.submit}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </>
        )}
        {/* =================================== */}
        <div className="line"></div>
        <div className="input-div">
          <input
            className="input-message"
            name="message"
            type="text"
            id="message"
            readOnly={checkDesc === 3 && true}
            value={checkDesc === 3 ? "" : queries}
            onChange={(e) => setQueries(e.target.value)}
            onKeyUp={handleKeyUp}
            placeholder={lang.Chatbot.otherMessage}
          />
          <button
            className="input-send"
            onClick={() => displayDescription(3, 0)}
          >
            {lang.Chatbot.askQuestion}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: "22px", height: "22px", margin: "10px 0px" }}
              fill="currentColor"
              className="bi bi-question"
              viewBox="0 0 16 16"
            >
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatBot;
