import { nanoid } from "nanoid";
import { useRef, useEffect, PropsWithChildren } from "react";

import Icon from "./Icon";

import classes from "./TextField.module.css";

interface Props extends PropsWithChildren {
  placeHolder: string;
  label?: string;
  icon?: string;
  type?: "text" | "email" | "password" | "tel";
  spellCheck?: boolean;
  getState?: (value: string) => void;
  value?: string;
  name?: string;
}

function TextField(props: Props) {
  const FIELD_ID = `field${nanoid(6)}`;
  const theInput = useRef<HTMLInputElement>(null);
  const { value } = props;

  useEffect(() => {
    if (!value || !theInput.current) {
      return;
    }

    theInput.current.value = value;
  }, [value]);

  function changeHandler() {
    if (!props.getState) {
      return;
    }

    const currentValue = theInput.current!.value;
    props.getState(currentValue!);
  }

  return (
    <div className={classes.container}>
      {props.label && (
        <label htmlFor={FIELD_ID} className={classes.label}>
          {props.label}
        </label>
      )}
      <input
        ref={theInput}
        type={props.type || "text"}
        id={FIELD_ID}
        placeholder={props.placeHolder}
        spellCheck={props.spellCheck !== undefined ? props.spellCheck : true}
        className={classes.input}
        onChange={changeHandler}
        name={props.name}
      />
      {props.icon !== undefined && (
        <Icon name={props.icon} className={classes.icon} />
      )}
      {props.children}
    </div>
  );
}

export default TextField;
