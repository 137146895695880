import React from 'react'
import { Box, Paper, Stack, Typography } from '@mui/material'

import { ReactComponent as PourSvg } from '../../icons/Pour.svg';
import { ReactComponent as PourCommuSvg } from '../../icons/PourCommu.svg';
import { ReactComponent as VosDonSvg } from '../../icons/VosDon.svg';

export default function PourquoiCesInfo() {
    return (
        <Box  bgcolor='#F6F6F9' sx={{ maxWidth:  {xs: '100%', md: '280px'}, mt: {xs: 3, md: 15}, p: 2, mx: 2, borderRadius: '16px' }}>
            <Typography variant='subtitle2' sx={{ mb: 2 }}>Pourquoi ces informations ?</Typography>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    justifyContent: "start",
                    alignItems: "start",
                    mb: 2,
                }}
            >
                <PourSvg />
                <Typography variant='body2'>Pour vous proposer des <Box component='span' sx={{ color: '#006CB7', fontWeight: '600' }}>devis personnalisés </Box>  adaptés à votre situation.</Typography>
            </Stack>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    justifyContent: "start",
                    alignItems: "start",
                    mb: 2,
                }}
            >
                <PourCommuSvg />
                <Typography variant='body2'>Pour garantir une <Box component='span' sx={{ color: '#006CB7', fontWeight: '600' }}>communication 100% sécurisée</Box> </Typography>
            </Stack>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    justifyContent: "start",
                    alignItems: "start",
                    mb: 2,
                }}
            >
                <VosDonSvg />
                <Typography variant='body2'>Vos données restent <Box component='span' sx={{ color: '#006CB7', fontWeight: '600' }}>confidentielles </Box> et ne seront jamais partagées sans votre accord.</Typography>
            </Stack>


        </Box>
    )
}
