import { format } from "date-fns";
import { fr } from "date-fns/locale";
import {
  FaHeartbeat,
  FaTooth,
  FaEye,
  FaDeaf,
  FaHospital,
} from "react-icons/fa";

const SubscriberDetailsCard = ({ offer }) => {
  if (!offer.subscriber) {
    return null; // If subscriber data is not available, return null
  }

  const {
    firstName,
    lastName,
    status,
    gender,
    birthDate,
    activity,
    socialRegime,
    email,
    phoneNumber,
    city,
    postalCode,
    insureDate,
  } = offer.subscriber;

  return (
    <div
      className="card mb-4 p-4"
      style={{
        borderRadius: "10px",
        backgroundColor: "#f8f9fa",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/${offer.provider}-Logo.png`}
            alt="Provider Logo"
            style={{ width: "120px" }}
          />
          <h3
            className="mt-3 mb-1"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >{`${firstName} ${lastName}`}</h3>
          <p className="text-muted mb-0" style={{ fontSize: "14px" }}>
            {status}
          </p>
        </div>
        <div className="text-end">
          <h3
            className="mb-1"
            style={{ fontSize: "28px", fontWeight: "bold", color: "#1a1a1a" }}
          >
            {`${offer.monthlyPrice} € /mois`}
          </h3>
          <small style={{ color: "#666" }}>{`Budget estimé: ${Math.round(
            offer.monthlyPrice * 12
          )} €/ans`}</small>
          <p className="text-muted mb-0" style={{ fontSize: "14px" }}>
            <strong>Numéro de police:</strong> {offer._id}
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <p>
            <strong>Genre:</strong> {gender}
          </p>
          <p>
            <strong>Date de Naissance:</strong>{" "}
            {format(new Date(birthDate), "d MMMM yyyy", { locale: fr })}
          </p>
          <p>
            <strong>assurer Date:</strong>{" "}
            {format(new Date(insureDate), "d MMMM yyyy", { locale: fr })}
          </p>
        </div>
        <div className="col-md-4">
          <p>
            <strong>Email:</strong> {email}
          </p>
          <p>
            <strong>Téléphone:</strong> {phoneNumber}
          </p>
          <p>
            <strong>Activité:</strong> {activity}
          </p>
        </div>

        <div className="col-md-4">
          <p>
            <strong>Régime Social:</strong> {socialRegime}
          </p>
          <p>
            <strong>Ville:</strong> {city}
          </p>
          <p>
            <strong>Code Postal:</strong> {postalCode}
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-4">
        <FaHeartbeat size={30} className="me-3" />
        <FaTooth size={30} className="me-3" />
        <FaEye size={30} className="me-3" />
        <FaDeaf size={30} className="me-3" />
        <FaHospital size={30} />
      </div>
    </div>
  );
};

export default SubscriberDetailsCard;
