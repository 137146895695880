import { nanoid } from "nanoid";

import RadioField, { RadioFieldGetState } from "../UI/RadioField";

export default function getRadioFieldRender(
  fieldType: "horizontal" | "vertical",
  icons?: string[]
) {
  return (
    item: string,
    index: number,
    isSelected: boolean,
    getState: RadioFieldGetState
  ) => (
    <RadioField
      key={nanoid(6)}
      type={fieldType}
      text={item}
      index={index}
      staySelected={isSelected}
      getState={getState}
      icon={icons && icons?.length > 0 ? icons![index] : undefined}
    />
  );
}
