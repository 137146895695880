import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { getAPI } from "../../../apis/Services";
import classes from "../../UI/Offer.module.css";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { getWarrantyLevelName } from "../../../utils/warrantyLevel";
import showToast from "../../../utils/toast";
import getWarrantiesIcons from "../../helpers/getWarrantiesIcons";
import { nanoid } from "nanoid";
import WarrantyLevel from "../../UI/WarrantyLevel";
import SubscriberDetailsCard from "../components/subcriberDetailsCard";

function OfferDetails() {
  const { offerId } = useParams();
  const warranties = getWarrantiesIcons();
  const [isLoading, setLoading] = useState(false);
  const [offerData, setOfferData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getAPI(
          `/api/v1/confirm-offer/get-offers?id=${offerId}`
        );
        console.log("res", res)
        if (res.status === 200) {
          const offer = res.data;
          const controlledOffer = { ...offer };

          if (controlledOffer.subscriber?.warranties) {
            controlledOffer.warranties = {
              medicalCare: {
                level: getWarrantyLevelName(
                  controlledOffer.subscriber.warranties.medicalCare?.level
                ),
              },
              dental: {
                level: getWarrantyLevelName(
                  controlledOffer.subscriber.warranties.dental?.level
                ),
              },
              visual: {
                level: getWarrantyLevelName(
                  controlledOffer.subscriber.warranties.visual?.level
                ),
              },
              hearing: {
                level: getWarrantyLevelName(
                  controlledOffer.subscriber.warranties.hearing?.level
                ),
              },
              hospitalization: {
                level: getWarrantyLevelName(
                  controlledOffer.subscriber.warranties.hospitalization?.level
                ),
              },
            };
          }
          controlledOffer.levels = Object.values(
            controlledOffer.warranties || {}
          ).map((warranty) => warranty.level);
          setOfferData(controlledOffer);
        } else {
          showToast(res.message, "error");
        }
      } catch (err) {
        console.log(err);
        showToast(err.message, "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [offerId]);

  return (
    <main id="main" className="main">
      {Object.keys(offerData).length !== 0 && (
        <SubscriberDetailsCard offer={offerData} />
      )}
      <div className="justify-content-center">
        <div>
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="mb-3 mt-2">
              <div className="row">
                <div className="col-md-12 p-0">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">
                        À propos{" "}
                        {offerData.name ||
                          offerData.label ||
                          offerData.provider}{" "}
                        ({offerData.provider})
                      </h5>

                      <p style={{ fontSize: "14px" }}>
                        L'offre d'assurance avec le code {offerData.code},
                        intitulée{" "}
                        <b>
                          "
                          {offerData.name ||
                            offerData.label ||
                            offerData.provider}
                          "
                        </b>{" "}
                        proposée par <b>{offerData.provider}</b>, offre une
                        couverture pour divers avantages. Le prix mensuel de
                        cette assurance est de <b>{offerData.monthlyPrice}€</b>.
                        <br />
                        <br />
                        <b>{offerData.provider} </b> offre une couverture
                        complète pour les particuliers, garantissant protection
                        et tranquillité d'esprit. Avec ce plan d'assurance, vous
                        pouvez profiter d'une large gamme d'avantages adaptés à
                        vos besoins. Que ce soit pour les frais médicaux,
                        l'hospitalisation ou d'autres services de santé,{" "}
                        {offerData.provider} vous couvre.
                        <br />
                        <br />
                        Le plan d'assurance propose un prix mensuel compétitif
                        de
                        <b>{offerData.monthlyPrice}€</b>, ce qui en fait une
                        option abordable pour les particuliers recherchant une
                        couverture fiable. {offerData.provider}, un fournisseur
                        d'assurance réputé, veille à ce que vous receviez le
                        soutien et l'assistance dont vous avez besoin chaque
                        fois que vous en avez besoin.
                        <br />
                        <br />
                        Avec{" "}
                        <b>
                          {offerData.name ||
                            offerData.label ||
                            offerData.provider}
                        </b>
                        , vous pouvez faire face en toute confiance à toutes les
                        circonstances imprévues, sachant que vous bénéficiez
                        d'une couverture d'assurance fiable. Ce plan vise à vous
                        offrir une sécurité financière et à vous aider à
                        maintenir votre bien-être général.
                      </p>
                      <h2 className="card-title mb-0 pb-1">
                        votre créneau horaire approprié pour un rappel gratuit
                      </h2>
                      {/* Date formatting and display */}
                      {offerData.dateSlot && (
                        <div className="mb-3 mt-3">
                          <span className="time_slot">
                            {format(
                              new Date(offerData.dateSlot),
                              "d MMMM yyyy",
                              {
                                locale: fr,
                              }
                            )}
                          </span>

                          <span className={"time_slot"}>
                            {offerData.timeSlot}
                          </span>
                        </div>
                      )}

                      <div className="mt-2">
                        <h4 className="card-title pb-1 mb-0">
                          Appeler l'assureur 01 84 20 00 60
                        </h4>
                        <p style={{ fontSize: "14px" }} className="p-0">
                          Posez toutes vos questions, les assureurs sont là pour
                          vous aider. Appelez du lundi au vendredi de 9h00 à
                          20h00.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {Object.keys(offerData).length !== 0 && (
        <div className={`${classes.container} mb-4`}>
          <div className={classes.provider}>
            <img
              className={classes.logo}
              src={`${process.env.PUBLIC_URL}/assets/img/${offerData.provider}-Logo.png`}
              alt={`${offerData.provider} Logo`}
            />
            <h4
              className={classes.label}
              style={{ fontSize: "15px", fontWeight: "700" }}
            >
              {offerData.name || offerData.label || offerData.provider}
            </h4>
            {offerData.code && <p className={classes.code}>{offerData.code}</p>}
          </div>
          <div className={classes.warranties}>
            {warranties.map((warranty, i) => (
              <WarrantyLevel
                key={nanoid(6)}
                icon={warranty.icon}
                level={offerData.levels[i]}
              />
            ))}
          </div>
          <div className={classes.more}>
            <div className={classes.price}>
              <div className={classes["monthly-price"]}>
                <span>{offerData.monthlyPrice} €</span>
                <span> /mois</span>
              </div>
              <div className={classes["yearly-price"]}>
                <p>
                  Budget estimé: {Math.round(offerData.monthlyPrice * 12)} €/ans
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}

export default OfferDetails;
