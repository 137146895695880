import { useState, useRef, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "moment/locale/fr";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import lang from "../../../../utils/language/french";
import { ConfirmationModal } from "../../../modals";
import { useAppSelector } from "../../../../store/stateHooks";
import { useNavigate } from "react-router-dom";
const ContactTable = ({ tbody, handleDelete }) => {
  const gridRef = useRef();
  const navigate = useNavigate();
  const [paginationRow, setPaginationRow] = useState(10);
  const { auth } = useAppSelector((state) => state.authCredential);
  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);

  const shortDateFormat = (params) => {
    const dateTimes = new Date(params.value);

    // Correct format for the year (yyyy instead of YYYY)
    const formattedEndDateTime = format(dateTimes, "dd MMM yyyy", {
      locale: fr,
    });
    return formattedEndDateTime;
  };

  const checkStatus = (params) => {
    const navigateHendle = () =>
      navigate("/admin/contact-update", { state: { data: params.data } });
    return params?.data?.status !== "Prospects" ? (
      <button
        className="badge rounded-pill bg-success"
        type="button"
        onClick={navigateHendle}
      >
        {params?.data?.status}
      </button>
    ) : (
      <button
        className="badge rounded-pill"
        style={{
          backgroundColor: "#fff4cc",
          color: "#af7e0a",
        }}
        type="button"
        onClick={navigateHendle}
      >
        {params?.data?.status}
      </button>
    );
  };

  const deleteContactRenderer = (params) => {
    return (
      <i
        className="bi bi-trash "
        style={{ cursor: "pointer" }}
        onClick={() => handleDelete(params.data._id)}
      ></i>
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Acquisition",
      valueGetter: "data.createdAt",
      cellRenderer: shortDateFormat,
    },
    {
      headerName: "Nom",
      maxWidth: 150,
      valueGetter: "data.firstName",
      // cellRenderer: (params) => params.value.phoneNumber,
    },
    {
      maxWidth: 150,
      headerName: "Prénom",
      valueGetter: "data.lastName",
    },
    {
      headerName: "Entreprise",
      valueGetter: "data.entreprise",
    },
    {
      headerName: lang.admin.offerHeader.email,
      valueGetter: "data.email",
    },
    {
      headerName: lang.admin.offerHeader.phoneNumber,
      valueGetter: "data.phone",
    },
    {
      headerName: lang.query.Status,
      field: "_id",
      valueGetter: "data.status",
      cellRenderer: checkStatus,
    },
    {
      cellRenderer: deleteContactRenderer,
      maxWidth: 150,
      cellStyle: { textAlign: "center" },
    },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    filterParams: {
      debounceMs: 100,
    },
    floatingFilter: true,
    resizable: true,
  }));

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  return (
    <div>
      <ConfirmationModal />
      <div className="tableOptions">
        <select
          className="form-control table_selection_control"
          name="pagination"
          onChange={(event) => setPagination(event)}
        >
          {lang.paginationRow.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="ag-theme-alpine" style={{ width: "100%", height: 480 }}>
        <AgGridReact
          ref={gridRef}
          rowData={tbody}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          paginationPageSize={paginationRow}
          suppressExcelExport={true}
          sortingOrder={sortingOrder}
          pagination={true}
          animateRows={true}
        />
      </div>
    </div>
  );
};

export default ContactTable;
