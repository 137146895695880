import { useRef } from "react";
import { nanoid } from "nanoid";

import Icon from "./Icon";
import {
  IWarranties,
  WarrantyLevel,
} from "../../store/userDataSlices/warrantiesSlice";

import classes from "./WarrantyBox.module.css";
import { useAppSelector } from "../../store/stateHooks";

export type WarrantyBoxGetState = (selectedLevel: WarrantyLevel) => void;

interface Props {
  title: string;
  icon: string;
  label: string;
  id: keyof IWarranties;
  getState?: WarrantyBoxGetState;
}

function WarrantyBox(props: Props) {
  const selectedLevelRef = useRef<HTMLDivElement | null>(null);

  const levels = ["Minimum", "Moyen", "Fort", "Maximum"];

  const selectedLevel = useAppSelector(
    (state) => state.userData.warranties[props.id].level
  );

  function clickHandler(e: React.MouseEvent) {
    const target = e.target as HTMLElement;
    let levelEl = target.classList.contains(classes.level)
      ? target
      : target.closest(".level");

    if (!levelEl || levelEl?.classList.contains(classes.selected)) {
      return;
    }

    if (props.getState) {
      props.getState(levelEl.textContent as WarrantyLevel);
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes["title-container"]}>
        <Icon name={props.icon} className={classes.icon} />
        <h4 className={classes.title} style={{marginBottom:"0px"}}>{props.title}</h4>
      </div>
      <p className={classes.label}>{props.label}</p>
      <div className={classes.levels} onClick={clickHandler}>
        {levels.map((level, i) => (
          <div
            key={nanoid(6)}
            className={`${classes.level} ${
              i === levels.indexOf(selectedLevel) ? classes.selected : ""
            }` + " warrantyLevelOption"}
            ref={
              i === levels.indexOf(selectedLevel) ? selectedLevelRef : undefined
            }
          >
            {level}
          </div>
        ))}
      </div>
    </div>
  );
}

export default WarrantyBox;
