import { useForm } from "react-hook-form";
import InputField from "../../../users/components/inputField";
import { useSignupMutation } from "../../../../apis/authService";
import lang from "../../../../utils/language/french";
import React from "react";
const SignupForm = ({ user, setShowAuthModal }) => {
  const [isLoading, seIsLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: `${user.firstName} ${user.lastName}`,
      email: user.email,
      phoneNumber: user.phoneNumber,
    },
  });
  const userSignup = useSignupMutation();
  const onSubmit = async (data) => {
    seIsLoading(true);
    try {
      await userSignup.mutateAsync(data);
      setShowAuthModal(true);
      seIsLoading(false);
    } catch (err) {
      seIsLoading(false);
      console.error(err);
    }
  };

  const password = watch("password", "");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-3">
        <InputField
          label={lang.common.fullName}
          name={"fullName"}
          type={"text"}
          placeholder={lang.common.fullName}
          register={register}
          errors={errors}
          validation={{ required: "Le nom complet est requis" }}
          id={"fullName-00000001"}
        />
        <InputField
          label={lang.common.email}
          name={"email"}
          type={"email"}
          placeholder={lang.common.email}
          register={register}
          errors={errors}
          validation={{
            required: "L'e-mail est requis",
            pattern: {
              value: /^\S+@\S+\.\S+$/,
              message: "Format d'e-mail invalide",
            },
          }}
          id={"email-00000023301"}
        />
        <InputField
          label={"Numéro de téléphone (facultatif)"}
          name={"phoneNumber"}
          type={"text"}
          placeholder={lang.common.phone}
          register={register}
          errors={errors}
          validation={{}}
        />
      </div>
      <div className="row mb-3">
        <div className="col-md-6">
          <InputField
            label={lang.admin.password}
            name="password"
            type="password"
            register={register}
            errors={errors}
            placeholder={lang.admin.password}
            validation={{
              required: "Le mot de passe est requis",
              minLength: {
                value: 8,
                message: "Le mot de passe doit comporter au moins 8 caractères",
              },
            }}
            id={"password-00000123123001"}
          />
        </div>
        <div className="col-md-6">
          <InputField
            label="Confirmez le mot de passe"
            name="confirmPassword"
            type="password"
            placeholder="Mot de passe"
            register={register}
            errors={errors}
            validation={{
              required: lang.admin.confirmPasswordPlaceholder,
              validate: (value) =>
                value === password || "Les mots de passe ne correspondent pas",
            }}
            id={"confirmPassword-0002342342300001"}
          />
        </div>
      </div>
      <button type="submit" className="btn btn-primary w-100 mt-3">
        {isLoading ? (
          <div
            style={{ height: "22px", width: "22px" }}
            className="spinner-border text-light"
          />
        ) : (
          "S'inscrire"
        )}
      </button>
    </form>
  );
};

export default SignupForm;
