import { useState } from "react";
import { nanoid } from "nanoid";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import RadioFieldGroup from "../../../UI/RadioFieldGroup";
import getRadioFieldRender from "../../../helpers/getRadioFieldRender";
import RadioField from "../../../UI/RadioField";

interface Props {
  onAnswer: (value: string, index: number) => void;
}

function OldContractExpiryMonth(props: Props) {
  const [showMoreMonths, setShowMoreMonths] = useState(false);

  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  // Get the current month index and the next month index
  const currentMonthIndex = new Date().getMonth(); // 0-11 for Jan-Dec
  const nextMonthIndex = (currentMonthIndex + 1) % 12; // 0-11 for Jan-Dec

  // Calculate the next three months indices
  const nextThreeMonthsIndices = [
    currentMonthIndex,
    nextMonthIndex,
    (currentMonthIndex + 2) % 12,
  ];

  function answerHandler(value: string, index: number) {
    props.onAnswer(value, index);
  }

  function showMoreMonthsHandler() {
    setShowMoreMonths(true);
  }

  return (
    <QuestionBox targetState="credentials" scrollToIt={true}>
      <FieldLabel>Mois d'échéance du contrat</FieldLabel>
      <RadioFieldGroup
        type="horizontal"
        items={
          showMoreMonths
            ? months // Show all months if "showMoreMonths" is true
            : months.filter((month, i) => nextThreeMonthsIndices.includes(i)) // Show the next three months
        }
        renderItem={getRadioFieldRender("horizontal")}
        getSelectedValue={answerHandler}
      >
        {!showMoreMonths && (
          <RadioField
            text="Autres mois..."
            type="horizontal"
            key={nanoid(6)}
            onClick={showMoreMonthsHandler}
            textAlign="center"
          />
        )}
      </RadioFieldGroup>
    </QuestionBox>
  );
}

export default OldContractExpiryMonth;
