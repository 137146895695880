import getRadioFieldRender from "../../../helpers/getRadioFieldRender";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import RadioFieldGroup from "../../../UI/RadioFieldGroup";

interface Props {
  getIsInsured: (value: boolean) => void;
}

function Insure(props: Props) {
  function answerHandler(value: string) {
    const isInsured = value === "Oui";
    props.getIsInsured(isInsured);
  }

  return (
    <QuestionBox targetState="credentials" scrollToIt={true}>
      <FieldLabel>Êtes-vous actuellement assuré(e) ?</FieldLabel>
      <RadioFieldGroup
        type="vertical"
        items={["Oui", "Non"]}
        renderItem={getRadioFieldRender("vertical", [
          "checkmarkDoneOutline",
          "closeOutline",
        ])}
        getSelectedValue={answerHandler}
      />
    </QuestionBox>
  );
}

export default Insure;
