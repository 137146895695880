import SectionHeading from "../../UI/SectionHeading";

import Levels from "./questions/Levels";
import FormBox from "../../UI/FormBox";
import Button from "../../UI/Button";
import { navigationActions } from "../../../store/appStateSlices/navigationSlice";
import { useAppDispatch } from "../../../store/stateHooks";
import ClientsSatisfaits from "../../UI/clientsview/ClientsSatisfaits";
import Fag from "../../UI/Faq/Fag";
import KnowMore from "../../UI/KnowMore";
import Footer from "../../UI/Footer";
import PageTopText from "../../UI/pageTopText";

function SelectWarranties() {
  const dispatch = useAppDispatch();

  function nextPageHandler() {
    dispatch(navigationActions.setActiveFormPage(3));
  }

  return (
    <>
      <PageTopText />
      <FormBox>
        <SectionHeading>Besoins</SectionHeading>
        <Levels />
        <Button   onClick={nextPageHandler}>Continuer</Button>
      </FormBox>
      <ClientsSatisfaits />
      <Fag />
      <KnowMore />
      <Footer />
    </>
  );
}

export default SelectWarranties;
