import * as React from "react";

import { ReactComponent as TimesSvg } from "../../icons/Times.svg";
import { ReactComponent as VotreRappelSvg } from "../../icons/VotreRappel.svg";
import { ReactComponent as PlusDinforSvg } from "../../icons/plusDinfor.svg";

import { TransitionProps } from "@mui/material/transitions";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Stack,
  Typography,
} from "@mui/material";

import TextFieldGroup from "../TextFieldGroup";
import TextField from "../TextField";

import StepOne from "./StepOne";
import { useAppSelector } from "../../../store/stateHooks";

interface MyButtonProps {
  /** The text to display inside the button */
  JesouhaiteOpen: boolean;
  setJesouhaiteOpen: Function;
  confirmUsersOffer: Function;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function JeSouhaiteUnRappelMain({
  JesouhaiteOpen,
  setJesouhaiteOpen,
  confirmUsersOffer,
}: MyButtonProps) {
  const subscriberData = useAppSelector((state) => state.userData);
  React.useEffect(() => {
    setOpen(JesouhaiteOpen);
  }, [JesouhaiteOpen]);

  const [open, setOpen] = React.useState(false);
  const [isStepTwo, setIsStepTwo] = React.useState(1);
  const { phoneNumber, lastName, firstName, insureDate } = subscriberData.credentials;
  console.log(subscriberData)
  React.useEffect(() => {
    setOpen(JesouhaiteOpen);
  }, [JesouhaiteOpen]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
    setJesouhaiteOpen(false);
    setIsStepTwo(1);
  }, [setJesouhaiteOpen]);
  // State object for form values and errors
  const [formState, setFormState] = React.useState({
    name: `${lastName ?? ""} ${firstName ?? ""}`.trim(),
    phone: phoneNumber || "",
    date: insureDate || new Date(),
    timeSlot: "",
    errors: {
      name: null as string | null,
      phone: null as string | null,
    },
  });

  // Validators
  const validateName = (name: string) => (name ? null : "Le nom est requis");
  const validatePhone = (phone: string) => {
    if (!phone) return "Le numéro de téléphone est requis";
    if (!/^\d{10}$/.test(phone)) return "Le numéro doit être de 10 chiffres";
    return null;
  };

  // Handle form input change and validation
  const handleInputChange = (name: string, value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
      errors: {
        ...prevState.errors,
        [name]: name === "name" ? validateName(value) : validatePhone(value),
      },
    }));
  };

  const handleNext = () => {
    const nameError = validateName(formState.name);
    const phoneError = validatePhone(formState.phone);
    const data = {
      name: formState.name,
      phone: formState.phone,
      date: formState.date,
      timeSlot: formState.timeSlot,
    };

    // Set errors and proceed to step two if valid
    setFormState((prevState) => ({
      ...prevState,
      errors: { name: nameError, phone: phoneError },
    }));

    if (isStepTwo !== 1) {
      confirmUsersOffer(data);
      handleClose();
    } else setIsStepTwo(2);
  };

  return (
    <React.Fragment>
      <>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          sx={{
            borderRadius: "24px",
            mx: "auto",
            "& .MuiDialog-paper": {
              borderRadius: "24px",
              width: "438px",
              maxWidth: "438px",
              py: "24px",
              px: "24px",
            },
          }}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                opacity: "75%",
                fontWeight: "400",
                textTransform: "capitalize",
              }}
            >
              {`étape ${isStepTwo}/2`}
            </Typography>
            <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
              <TimesSvg />
            </Box>
          </Stack>
          <DialogTitle sx={{ textAlign: "center" }}>
            <VotreRappelSvg />
            <Typography
              sx={{
                fontFamily: `"Nunito", sans-serif`,
                fontSize: "28px",
                fontWeight: "700",
                color: "#37474F",
              }}
            >
              Planifiez votre rappel
            </Typography>
            <Typography
              variant="body2"
              color={"#37474F"}
              sx={{ opacity: "75%", maxWidth: "327px", mx: "auto" }}
            >
              Entrez votre nom et numéro de téléphone pour recevoir un rappel.
            </Typography>
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {isStepTwo === 1 ? (
                <>
                  <Box mb={2}>
                    <Typography
                      variant="body2"
                      color={"#37474F"}
                      sx={{ fontSize: "12px", opacity: "75%", mb: 0.5 }}
                    >
                      Nom
                    </Typography>
                    <TextFieldGroup>
                      <TextField
                        placeHolder="Entrez votre nom"
                        getState={(e) => handleInputChange("name", e)}
                        value={formState.name}
                        name="name"
                      />
                    </TextFieldGroup>
                  </Box>
                  <Box mb={1}>
                    <Typography
                      variant="body2"
                      color={"#37474F"}
                      sx={{ fontSize: "12px", opacity: "75%", mb: 0.5 }}
                    >
                      Numéro de téléphone
                    </Typography>
                    <TextFieldGroup>
                      <TextField
                        placeHolder="Ex: 06 10 00 10 00"
                        getState={(e) => handleInputChange("phone", e)}
                        value={formState.phone}
                        name="phone"
                      />
                    </TextFieldGroup>
                  </Box>
                </>
              ) : (
                <>
                  <Box mb={1}>
                    <StepOne
                      handleInputChange={(name: string, value: any) =>
                        handleInputChange(name, value)
                      }
                    />
                  </Box>
                </>
              )}
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: "center",
                  alignItems: "start",
                }}
              >
                <PlusDinforSvg />
                <Typography
                  variant="body2"
                  color={"#37474F"}
                  sx={{ fontSize: "12px", opacity: "75%" }}
                >
                  Nous respectons votre vie privée, votre numéro ne sera jamais
                  partagé.
                </Typography>
              </Stack>
            </DialogContentText>
            <Stack
              direction="row"
              spacing={4}
              mt={2}
              sx={{
                justifyContent: "space-between",
                alignItems: "start",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#F0F0F0",
                  color: "#37474F",
                  borderRadius: "26.5px",
                  width: "100%",
                  padding: "12px 30px",
                  "&:hover": {
                    color: "#ffffff",
                  },
                }}
                onClick={() => setIsStepTwo(1)}
              >
                Annuler
              </Button>
              <Button
                variant="contained"
                // disabled
                sx={{
                  bgcolor:
                    formState.errors.name || formState.errors.phone
                      ? "grey.300"
                      : "primary.main",
                  color:
                    formState.errors.name || formState.errors.phone
                      ? "grey.500"
                      : "white",
                  borderRadius: 2,
                  width: "100%",
                  py: 1.5,
                }}
                onClick={handleNext}
              >
                {isStepTwo ? "Suivant" : "Souscrire"}
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      </>
    </React.Fragment>
  );
}
