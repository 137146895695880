import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";

import "./index.css";
import store, { persistor } from "./store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from "redux-persist/integration/react";
import { init as initApm } from "@elastic/apm-rum";


const initializeApm = () => {
  try {
    const apm = initApm({
      serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
      environment: process.env.REACT_APP_APM_ENVIRONMENT,
      serverUrl: process.env.REACT_APP_APM_SERVER_URL,
      serviceVersion: "1.0.0",
      logLevel: "info",
      breakdownMetrics: true,
      transactionSampleRate: 1.0,
    });
    apm.startTransaction("App Load", "app-load");
  } catch (error) {
    console.error("Failed to initialize APM:", error);
  }
};

initializeApm();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ToastContainer
          className="foo" style={{ width: "auto" }}
        />
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);
