export default function getYearsDifference(providedDate: Date) {
  const providedDateInMs = providedDate.getTime();
  const maxSupportedDate = Math.abs(new Date(1900, 0, 1).getTime());

  if (providedDateInMs < 0 && Math.abs(providedDateInMs) > maxSupportedDate) {
    return -1;
  }

  const nowInMs = Date.now();
  if (providedDateInMs > nowInMs) {
    return 0;
  }

  const diffInMs = nowInMs - providedDateInMs;

  return Math.ceil(diffInMs / 1000 / 60 / 60 / 24 / 365);
}
