import { useForm } from "react-hook-form";
import InputField from "../components/inputField";
import { api } from "../../../utils/storage";
import { putAPI } from "../../../apis/Services";
import showToast from "../../../utils/toast";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const body = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };
      const res = await putAPI(api.resetPassword, body);
      showToast(res.message, "success");
    } catch (err) {
      const errorMessage =
        err.response?.data?.msg ||
        "Quelque chose s'est mal passé, veuillez réessayer.";
      showToast(errorMessage, "error");
    }
  };

  const newPassword = watch("newPassword");

  return (
    <main id="main" className="main">
      <div className="d-flex justify-content-start align-items-center ">
        <div
          className="card p-4 shadow-lg"
          style={{ maxWidth: "500px", width: "100%" }}
        >
          <h4 className="fw-bold mb-4 text-start " style={{color: '#37474F'}}>
          Réinitialiser le mot de passe
          </h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              key="oldPassword"
              label="Ancien mot de passe"
              name="oldPassword"
              type="password"
              placeholder="Entrez votre ancien mot de passe"
              register={register}
              errors={errors}
              validation={{
                required: "L'ancien mot de passe est requis",
              }}
            />

            <InputField
              key="newPassword"
              label="Nouveau mot de passe"
              name="newPassword"
              type="password"
              placeholder="Entrez votre nouveau mot de passe"
              register={register}
              errors={errors}
              validation={{
                required: "Un nouveau mot de passe est requis",
                minLength: {
                  value: 8,
                  message: "Le mot de passe doit comporter au moins 8 caractères",
                },
              }}
            />

            <InputField
              key="confirmPassword"
              label="Confirmer le nouveau mot de passe"
              name="confirmPassword"
              type="password"
              placeholder="Confirmer le nouveau mot de passe"
              register={register}
              errors={errors}
              validation={{
                required: "Veuillez confirmer votre mot de passe",
                validate: (value) =>
                  value === newPassword ||
                  "Les mots de passe ne correspondent pas",
              }}
            />

            <button type="submit" className="btn btn-primary w-100 mt-3 py-2"  style={{backgroundColor:'#006CB7', borderRadius: '99px'}}>
              Réinitialiser le mot de passe
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default ResetPassword;
