import axios from "axios";
import { nanoid } from "nanoid";
import { useEffect, useState, MouseEvent } from "react";

import { PostalCode } from "../hooks/useAPI";

import classes from "./AddressMenu.module.css";

interface Props {
  query: string;
  onSelect: (address: string) => void;
}

function AddressMenu(props: Props) {
  const { query } = props;
  const [data, setData] = useState<PostalCode[]>();
  const API_URL = process.env.REACT_APP_API_SERVER;
  useEffect(() => {
    async function loadPostalCodes() {
      try {
        const response = await axios({
          url: `${API_URL}/api/v1/postal_codes`,
          method: "POST",
          data: {
            query,
            key: process.env.REACT_APP_POSTAL_CODES_KEY!,
          },
        });

        setData(response.data.data.postalCodes as PostalCode[]);
      } catch (err) {
        return;
      }
    }

    loadPostalCodes();
  }, [query]);

  const renderCondition = data && data.length > 0;

  function renderAddresses() {
    const postalCodes = data as PostalCode[];

    return postalCodes.map((postalCode) => (
      <p className={classes.address} key={nanoid(6)} style={{marginBottom:"0px"}}>
        {`${postalCode.zipCode} - ${postalCode.city}`}
      </p>
    ));
  }

  function addressSelectionHandler(e: MouseEvent) {
    const target = e.target as HTMLElement;

    if (!target.classList.contains(classes.address)) {
      return;
    }

    props.onSelect(target.textContent!);
  }

  return (
    <>
      {renderCondition && (
        <div className={classes.container}>
          <div className={classes.addresses} onClick={addressSelectionHandler}>
            {renderAddresses()}
          </div>
        </div>
      )}
    </>
  );
}

export default AddressMenu;
