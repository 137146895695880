// import { nanoid } from "nanoid";

// import OffersHeading from "./OffersHeading";
// import Offer from "./Offer";

// import classes from "./OffersBox.module.css";
// import { Offer as IOffer } from "../hooks/useAPI";
// import { navigationActions } from "../../store/appStateSlices/navigationSlice";
// import { useAppDispatch } from "../../store/stateHooks";

// interface Props {
//   offers: IOffer[];
// }

// function OffersBox(props: Props) {
//   const dispatch = useAppDispatch();
//   function renderOffers() {
//     if (props?.offers != null) {
//       return props.offers.map((offer) => {
//         const levels = Object.values(offer.warranties!).map((warranty) => {
//           return warranty.level;
//         });

//         return (
//           <Offer
//             key={nanoid(6)}
//             provider={offer.provider}
//             monthlyPrice={offer.monthlyPrice}
//             levels={levels}
//             name={offer.name}
//             label={offer.label}
//             code={offer.code}
//             description={offer.description}
//             check={0}
//           />
//         );
//       });
//     } else dispatch(navigationActions.setActiveFormPage(1));
//   }
//   return (
//     <div className={classes.container}>
//       {/* <OffersHeading offersNumber={props?.offers?.length}>Offres</OffersHeading> */}
//       <div className={classes["offers-container"]}>{renderOffers()}</div>
//     </div>
//   );
// }

// export default OffersBox;

import React, { useState, useEffect, useRef } from "react";
import { Offer as IOffer } from "../hooks/useAPI";
import { useAppDispatch } from "../../store/stateHooks";
import { navigationActions } from "../../store/appStateSlices/navigationSlice";
import Offer from "./Offer";
import classes from "./OffersBox.module.css";

import LoadingSpinner from "./LoadingSpinner";
interface Props {
  offers: IOffer[];
}

function OffersBox({ offers }: Props) {
  const dispatch = useAppDispatch();

  const [visibleOffers, setVisibleOffers] = useState<IOffer[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const loaderRef = useRef<HTMLDivElement | null>(null);

  const ITEMS_PER_PAGE = 20;

  useEffect(() => {
    // Load the first batch of offers when the component mounts
    if (offers) {
      setVisibleOffers(offers.slice(0, ITEMS_PER_PAGE));
      setCurrentIndex(ITEMS_PER_PAGE);
    } else {
      dispatch(navigationActions.setActiveFormPage(1));
    }
  }, [offers, dispatch]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          loadMoreOffers();
        }
      },
      { root: null, rootMargin: "20px", threshold: 1.0 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loaderRef.current, currentIndex]);

  const loadMoreOffers = () => {
    if (currentIndex < offers.length) {
      const nextBatch = offers.slice(
        currentIndex,
        currentIndex + ITEMS_PER_PAGE
      );
      setVisibleOffers((prev) => [...prev, ...nextBatch]);
      setCurrentIndex((prev) => prev + ITEMS_PER_PAGE);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes["offers-container"]}>
        {visibleOffers.map((offer, i) => {
          const levels = Object.values(offer.warranties!).map(
            (warranty) => warranty.level
          );

          return (
            <Offer
              key={`${offer.code}-${i}`}
              provider={offer.provider}
              monthlyPrice={offer.monthlyPrice}
              levels={levels}
              name={offer.name}
              label={offer.label}
              code={offer.code}
              description={offer.description}
              check={0}
            />
          );
        })}
        {/* Loader div for IntersectionObserver */}
        {currentIndex < offers.length && (
          <div ref={loaderRef} className={"d-flex justify-content-center"}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
}

export default OffersBox;
