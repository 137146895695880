import * as React from "react";

import { ReactComponent as TabDownSvg } from "../../icons/TabDown.svg";
import { ReactComponent as TimesSvg } from "../../icons/Times.svg";

import { TransitionProps } from "@mui/material/transitions";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import OfferLastButton from "../OfferLastButton";
import PhoneModel from "../PhoneModel";
interface MyPropoAssurancesProps {
  confirmUsersOffer: Function;
  directSUbmitOffer: Function;
  confirmConsultation: Function;
  offer: Object;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PropoAssurances({
  confirmUsersOffer,
  directSUbmitOffer,
  confirmConsultation,
  offer,
}: MyPropoAssurancesProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <>
        <Box
          onClick={handleClickOpen}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
        >
          <TabDownSvg />
          <Typography sx={{ fontSize: "12px", color: "#455A64" }}>
            Savoir Plus
          </Typography>
        </Box>

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          sx={{
            textAlign: "center",
            borderRadius: "24px",
            mx: "auto",

            "& .MuiDialog-paper": {
              borderRadius: "24px",
              maxWidth: "770px",
              py: "20px",
              px: "10px",
            },
          }}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "10px",
              right: "20px",
              cursor: "pointer",
            }}
          >
            <TimesSvg />
          </Box>
          <DialogTitle
            sx={{
              fontSize: "20px",
              fontWeight: "700",
              color: "#37474F",
              pb: 0.9,
              textAlign: "left",
            }}
          >
            À propos FMA assurances
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              sx={{
                color: "#69757B",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: "left",
              }}
            >
              <Typography>
                L'offre d'assurance avec le code , intitulée "" proposée par ,
                offre une couverture pour divers avantages. Le prix mensuel de
                cette assurance est de €.
              </Typography>
              <Typography sx={{ my: 2 }}>
                offre une couverture complète pour les particuliers,
                garantissant protection et tranquillité d'esprit. Avec ce plan
                d'assurance, vous pouvez profiter d'une large gamme d'avantages
                adaptés à vos besoins. Que ce soit pour les frais médicaux,
                l'hospitalisation ou d'autres services de santé, vous couvre.
              </Typography>
              <Typography>
                Le plan d'assurance propose un prix mensuel compétitif de€, ce
                qui en fait une option abordable pour les particuliers
                recherchant une couverture fiable. , un fournisseur d'assurance
                réputé, veille à ce que vous receviez le soutien et l'assistance
                dont vous avez besoin chaque fois que vous en avez besoin.
              </Typography>
              <Typography sx={{ my: 2 }}>
                Avec , vous pouvez faire face en toute confiance à toutes les
                circonstances imprévues, sachant que vous bénéficiez d'une
                couverture d'assurance fiable. Ce plan vise à vous offrir une
                sécurité financière et à vous aider à maintenir votre bien-être
                général.
              </Typography>
            </DialogContentText>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <OfferLastButton
                model={false}
                confirmUsersOffer={confirmUsersOffer}
                directSUbmitOffer={directSUbmitOffer}
                confirmConsultation={confirmConsultation}
                offer={offer}
              />
            </Box>
            <Box
              mt={2.5}
              sx={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "20px", fontWeight: "600", textAlign: "left" }}
                color={"#37474F"}
              >
                Appeler l'assureur
              </Typography>
              <PhoneModel color={"#000"} />
            </Box>
          </DialogContent>
        </Dialog>
      </>
    </React.Fragment>
  );
}
