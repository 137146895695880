import axios from "axios";
import {
  getRefrashToken,
  setSessionData,
  getSessionData,
  setRefrashToken,
} from "./auth.atom";
import { api } from "../utils/storage";
import showToast from "../utils/toast";

const createAxiosDefaults = {
  baseURL: process.env.REACT_APP_API_SERVER,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language": "fr",
  },
};

const instance = axios.create(createAxiosDefaults);

let isRefreshing = false;
let failedRequestsQueue = [];

const processQueue = (error, token = null) => {
  failedRequestsQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedRequestsQueue = [];
};

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;

    // Check for network errors or if there is no internet
    if (!error.response) {
      const customError = {
        message:
          "Problème de réseau détecté. Vérifiez votre connexion Internet.",
        statusCode: null, // No status code for network errors
      };
      return Promise.reject(customError);
    }

    const statusCode = error.response.status;
    let customMessage =
      "Oups, une erreur s'est produite. Veuillez réessayer plus tard.";

    // Handle specific status codes
    if (
      statusCode === 403 &&
      error.response.data?.message === "Jeton expiré..."
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const result = await refreshToken(); // Refresh the token

          // Update the original request's authorization header with the new token
          originalConfig.headers.Authorization = `Bearer ${result.access.token}`;

          processQueue(null, result.access.token);

          // Retry the original request with the new token
          return instance(originalConfig);
        } catch (refreshError) {
          processQueue(refreshError, null);
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false;
        }
      } else {
        // If token refresh is in progress, enqueue this request
        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({ resolve, reject });
        });
      }
    } else if (statusCode >= 500) {
      customMessage = "Erreur interne du serveur, contratez l'administrateur.";
      showToast(customMessage, "error");
    } else if (statusCode === 413) {
      customMessage = "Entité de requête trop grande.";
      showToast(customMessage, "error");
    } else if (statusCode >= 400) {
      customMessage = error.response.data?.message || customMessage; // Use server message if available
    }

    // Create a custom error object
    const customError = {
      message: customMessage,
      statusCode: statusCode,
    };

    console.error(`Error ${statusCode}: ${customMessage}`);
    return Promise.reject(customError);
  }
);

const refreshToken = async () => {
  try {
    if (localStorage) {
      const data = await baseAxios.post(api.refreshToken, {
        refreshToken: getRefrashToken(),
      });
      if (data) {
        setSessionData(data.access.token);
        setRefrashToken(data.refreshToken.token);
        processQueue(null, data);
        return data;
      }
    }
  } catch (err) {
    processQueue(err, null);
    return Promise.reject(err);
  }
};
/**
 * Returns each of the wrapped fetch methods.
 */

export const baseAxios = {
  get: (url) => 
    request(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getSessionData()}`,
        "Accept-Language": "fr",
      },
    }),
  post: (url, body) =>
    request(url, {
      method: "POST",
      data: body,
      headers: {
        Authorization: `Bearer ${getSessionData()}`,
        "Accept-Language": "fr",
      },
    }),
  postMedia: (url, formData) =>
    request(url, {
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Bearer ${getSessionData()}`,
        "Content-Type": "multipart/form-data",
        "Accept-Language": "fr",
      },
    }),
  put: (url, body) =>
    request(url, {
      method: "PUT",
      data: body,
      headers: {
        Authorization: `Bearer ${getSessionData()}`,
        "Content-Type": "multipart/form-data",
        "Accept-Language": "fr",
      },
    }),
  delete: (url, body) =>
    request(url, {
      method: "DELETE",
      data: body,
      headers: {
        Authorization: `Bearer ${getSessionData()}`,
        "Accept-Language": "fr",
      },
    }),
};

/**
 * Wraps a fetch request
 *
 * @param url URL to fetch
 * @param options fetch options
 * @returns response object
 */

async function request(url, options) {
  return instance(url, options)
    .then((res) => res.data)
    .catch((err) => onError(err));
}

/**
 * Formats an error response
 *
 * @param response Response object
 */
export const onError = async (error) => {
  // console.error(error);
  let errorMessage =
    "Oups, Une erreur s'est produite. Veuillez réessayer plus tard.";
  if (error) {
    return Promise.reject(error);
  }
  const { response } = error;
  errorMessage = response.message ? response.message : errorMessage;
  // eslint-disable-next-line
  console.error(`${response.status} - ${errorMessage}`);

  return Promise.reject(errorMessage);
};

export default baseAxios;
