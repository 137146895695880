// import React from "react";
// import AsyncSelect from "react-select/async";

// const CustomAsyncSelect = ({
//   label,
//   placeholder,
//   loadOptions,
//   onChange,
//   defaultValue,
//   isMulti = false,
//   name,
//   errors,
//   validation,
//   register,
// }) => {
//   return (
//     <div className="">
//       {label && (
//         <label className="form-label" style={{ color: "#000" }}>
//           {label}
//         </label>
//       )}
//       <AsyncSelect
//         cacheOptions
//         loadOptions={loadOptions}
//         defaultValue={defaultValue}
//         onChange={(selected) => onChange(selected, name)}
//         isMulti={isMulti}
//         placeholder={placeholder}
//         defaultOptions
//         // cacheOptions loadOptions={loadOptions} defaultOptions
//       />
//       {errors[name] && <p className="text-danger">{errors[name].message}</p>}
//       {/* To use register, we register the name for validation */}
//       <input
//         type="hidden"
//         name={name}
//         // ref={register(validation)}
//       />
//     </div>
//   );
// };

// export default CustomAsyncSelect;

import React from "react";
import AsyncSelect from "react-select/async";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#f5f5f5", // Light grey background
    borderRadius: "6px", // Rounded corners
    padding: "4px 4px", // Padding inside the select box
    border: "0px solid #e2e2e2", // Light border color
    boxShadow: state.isFocused ? "0 0 0 1px rgba(0,123,255,.25)" : "none", // Optional focus effect
    "&:hover": {
      borderColor: "#ccc", // Slightly darker border on hover
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#6c757d", // Grey placeholder color
    fontSize: "16px", // Larger font size if needed
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#343a40", // Darker text color for selected value
    fontSize: "16px",
  }),
};

const CustomAsyncSelect = ({
  label,
  placeholder,
  loadOptions,
  onChange,
  defaultValue,
  isMulti = false,
  name,
  errors,
  validation,
  register,
}) => {
  return (
    <div className="form-group">
      {label && (
        <label htmlFor={name} className="form-label" style={{ color: '#37474F', fontWeight: '300', fontSize: '12px' }}>
          {label}
        </label>
      )}
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        defaultValue={defaultValue}
        onChange={(selected) => onChange(selected, name)}
        isMulti={isMulti}
        placeholder={placeholder}
        defaultOptions
        styles={customStyles} // Apply custom styles here
      />
      {errors[name] && <p className="text-danger">{errors[name].message}</p>}
      {/* To use register, we register the name for validation */}
      <input
        type="hidden"
        name={name}
        {...register(name, validation)}
      />
    </div>
  );
};

export default CustomAsyncSelect;
