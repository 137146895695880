

import { Box, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import classes from "./Footer.module.css";
import { ReactComponent as LogoWhiteSvg } from '../icons/logoW.svg';
import { ReactComponent as FacebookSvg } from '../icons/facebook.svg';
import { ReactComponent as InstaSvg } from '../icons/Insta.svg';
import { ReactComponent as TwitterSvg } from '../icons/twitter.svg'; 
import PhoneModel from "./PhoneModel";


function Footer() {
    return (
        <>
            <Box bgcolor='#455A64' py={{ xs: 3, sm: 1 }} px={3}>

                <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item xs={12} sm={4}>
                        <LogoWhiteSvg />
                    </Grid>
                    <Grid item xs={12} sm={4} sx={{display: {xs: 'none', sm:'block'}}}>
                        <div className={classes.textColor}>1Swis - Copyright 2024 All rights reserved.</div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box className={classes.rightSidera}>
                            <PhoneModel color= {'#ffffff'}/>
                            {/* <Link className={classes.phone} to='tel:01 84 20 00 60'>
                                <PhoneSvg />
                                <div className={classes.linkMen}> 01 84 20 00 60</div>
                            </Link> */}

                            <Link className={classes.linkMen} to='/mentions_legales'>Mentions légales</Link>

                            <Stack
                                direction={{ xs: 'row', sm: 'row' }}
                                spacing={1}
                                sx={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Link to='https://www.facebook.com/1swis.fr/' target="_blank" className={classes.socialBox}> <FacebookSvg /></Link>
                                <Link to='/' className={classes.socialBox}><InstaSvg /></Link>
                                <Link to='/' className={classes.socialBox}><TwitterSvg /></Link>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Box className={classes.textColor}  sx={{display: {xs: 'block', sm:'none'}}}>1Swis - Copyright 2024 All rights reserved.</Box>
            </Box>

        </>
    );
}

export default Footer;
