import { IMembers } from "../../store/userDataSlices/membersSlice";

export default function getMembersIsFulfilled(state: IMembers) {
  const testObject: { [key: string]: string | boolean | undefined } = {
    status: state.status,
    gender: state.gender,
    birthDate: state.birthDate,
    activity: state.activity,
    socialRegime: state.socialRegime,
  };

  if (
    testObject.status === "Un adulte + enfant(s)" ||
    testObject.status === "Un couple + enfant(s)"
  ) {
    if (!state.children) {
      testObject.children = false;
    } else {
      Object.entries(state.children!).forEach((child) => {
        testObject[child[0]] = child[1].birthDate;
      });
    }
  }

  if (
    testObject.status === "Un couple" ||
    testObject.status === "Un couple + enfant(s)"
  ) {
    testObject.partnerGender = state.partnerGender;
    testObject.partnerBirthDate = state.partnerBirthDate;
  }

  return Object.values(testObject).every((value) => value);
}
