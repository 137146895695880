import Button from '@mui/material/Button';
import { ReactComponent as SouscrireSvg } from '../icons/Souscrire.svg';
import { ReactComponent as JeSouhSvg } from '../icons/JeSouh.svg';
import { ReactComponent as PrendreSvg } from '../icons/Prendre.svg';
import JeSouhaiteUnRappelMain from './JeSouhaiteUnRappel/JeSouhaiteUnRappelMain';
import PrendreRendezVous from './PrendreRendezVous/PrendreRendezVous';
import { useState } from 'react';

interface MyButtonProps {
    model: boolean;
    JesouhaiteOpen?: boolean;
    setJesouhaiteOpen?: (open: boolean) => void;
    confirmUsersOffer: Function;
    directSUbmitOffer: Function;
    confirmConsultation:Function;
    offer: Object;
}

export default function OfferLastButton({
    model,
    confirmUsersOffer,
    JesouhaiteOpen,
    setJesouhaiteOpen,
    directSUbmitOffer,
    confirmConsultation,
    offer,
}: MyButtonProps) {
    const [PrendreRendezvousOpen, setPrendreRendezvousOpen] = useState(false);

    return (
        <>
            <Button
                variant="contained"
                onClick={() => directSUbmitOffer()}
                sx={
                    model
                        ? { borderRadius: 12.5, height: '53px', px: 4 }
                        : { borderRadius: 12.5, height: '45px', px: 2, fontSize: '14px' }
                }
                color="primary"
                startIcon={<SouscrireSvg />}
            >
                Souscrire maintenant
            </Button>

            {JesouhaiteOpen !== undefined && setJesouhaiteOpen && (
                <Button
                    variant="contained"
                    onClick={() => setJesouhaiteOpen(!JesouhaiteOpen)}
                    sx={
                        model
                            ? { bgcolor: '#32DEAC', borderRadius: 12.5, height: '53px', px: 4 }
                            : { bgcolor: '#32DEAC', borderRadius: 12.5, height: '45px', px: 2, fontSize: '14px' }
                    }
                    startIcon={<JeSouhSvg />}
                >
                    Je souhaite un rappel
                </Button>
            )}

            <Button
                onClick={() => setPrendreRendezvousOpen(!PrendreRendezvousOpen)}
                startIcon={<PrendreSvg />}
                sx={
                    model
                        ? { borderRadius: 12.5, height: '53px', px: 4 }
                        : { borderRadius: 12.5, height: '45px', px: 2, fontSize: '14px' }
                }
            >
                Prendre rendez-vous
            </Button>

            {JesouhaiteOpen !== undefined && setJesouhaiteOpen && (
                <JeSouhaiteUnRappelMain
                    JesouhaiteOpen={JesouhaiteOpen}
                    setJesouhaiteOpen={setJesouhaiteOpen}
                    confirmUsersOffer={confirmUsersOffer}
                />
            )}

            <PrendreRendezVous
                PrendreRendezvousOpen={PrendreRendezvousOpen}
                setPrendreRendezvousOpen={setPrendreRendezvousOpen}
                confirmUsersOffer={confirmConsultation}
                offer={offer}
            />
        </>
    );
}
