
import { Box, Button, Grid, Stack, Typography, } from '@mui/material'
import { ReactComponent as KnowMoreSvg } from '../icons/KnowMore.svg';
import { ReactComponent as ArrowSvg } from '../icons/arrow.svg';


export default function KnowMore() {

    return (
        <div className='container'>
            <Box bgcolor='#2D4E6F' my={{xs: 1, md: 5}} sx={{ borderRadius: '16px', }}>
                <Grid container spacing={6}  sx={{ pt: { xs: 2, md: 2 }, px: 3, pb: { xs: 4, sm: 0 } }}>
                    <Grid item xs={12} sm={5}>
                        <Box sx={{ width: '100%', textAlign: { xs: 'center', sm: 'right' }, }}>
                            <KnowMoreSvg />
                            {/* {
                                onlySmallScreen ? <KnowMoreSvg style={{ maxWidth: '400px' }}/> : <KnowMoreSvg style={{ maxWidth: '200px' }} />
                            } */}

                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={7} sx={{ alignContent: 'start', pt: { xs: 4, md: 0 }, }}>
                        <Stack
                            sx={{
                                alignItems: "start",
                                alignContent: 'center',

                            }}
                        >
                            <Typography variant='h5' color='#fff'>Besoin d’aide ?</Typography>
                            <Typography variant='body1' sx={{ color: '#ffffff', opacity: '75%', fontWeight: '300', fontSize: '16px', maxWidth: '430px', my:2 }}>Pour toute question ou suivi de votre souscription, nos conseillers sont à votre disposition.</Typography>
                            <Button variant="contained" endIcon={<ArrowSvg />} sx={{ borderRadius: 100, backgroundColor: '#fff', mt: 2, px: 3.5, py: 1.5, color: '#37474F' }} >
                                Contactez-nous
                            </Button>
                        </Stack>

                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
