import * as React from "react";

import { ReactComponent as TabDownSvg } from "../../icons/TabDown.svg";
import { ReactComponent as TimesSvg } from "../../icons/Times.svg";

import { TransitionProps } from "@mui/material/transitions";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Slide,
} from "@mui/material";
import ConsultationBlock from "./ConsultationBlock";
import CalendarBlock from "./CalendarBlock";
import IndiquezVosInfo from "./IndiquezVosInfo";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface MyFunctionProps {
  /** The text to display inside the button */
  PrendreRendezvousOpen: boolean;
  setPrendreRendezvousOpen: Function;
  confirmUsersOffer: Function;
  offer: any;
}

export default function PrendreRendezVous({
  PrendreRendezvousOpen,
  setPrendreRendezvousOpen,
  confirmUsersOffer,
  offer,
}: MyFunctionProps) {
  React.useEffect(() => {
    setOpen(PrendreRendezvousOpen);
  }, [PrendreRendezvousOpen]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPrendreRendezvousOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        sx={{
          borderRadius: "24px",
          mx: "auto",
          "& .MuiDialog-paper": {
            borderRadius: "24px",
            width: "1100px",
            maxWidth: "1100px",
            py: "0px",
            px: "4px",
          },
        }}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "10px",
            right: "20px",
            cursor: "pointer",
          }}
        >
          <TimesSvg />
        </Box>

        <DialogContent sx={{ py: 0 }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <ConsultationBlock provider={offer.provider} />
              </Grid>
              <Grid item xs={12} md={6}>
                <CalendarBlock
                  confirmUsersOffer={(data: any) => {
                    confirmUsersOffer(data);
                    handleClose();
                  }}
                />
                {/* <IndiquezVosInfo /> */}
              </Grid>
            </Grid>
          </DialogContentText>

          {/* <Button variant="contained" color='primary' sx={{
                            mt: 4,
                            borderRadius: '26.5px', width: '100%', padding: '12px 30px',
                            '&:hover': {
                                color: '#ffffff',
                            },
                        }}>
                            Super, merci !
                        </Button> */}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
