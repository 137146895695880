import axios, { AxiosError } from "axios";
import { useState, useEffect } from "react";

import { navigationActions } from "../../../store/appStateSlices/navigationSlice";
import { useAppDispatch, useAppSelector } from "../../../store/stateHooks";
import getUsername from "../../helpers/getUsername";
import { Error, Offer } from "../../hooks/useAPI";
import Button from "../../UI/Button";
import Icon from "../../UI/Icon";
import LoadingSpinner from "../../UI/LoadingSpinner";

import { ReactComponent as EmailSvg } from "../../icons/Email.svg";
import { ReactComponent as LocationSvg } from "../../icons/Location.svg";

import classes from "./index.module.css";
import { useNavigate } from "react-router-dom";

enum WarrantyLevels {
  Minimum = 1,
  Moyen,
  Fort,
  Maximum,
}

interface Props {
  onLoad: (offers: Offer[]) => void;
}

function LoadOffers(props: Props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<Offer[] | null>(null);

  const subscriberData = useAppSelector((state) => state.userData);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const requestBody = {
      ...subscriberData.members,
      ...subscriberData.credentials,
      warranties: subscriberData.warranties,
    };

    const statusState = requestBody.status;

    if (
      statusState === "Un adulte" ||
      statusState === "Un adulte + enfant(s)"
    ) {
      delete requestBody.partnerGender;
      delete requestBody.partnerBirthDate;
    }

    if (statusState === "Un adulte" || statusState === "Un couple") {
      delete requestBody.children;
    }

    async function fetchData() {
      const API_URL = process.env.REACT_APP_API_SERVER;
      try {
        setIsLoading(true);

        const response = await axios({
          url: `${API_URL}/api/v1/health`,
          method: "POST",
          data: requestBody,
        });

        setData(response.data.data.offers.offers as Offer[]);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const error = err as AxiosError;
          const errorResponse = error.response?.data as Error;

          if (errorResponse && errorResponse.status) {
            setError(errorResponse.message);
          }
        } else {
          setError(
            "Quelque chose s'est mal passé! Veuillez vérifier votre connexion ou réessayer plus tard."
          );
        }
      }

      setIsLoading(false);
    }

    fetchData();
    document.documentElement.style.setProperty("--white", "#d9f6f8");
  }, [subscriberData]);

  function loadHandler() {
    const offers = data as Offer[];

    const controlledOffers = offers!.map((offer) => {
      const controlledOffer = { ...offer };

      if (!offer.warranties || !offer.warranties.medicalCare) {
        controlledOffer.warranties = {
          medicalCare: {
            level: WarrantyLevels[subscriberData.warranties.medicalCare.level],
          },
          dental: {
            level: WarrantyLevels[subscriberData.warranties.dental.level],
          },
          visual: {
            level: WarrantyLevels[subscriberData.warranties.visual.level],
          },
          hearing: {
            level: WarrantyLevels[subscriberData.warranties.hearing.level],
          },
          hospitalization: {
            level:
              WarrantyLevels[subscriberData.warranties.hospitalization.level],
          },
        };
      }

      return controlledOffer;
    });
    props.onLoad(controlledOffers!);
  }

  function getStatus() {
    let status = "";
    if (isLoading) {
      status = "Récupération des offres...";
    } else if (!isLoading && error) {
      status = "Récupération des offres échoué ☹";
    } else if (!isLoading && !error && data) {
      status = "Récupération des offres réussie 😀";
    } else {
      status = "Aucune offre trouvée. Veuillez modifier vos critères. 🔄";
    }

    return status;
  }

  function retryHandler() {
    dispatch(navigationActions.setActiveAppPage(1));
  }

  function highlightStatus() {
    let elementToRender;
    if (isLoading) {
      elementToRender = (
        <>
          <p>
            Nous recherchons les meilleurs tarifs parmi 300 contrats référents
            sur le marché
          </p>
          <LoadingSpinner />
        </>
      );
    } else if (!isLoading && error) {
      elementToRender = (
        <>
          <p className={classes.error}>
            Vérifiez vos informations soumises et votre connexion Internet, puis
            réessayez.
          </p>
          <Button onClick={retryHandler}>Réessayer</Button>
        </>
      );
    } else if (!error && data && data.length > 0) {
      elementToRender = (
        <>
          <p>{`${data.length} offres trouvées pour vous! Juste un clic pour être assuré`}</p>
          <Button onClick={loadHandler}>Parcourir</Button>
        </>
      );
    } else {
      elementToRender = (
        <>
          <p>{`Aucune offre trouvée. Veuillez modifier vos critères ou cliquer sur le bouton ci-dessous pour retourner à la page d'accueil.`}</p>
          <Button
            onClick={() => {
              dispatch(navigationActions.setActiveAppPage(1));
              navigate("/");
            }}
          >
            Rentrer à la maison
          </Button>
        </>
      );
    }

    return elementToRender;
  }

  return (
    <div className="container">
      <div className={classes.container}>
        <div className={classes.cards}>
          <div className={classes["user-card"]}>
            <Icon name="personCircleOutline" className={classes.icon} />
            <div className={classes.infos}>
              <p className={classes.username}>
                {getUsername(
                  subscriberData.members.gender,
                  subscriberData.credentials.firstName,
                  subscriberData.credentials.lastName
                )}
              </p>
              <p>
                <EmailSvg /> {subscriberData.credentials.email}
              </p>
              <p>
                <LocationSvg /> {subscriberData.credentials.postalCode},{" "}
                {subscriberData.credentials.city}, FRANCE
              </p>
            </div>
          </div>
          <div className={classes["loading-card"]}>
            <img
              className={classes.image}
              src={`${process.env.PUBLIC_URL}/assets/img/loadOffers.png`}
              alt="Loading offers..."
            />
            <div className={classes.loading}>
              <h2 style={{ fontWeight: "700" }}>{getStatus()}</h2>
              {highlightStatus()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadOffers;
