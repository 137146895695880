import { useState } from "react";

import { membersActions } from "../../../../store/userDataSlices/membersSlice";
import { useAppDispatch } from "../../../../store/stateHooks";
import DateField from "../../../UI/DateField";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import getYearsDifference from "../../../helpers/getYearsDifference";
import QuestionError from "../../../UI/QuestionError";

interface Props {
  value?: string;
}

function PartnerbirthDate(props: Props) {
  const [error, setError] = useState<string>();
  const dispatch = useAppDispatch();

  function registerAnswerHandler(value: string) {
    dispatch(membersActions.setPartnerBirthDate(value));
  }

  function checkAnswerHandler(value: string) {
    const yearsDifference = getYearsDifference(new Date(value));
    if (yearsDifference === -1) {
      setError("Veuillez fournir une date de naissance valide.");
      return;
    }

    if (yearsDifference < 18) {
      setError(
        "Désolé, votre conjoint doit être majeur (18 ans ou plus) pour compléter."
      );
      return;
    }

    setError(undefined);
    registerAnswerHandler(value);
  }

  return (
    <QuestionBox
      targetState="members"
      action="setPartnerBirthDate"
      scrollToIt={true}
    >
      <FieldLabel>Date de naissance de votre conjoint(e)</FieldLabel>
      <DateField
        getState={checkAnswerHandler}
        state={props.value ? new Date(props.value) : undefined}
      >
        {error && <QuestionError error={error} />}
      </DateField>
    </QuestionBox>
  );
}

export default PartnerbirthDate;
