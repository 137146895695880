import { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../components/inputField";
import { useAppSelector } from "../../../store/stateHooks";
import lang from "../../../utils/language/french";
import userLogo from "../../Admin/assets/img/user.png";
import { putAPI } from "../../../apis/Services";
import { ReactComponent as CamSvg } from "../icons/Cam.svg";
 
const Profile = () => {
  const { user } = useAppSelector((state) => state.user);
  const [profilePic, setProfilePic] = useState(user.image || userLogo);
  const [selectedFile, setSelectedFile] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setError,
  } = useForm({
    defaultValues: {
      fullName: user.fullName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      image: user.image || "",
    },
  });
  const onSubmit = async (data) => {
    const formData = new FormData();

    // Append form fields to formData
    formData.append("fullName", data.fullName);
    formData.append("email", data.email);
    formData.append("phoneNumber", data.phoneNumber);

    // Append the profile picture if available
    if (selectedFile) {
      formData.append("profilePic", selectedFile);
    }

    try {
      const response = await putAPI("/auth/v1/user/profile", formData);
      // Handle success, maybe update state or show a message
    } catch (error) {
      setError("apiError", {
        type: "manual",
        message: error.message,
      });
    }
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePic(URL.createObjectURL(file)); // Display the selected image
      setSelectedFile(file);
    }
  };

  return (
    <main id="main" className="main">
      <div className="d-flex justify-content-start">
        <div className="col-12 col-md-8 col-lg-6">
          <div className="card shadow-sm p-4 ">
            <div className="container text-center mb-4">
              <div className="row justify-content-center">
                <div className="col-auto position-relative">
                  <div
                    className="d-flex justify-content-center align-items-center rounded-circle overflow-hidden"
                    style={{
                      height: "100px",
                      width: "100px",
                      backgroundColor: "#f0f0f0",
                    }}
                  >
                    <img
                      src={profilePic}
                      alt="Profile"
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                  <label
                    htmlFor="profilePic"
                    className="position-absolute"
                    style={{
                      bottom: "10px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <input
                      type="file"
                      id="profilePic"
                      accept="image/*"
                      className="d-none"
                      {...register("profilePic")}
                      onChange={handleProfilePicChange}
                    />
                    <CamSvg />
                     
                  </label>
                </div>
              </div>
            </div>

            {errors.apiError && (
              <div className="alert alert-danger" role="alert">
                {errors.apiError.message}
              </div>
            )}
            {isSubmitSuccessful && (
              <div className="alert alert-success" role="alert">
                Profile updated successfully!
              </div>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputField
                label={lang.common.fullName}
                name="fullName"
                type="text"
                placeholder={lang.common.fullName}
                register={register}
                errors={errors}
                validation={{
                  required: "Full name is required",
                }}
              />

              <InputField
                label={lang.common.email}
                name="email"
                type="email"
                placeholder={lang.common.email}
                register={register}
                errors={errors}
                validation={{
                  required: "L'e-mail est requis",
                  pattern: {
                    value: /^\S+@\S+\.\S+$/,
                    message: "Format d'e-mail invalide",
                  },
                }}
              />

              <InputField
                label={lang.admin.offerHeader.phoneNumber}
                name="phoneNumber"
                type="text"
                placeholder={lang.admin.offerHeader.phoneNumber}
                register={register}
                errors={errors}
                validation={{
                  required: "Le numéro de téléphone est requis",
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Numéro de téléphone invalide",
                  },
                }}
              />

              <div className="text-center mt-4">
                <button
                style={{backgroundColor:'#006CB7', borderRadius: '99px', fontWeight: '600'}}
                  type="submit"
                  className="btn btn-primary btn-block w-100 py-2"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Mise à jour..." : "Mettre à jour le profil"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Profile;
