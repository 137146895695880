import classes from "./QuestionError.module.css";

interface Props {
  error: string;
}

function QuestionError(props: Props) {
  return (
    <div className={classes.container}>
      <p className={classes.text}>{props.error}</p>
    </div>
  );
}

export default QuestionError;
