import { useState } from "react";

import { credentialsActions } from "../../../../store/userDataSlices/credentialsSlice";
import { useAppDispatch } from "../../../../store/stateHooks";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import TextField from "../../../UI/TextField";
import AddressMenu from "../../../UI/AddressMenu";

interface Props {
  value?: string;
}

function Address(props: Props) {
  const [userText, setUserText] = useState<string>();
  const [correctAddress, setCorrectAddress] = useState<string | undefined>(
    props.value
  );

  const dispatch = useAppDispatch();

  function userTypingHandler(value?: string) {
    setCorrectAddress(undefined);
    setUserText(value);
  }

  function answerHandler(value: string) {
    if (!value || !value.includes(" - ")) {
      return;
    }

    const zipCodeAndCity = value.split(" - ");

    dispatch(credentialsActions.setAddress(zipCodeAndCity));
    setCorrectAddress(value);
  }

  return (
    <QuestionBox
      targetState="credentials"
      action="setAddress"
      scrollToIt={true}
    >
      <FieldLabel>Votre ville ou code postal</FieldLabel>
      <TextField
        placeHolder="Ex: 75001 - PARIS"
        icon={correctAddress ? "checkmarkCircleOutline" : "alertCircleOutline"}
        spellCheck={false}
        value={correctAddress}
        getState={userTypingHandler}
      >
        {!correctAddress &&
          typeof userText === "string" &&
          userText.length > 2 && (
            <AddressMenu query={userText} onSelect={answerHandler} />
          )}
      </TextField>
    </QuestionBox>
  );
}

export default Address;
