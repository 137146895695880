import { PropsWithChildren } from "react";

import classes from "./SectionHeading.module.css";

function SectionHeading(props: PropsWithChildren) {
  return (
    <div className={classes.container}>
      <div className={classes.vl}></div>
      <h2
        style={{ fontSize: "1.5em", fontWeight: "700", marginBottom: "0px" }}
        className={classes.heading}
      >
        {props.children}
      </h2>
    </div>
  );
}

export default SectionHeading;
