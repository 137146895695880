
import * as React from 'react';
import Box from '@mui/material/Box';
import { ReactComponent as SonisSvg } from '../icons/Sonis.svg';
import { ReactComponent as DentainSvg } from '../icons/Dentain.svg';
import { ReactComponent as OptiqueSvg } from '../icons/Optique.svg';
import { ReactComponent as AidesSvg } from '../icons/Aides.svg';
import { ReactComponent as HospitalisationSvg } from '../icons/Hospitalisation.svg';
import { Stack, Typography } from '@mui/material';



export default function OfferCategoryStack() {
    const oferCatData = [
        {
            name: 'Soins courants',
            icon: <SonisSvg />,
            array: 3

        },
        {
            name: 'Dentaire',
            icon: <DentainSvg />,
            array: 2,
        },
        {
            name: 'Optique',
            icon: <OptiqueSvg />,
            array: 3
        },
        {
            name: 'Aides auditives',
            icon: <AidesSvg />,
            array: 2
        },
        {
            name: 'Hospitalisation',
            icon: <HospitalisationSvg />,
            array: 4
        }
    ]
    return (
        <Stack
            direction="row"
            spacing={4}
            my={5}
            sx={{
                justifyContent: "space-between",
                alignItems: "center",
                bgcolor: '#EDF4FA',
                borderRadius: '16px',
                padding: '27px 71px',
            }}
        >
            {
                oferCatData.map((item, index) => {
                    return (
                        <Box key={index} sx={{
                            display: 'flex', alignItems: 'start', justifyContent: "space-between",
                            flexDirection: 'column'
                        }}>
                            <Box   sx={{
                                display: 'flex', alignItems: 'center', justifyContent: "space-between",
                                gap: '5px'
                            }}>
                                {item.icon}
                                <Typography sx={{ color: '#37474F', fontWeight: 600, fontSize: '16px' }}>{item.name}</Typography>
                            </Box>

                            <Box key={index} sx={{
                                display: 'flex', alignItems: 'center', justifyContent: "space-between",
                                gap: '5px',
                                mt: 1.5
                            }}>
                                {
                                    [1, 2, 3, 4].map((e, r) => {
                                        return (
                                            <Box
                                            key={r}
                                            sx={{ 
                                                bgcolor: '#006CB7',
                                                opacity: item?.array > r ? "100%" : '25%' ,
                                                width: '22px',
                                                height: '8px',
                                                borderRadius: '4px'
                                            }} />
                                        )
                                    })
                                }
                            </Box>


                        </Box>
                    )
                })
            }

        </Stack>
    );
}
