import { PropsWithChildren, useRef, useEffect } from "react";
import classes from "./Button.module.css";

interface Props extends PropsWithChildren {
  align?: "left" | "center" | "right";
  onClick?: () => void;
  scrollToIt?: boolean;
}

function Button(props: Props) {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const containerCSSClass = `${classes.container} ${
    classes[`items-${props.align || "center"}`]
  }`;

  const { scrollToIt } = props;

  useEffect(() => {
    if (!buttonRef || !scrollToIt) {
      return;
    }
  }, [scrollToIt]);

  function clickHandler() {
    if (props.onClick) {
      props.onClick();
    }
  }

  return (
    <div className={containerCSSClass}>
      <button className={classes.button} ref={buttonRef} onClick={clickHandler}>
        {props.children}
      </button>
    </div>
  );
}

export default Button;
