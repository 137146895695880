import { PropsWithChildren } from "react";

import Logo from "./Logo";

import classes from "./NavbarBox.module.css";
import { Link } from "react-router-dom";
 

function NavbarBox(props: PropsWithChildren) {
  return (
    <nav className={classes.container}>
      <div className={classes.brand}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      {props.children}
    </nav>
  );
}

export default NavbarBox;
