import { useForm } from "react-hook-form";
import InputField from "../../../../users/components/inputField";
import CustomAsyncSelect from "../../../../users/components/CustomAsyncSelect";
import lang from "../../../../../utils/language/french";
import { postAPI, putAPI } from "../../../../../apis/Services";
import { api } from "../../../../../utils/storage";
import { useAppSelector } from "../../../../../store/stateHooks";
import showToast from "../../../../../utils/toast";
import { useState } from "react";
const StepOne = ({ formState, handleNextStep, handleCancel }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: formState,
  });

  const onSubmit = (data) => {
    handleNextStep(data);
    console.log(data);
  };
  const {
    contratType = "",
    produit = "",
    fournisseur = "",
    status = "",
    entreprise = "",
    email = "",
    civility = "M",
    type = "",
  } = formState || {};
  const [product, setProduct] = useState(false);
  const { auth } = useAppSelector((state) => state.authCredential);

  const loadEntrepriseOptions = (inputValue, callback) => {
    // Simulate async call with a setTimeout or replace with an API call
    setTimeout(() => {
      const options = [
        { value: "1", label: "Health sante" },
      ].filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(options);
    }, 1000);
  };

 const loadFournisseurOptions = (inputValue, callback) => {
    // Simulate async call with a setTimeout or replace with an API call
    setTimeout(() => {
      const options = [
        { value: "1", label: "NeoLiane" },
        { value: "2", label: "FMA" },
        { value: "3", label: "April" },
        { value: "4", label: "Alptis" },
        { value: "5", label: "praeconis" },
      ].filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(options);
    }, 1000);
  };
  //   // Handle changes in the select input
  const handleSelectChange = (selected, name) => {
    setValue(name, selected ? selected.label : ""); // Set the selected value
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="m-4">
      <p>
        {formState
          ? "Modifier les informations du contact."
          : "Renseignez les champs obligatoires concernants les informations du contrat"}
      </p>
      <strong>{`Le produit (1/3)`}</strong>
      <div className="justify-content-between align-items-center">
        {product ? (
          <>
            <CustomAsyncSelect
              label="* Type de contrat"
              name="contratType"
              //   placeholder="Sélectionnez une entreprise"
              loadOptions={loadEntrepriseOptions}
              onChange={handleSelectChange}
              defaultValue={
                entreprise && { label: entreprise.name, value: entreprise.id }
              }
              register={register}
              validation={{ required: "Entreprise est obligatoire" }}
              errors={errors}
            />
            <InputField
              key={"produit"}
              label={"* Produit"}
              name={"produit"}
              type={"string"}
              //   placeholder={field.placeholder}
              register={register}
              errors={errors}
              //   validation={field.validation}
            />
          </>
        ) : (
          <>
            <CustomAsyncSelect
              label="* Produit"
              name="produit"
              placeholder="Sélectionnez une produit"
              loadOptions={loadEntrepriseOptions}
              onChange={handleSelectChange}
              defaultValue={
                entreprise && { label: entreprise.name, value: entreprise.id }
              }
              register={register}
              validation={{ required: "Produit est obligatoire" }}
              errors={errors}
            />
          </>
        )}

        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-link fw-semibold"
            onClick={() => setProduct(!product)}
          >
            {product
              ? "Vous voulez chercher votre produit"
              : "Vous ne trouvez pas le produit ?"}
          </button>
        </div>
        <CustomAsyncSelect
          label="* Fournisseur"
          name="fournisseur"
          //   placeholder="Sélectionnez une entreprise"
          loadOptions={loadFournisseurOptions}
          onChange={handleSelectChange}
          defaultValue={
            entreprise && { label: entreprise.name, value: entreprise.id }
          }
          register={register}
          validation={{ required: "Fournisseur est obligatoire" }}
          errors={errors}
        />
      </div>
      <div className="d-flex justify-content-between mt-4 gap-3 ">
        <button
          type="button"
          className="btn btn-outline-primary w-50"
          onClick={() => handleCancel()}
        >
          Annuler
        </button>
        <button type="submit" className="btn btn-primary w-50">
          {"Suivant"}
        </button>
      </div>
    </form>
  );
};

export default StepOne;
