import React, { useEffect, useState } from "react";
import { api } from "../../../utils/storage";
import { getAPI, postAPI } from "../../../apis/Services";
import showToast from "../../../utils/toast";
// import QRCode from 'qrcode.react';

const TwoFactorAuth = ({ adminId }) => {
  const [code, setCode] = useState("");
  const [data, setData] = useState();
  const [twoFactorEnabled, setTwoFactorEnabled] =useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const res = await getAPI(`${api.twoFA}/${adminId}`);
      if (res.status === 200) {
        console.log(res);
        setData(res.data);
      } else {
        // showToast(res.message, "error");
        console.log(res);
      }
    };

    fetchData();
  }, [twoFactorEnabled]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle the form submission for activating 2FA
    console.log("2FA Code Submitted:", code);
if(code !== ""){
    try {
      const res = await postAPI(`${api.verifyTowFA}/${adminId}`, {code});
      console.log("res>>>>>>>>", res)
      setTwoFactorEnabled(res.twoFactorEnabled);
      showToast(res.message, 'success');
    } catch (e) {
      console.log("Error:", e);

      showToast(e.message, 'error');
    }
    } else{
        showToast("Veuillez entrer un code valide", "info");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      <h3>Scannez ce code QR avec votre application Google Authenticator :</h3>
      {/* <QRCode value="I5ACN4L2ML3A53TBP" size={200} /> */}
      <img src={data?.qrCode} />
      <p>
        Ou entrez ce secret manuellement : <strong>{data?.secret}</strong>
      </p>

      <form
        onSubmit={handleSubmit}
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <label htmlFor="code" style={{ marginBottom: "10px" }}>
          Entrer le code
        </label>
        <input
          type="text"
          id="code"
          placeholder="Entrer le code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          style={{ padding: "10px", width: "250px", marginBottom: "20px" }}
        />
        <button
          type="submit"
          style={{
            padding: "10px 20px",
            backgroundColor: data?.twoFactorEnabled ? "red" : "#0e6efd",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
          }}
        >
          {data?.twoFactorEnabled ? "Désactiver 2FA" : "Activer 2FA"}
        </button>
      </form>
    </div>
  );
};

export default TwoFactorAuth;
