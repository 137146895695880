import * as React from 'react';

import { ReactComponent as CheckSvg } from '../../../icons/check.svg';
import { ReactComponent as SonisSvg } from '../../../icons/Sonis.svg';
import { ReactComponent as DentainSvg } from '../../../icons/Dentain.svg';
import { ReactComponent as OptiqueSvg } from '../../../icons/Optique.svg';
import { ReactComponent as AidesSvg } from '../../../icons/Aides.svg';
import { ReactComponent as HospitalisationSvg } from '../../../icons/Hospitalisation.svg';

import { Box, Grid, Stack, Typography, } from '@mui/material';
import PhoneModel from '../../../UI/PhoneModel';

export default function OfferDetailsDeLaGarantie() {
    const garantieData = [
        {
            name: 'Soins courants',
            icon: <SonisSvg />,
            Tabletext: [
                {
                    LeftText: 'Consultations chez généralistes',
                    RightText: '100 % (Optam / Non-Optam)',
                },
                {
                    LeftText: 'Consultations chez spécialistes',
                    RightText: '100 % (Optam / Non-Optam)',

                },
                {
                    LeftText: 'Pharmacie',
                    RightText: '100 % sur médicaments remboursés',
                },
                {
                    LeftText: 'Laboratoires (analyses médicales)',
                    RightText: '100%',
                },
            ]
        },
        {
            name: 'Dentaire',
            icon: <DentainSvg />,
            Tabletext: [
                {
                    LeftText: 'Soins dentaires courants',
                    RightText: `Jusqu'à 100 %`,
                },
                {
                    LeftText: 'Prothèses dentaires',
                    RightText: `70 % à 100 % selon contrat`,
                },
                {
                    LeftText: 'Orthodontie',
                    RightText: `Plafond annuel appliqué`,
                },
                {
                    LeftText: 'Implant dentaire',
                    RightText: `Partiellement pris en charge`,
                },


            ]

        },
        {
            name: 'Optique',
            icon: <OptiqueSvg />,
            Tabletext: [
                {
                    LeftText: 'Lunettes de vue (verres et montures)',
                    RightText: `Forfait annuel : 150 €`,
                },
                {
                    LeftText: 'Lentilles sur ordonnance',
                    RightText: `Selon justification médicale`,
                },
                {
                    LeftText: 'Chirurgie réfractive (myopie, astigmatisme)',
                    RightText: `Partielle selon contrat`,
                },
                {
                    LeftText: 'Réparation ou remplacement de lunettes',
                    RightText: `Inclus selon conditions`,
                },
            ]
        },
        {
            name: 'Aides auditives',
            icon: <AidesSvg />,
            Tabletext: [{
                LeftText: 'Appareils auditifs (1 par oreille)',
                RightText: `300 € par appareil tous les 4 ans`,
            },
            {
                LeftText: 'Réparations et accessoires',
                RightText: `Selon plafond annuel`,
            },
            {
                LeftText: 'Consultation ORL pour évaluation',
                RightText: `100 %`,
            },
            {
                LeftText: 'Entretien annuel des appareils',
                RightText: `Partiellement pris en charge`,
            },

            ]


        },
        {
            name: 'Hospitalisation',
            icon: <HospitalisationSvg />,
            Tabletext: [{
                LeftText: 'Honoraires Optam / N.Optam',
                RightText: `100% ** / 100% **`,
            },
            {
                LeftText: 'Chambre particulière',
                RightText: `-`,
            },
            {
                LeftText: 'Frais de séjour',
                RightText: `100%`,
            },
            ]


        }
    ]

    return (
        <Box mt={8}>

            <Typography sx={{ fontSize: '20px', fontWeight: '700', color: '#37474F', pb: 0.9, textAlign: 'left', mb: 2, }}>Détails de la garantie</Typography>

            {
                garantieData.map((item, index) => {
                    return (
                        <Box key={index} mb={4}>
                            <Stack spacing={{ xs: 1, sm: 1 }}
                                mb={2}
                                sx={{
                                    alignItems: "center",
                                }}
                                direction="row">
                                {item.icon}
                                <Typography variant='body1' sx={{ fontWeight: '500' }} color={'#37474F'}>{item.name}</Typography>
                            </Stack>
                            {
                                item.Tabletext?.map((tableItem, tindex) => {
                                    return (
                                        <Box key={tindex} sx={[{ bgcolor: '#32deac1c', borderRadius: '8px', py: { xs: 2, sm: 1 }, px: 2 }, {
                                            '&:nth-of-type(odd)': {
                                                bgcolor: '#fff',
                                            }
                                        }
                                        ]}
                                        >
                                            <Grid container spacing={{ xs: 0, sm: 2 }}>
                                                <Grid item xs={12} md={7}>
                                                    <Stack spacing={{ xs: 1, sm: 1 }}
                                                        direction="row"
                                                        sx={{
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <CheckSvg />
                                                        <Typography variant='body1' sx={{ fontWeight: '400' }} color={'#37474F'}>{tableItem.LeftText}</Typography>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <Typography variant='body1' sx={{ fontWeight: '400', textAlign: 'left', ml: { xs: 2.5, sm: 0 } }} color={'#37474F'}>
                                                        {tableItem.RightText}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                        </Box >
                                    )
                                })
                            }

                        </Box>
                    )
                })
            }

            <Box
                mt={2.5}
                sx={{
                    display: "flex",
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' }, 
                    gap: "10px"
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontSize: "20px", fontWeight: "600", textAlign: "left" }}
                    color={"#37474F"}
                >
                    Appeler l'assureur
                </Typography>
                <PhoneModel color={"#006CB7"} />
            </Box>

        </Box>
    )
}
