import { useState, useRef, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "moment/locale/fr";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import lang from "../../../../utils/language/french";
import { ConfirmationModal } from "../../../modals";
import { useAppSelector } from "../../../../store/stateHooks";
import { useNavigate } from "react-router-dom";
import CheckStatus from "./components/statusOption";
const ContratTable = ({ tbody, handleDelete }) => {
  const gridRef = useRef();
  const navigate = useNavigate();
  const [paginationRow, setPaginationRow] = useState(10);
  const [status, setStatus] = useState();
  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };
  const { auth } = useAppSelector((state) => state.authCredential);
  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);

  const shortDateFormat = (params) => {
    const dateTimes = new Date(params?.value);

    // Correct format for the year (yyyy instead of YYYY)
    const formattedEndDateTime = format(dateTimes, "dd MMM yyyy", {
      locale: fr,
    });
    return formattedEndDateTime;
  };

  const checkGérer = (params) => {
    const navigateHendle = () =>
      navigate("/admin/portefeuille-contacts-details", {
        state: { data: params?.data },
      });
    return (
      <button
        className="badge rounded-pill bg-success"
        type="button"
        onClick={navigateHendle}
      >
        {"Gérer"}
      </button>
    );
  };

  const deleteContactRenderer = (params) => {
    return (
      <i
        className="bi bi-trash "
        style={{ cursor: "pointer" }}
        onClick={() => handleDelete(params.data._id)}
      ></i>
    );
  };

  const checkStatus = (params) => {
    return (
      <CheckStatus
        status={status ? status : params.data.statutContrat}
        onStatusChange={handleStatusChange}
      />
    );
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Référence",
      valueGetter: "data?.reference",
      // cellRenderer: shortDateFormat,
    },
    {
      headerName: "Client",
      maxWidth: 150,
      valueGetter: "data.fournisseur",
      // cellRenderer: (params) => params.value.phoneNumber,
    },
    {
      maxWidth: 150,
      headerName: "Email",
      valueGetter: "data.email",
    },
    {
      headerName: "Statut",
      // valueGetter: "data.statutContrat",
      // maxWidth: 150,
      cellRenderer: checkStatus,
    },
    {
      headerName: "Produit",
      valueGetter: "data.email",
    },
    {
      headerName: "Date d'effet",
      valueGetter: "data.dateEffet",
      cellRenderer: shortDateFormat,
    },
    {
      //   headerName: lang.query.Status,
      // field: "_id",
      //   valueGetter: "Gérer",
      cellRenderer: checkGérer,
    },
    {
      cellRenderer: deleteContactRenderer,
      maxWidth: 150,
      cellStyle: { textAlign: "center" },
    },
  ]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    filterParams: {
      debounceMs: 100,
    },
    floatingFilter: true,
    resizable: true,
  }));

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  return (
    <div>
      <ConfirmationModal />
      <div className="tableOptions">
        <select
          className="form-control table_selection_control"
          name="pagination"
          onChange={(event) => setPagination(event)}
        >
          {lang.paginationRow.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="ag-theme-alpine" style={{ width: "100%", height: 480 }}>
        <AgGridReact
          ref={gridRef}
          rowData={tbody}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          paginationPageSize={paginationRow}
          suppressExcelExport={true}
          sortingOrder={sortingOrder}
          pagination={true}
          animateRows={true}
        />
      </div>
    </div>
  );
};

export default ContratTable;
