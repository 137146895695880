// src/CheckStatus.js
import React from "react";

const CheckStatus = ({ status, onStatusChange }) => {
  const statuses = [
    { label: "Actif", value: "Actif", color: "#e3fcec", textColor: "#2c662d" },
    {
      label: "Annulé",
      value: "Annulé",
      color: "#fce3e6",
      textColor: "#b71c1c",
    },
    {
      label: "En attente de signature",
      value: "En attente de signature",
      color: "#e3e3fc",
      textColor: "#2c2ca1",
    },
    {
      label: "En attente de paiement",
      value: "En attente de paiement",
      color: "#e3e3fc",
      textColor: "#2c2ca1",
    },
    {
      label: "Résilié",
      value: "Résilié",
      color: "#fdf4e3",
      textColor: "#c49b3f",
    },
    { label: "Devis", value: "Devis", color: "#e3e3fc", textColor: "#2c2ca1" },
    {
      label: "Expiré",
      value: "Expiré",
      color: "#fce3e6",
      textColor: "#b71c1c",
    },
  ];

  return (
    <div className="dropdown" style={{ zIndex: 100 }}>
      <button
        className="btn btn-light dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{
          backgroundColor: statuses.find((s) => s.value === status).color,
          color: statuses.find((s) => s.value === status).textColor,
          border: "none",
          padding: "8px 12px",
          borderRadius: "8px",
        }}
      >
        {status}
      </button>
      <ul className="dropdown-menu" style={{ zIndex: 1007 }}>
        {statuses.map((s) => (
          <li key={s.value} className="p-2">
            <button
              className="dropdown-item"
              onClick={() => onStatusChange(s.value)}
              style={{
                backgroundColor: s.color,
                color: s.textColor,
                padding: "8px 12px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {s.value}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CheckStatus;
