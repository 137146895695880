import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { FormControl, Select, MenuItem, Box } from "@mui/material";
import { fr } from "date-fns/locale";
export default function StepOne({ handleInputChange }: any) {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  // const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [timeSlot, setTimeSlot] = useState<string[]>([]);
  const [age, setAge] = useState("");
  useEffect(() => {
    const slots = [
      "09h - 10h",
      "10h - 11h",
      "11h - 12h",
      "12h - 13h",
      "13h - 14h",
      "14h - 15h",
      "15h - 16h",
      "16h - 17h",
      "17h - 18h",
      "18h - 19h",
      "19h - 20h",
    ];
    setTimeSlot(slots);
  }, []);

  // Handle the change in date selection
  const handleDateFieldChange = (value: Date | null) => {
    const newDate = value ? new Date(value) : new Date();
    setSelectedDate(newDate);
    handleInputChange("date", newDate);
  };

  // Function to determine if the time slot should be disabled
  const isTimeSlotDisabled = (timeSlot: string): boolean => {
    const now = new Date();

    // If no date is selected, disable all time slots
    if (!selectedDate) {
      return true;
    }

    const selectedTime = new Date(selectedDate);
    if (selectedTime.toDateString() === now.toDateString()) {
      // If today is selected, disable past time slots
      const [startHour] = timeSlot.split("h");
      const slotStartTime = new Date(
        selectedDate.setHours(parseInt(startHour.trim()), 0, 0, 0)
      );
      return slotStartTime < now; // Disable if slot time is in the past
    }

    return false; // Enable all slots for future dates
  };

  // Handle time slot selection
  const handleChange = (event: any) => {
    const selectedTime = event.target.value as string;
    setAge(selectedTime);
    if (!isTimeSlotDisabled(selectedTime)) {
      // setSelectedItems([selectedTime]);
      handleInputChange("timeSlot", selectedTime);
    }
  };

  return (
    <div>
      {/* Date Picker Component */}
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
        <DatePicker
          sx={{ width: "100%" }}
          value={selectedDate}
          onChange={(newValue) => handleDateFieldChange(newValue)}
          shouldDisableDate={(date) => {
            const now = new Date();
            return (
              date.getTime() < new Date(now.setHours(0, 0, 0, 0)).getTime()
            ); // Disable past dates
          }}
        />
      </LocalizationProvider>
      {/* Time Slot Selection with Material-UI Select */}
      <Box my={3}>
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">Select Time Slot</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            onChange={handleChange}
            disabled={!selectedDate} // Disable Select if no date is selected
          >
            {timeSlot.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  value={item}
                  disabled={isTimeSlotDisabled(item)}
                >
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
