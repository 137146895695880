import { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import "../contactScreens/style.css";
import { CustomModal } from "../../../modals";
import { AddContratsForm } from "../../../UI/forms";
import lang from "../../../../utils/language/french";
// import { DevisContrats, Documents, CommentComponent } from "./components";
import { deleteAPI, getAPI } from "../../../../apis/Services";
import { api } from "../../../../utils/storage";
import { useAppDispatch, useAppSelector } from "../../../../store/stateHooks";
import showToast from "../../../../utils/toast";
import { AuthActions } from "../../../../store/appStateSlices/AuthCredentialsSlice";
import AddContratsMultiStepForm from "./forms/addContratsMultiStepForm";
import ContratTable from "./contratTable";
import { navigationActions } from "../../../../store/appStateSlices/navigationSlice";
const PortefeuilleContrats = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { auth } = useAppSelector((state) => state.authCredential);
  const [showModal, setShowModal] = useState(false);
  const [contrats, setContrats] = useState([]);
  const [statusFilter, setStatusFilter] = useState("Tous");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAPI(`${api.contrat}/${auth.data.admin_id}`);
        if (res.status === 200) {
          console.log(res.data);
          setContrats(res.data);
        } else {
          showToast(res.message, "error");
        }
      } catch (err) {
        showToast(
          "Une erreur s'est produite lors de la récupération des contrats",
          "error"
        );
      }
    };

    fetchData();
  }, [auth.data.admin_id]);

  const handleCancel = () => {
    setShowModal(false);
  };

  const filteredContacts = contrats?.filter((contact) => {
    if (statusFilter === "Tous") return true;
    return contact.statutContrat === statusFilter;
  });

  const handleDelete = async (contactId) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer ce contact?"
    );
    if (confirmed) {
      try {
        const res = await deleteAPI(`${api.contrat}/${auth.data.admin_id}`, {
          id: contactId,
        });
        setContrats((prevContacts) =>
          prevContacts.filter((contact) => contact._id !== contactId)
        );
        showToast(res.message, "success");
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleAddContact = (newContact) => {
    // setContrats((prevContacts) => [newContact, ...prevContacts]);
    setShowModal(false);
  };
  return (
    <>
      <main id="main" className="main">
        <CustomModal
          show={showModal}
          onCancel={handleCancel}
          title="Ajouter un contrat"
          modayType={"modal-lg"}
        >
          <AddContratsMultiStepForm
            handleCancel={handleCancel}
            handleAddContact={handleAddContact}
          />
        </CustomModal>
        <div className="pagetitle">
          <h1>{lang.offer.Offres}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">{lang.common.home}</Link>
              </li>
              <li className="breadcrumb-item active">{`Contrats`}</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="fw-bold">{`Contrats (${contrats.length})`}</h1>
              <div className="d-flex gap-2">
                <div className="d-flex">
                  <button
                    className={`btn btn-light me-2 ${
                      statusFilter === "Tous" ? "active" : ""
                    }`}
                    onClick={() => setStatusFilter("Tous")}
                  >
                    Tous
                  </button>
                  <button
                    className={`btn btn-light me-2 ${
                      statusFilter === "En attente" ? "active" : ""
                    }`}
                    onClick={() => setStatusFilter("En attente")}
                  >
                    En attente
                  </button>
                  <button
                    className={`btn btn-light ${
                      statusFilter === "Actif" ? "active" : ""
                    }`}
                    onClick={() => setStatusFilter("Actif")}
                  >
                    Actif
                  </button>
                  <button
                    className={`btn btn-light ${
                      statusFilter === "Annulé" ? "active" : ""
                    }`}
                    onClick={() => setStatusFilter("Annulé")}
                  >
                    Annulé
                  </button>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    dispatch(navigationActions.setActiveAppPage(1));
                    navigate("/");
                  }}
                >
                  <i className="bi bi-plus"></i> Nouveau devis
                </button>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setShowModal(true)}
                >
                  <i className="bi bi-plus"></i> Ajouter un contrat
                </button>
              </div>
            </div>
            <ContratTable
              tbody={filteredContacts}
              handleDelete={handleDelete}
            />
          </div>
        </section>
      </main>
    </>
  );
};

export default PortefeuilleContrats;
