import { useState, PropsWithChildren } from "react";

import DatePicker from "react-date-picker/dist/entry.nostyle";
import Icon from "./Icon";

import classes from "./DateField.module.css";
import "./DateField.css";

interface Props extends PropsWithChildren {
  getState?: (value: string) => void;
  state?: Date;
}

function DateField(props: Props) {
  const [value, setValue] = useState<Date | undefined>(props.state);

  function changeHandler(value?: Date) {
    if (!value) {
      return;
    }

    setValue(value);

    if (props.getState) {
      props.getState(value.toISOString());
    }
  }

  return (
    <div className={classes.container}>
      <DatePicker
        onChange={changeHandler}
        value={value}
        format="dd/MM/yyyy"
        dayPlaceholder="JJ"
        monthPlaceholder="MM"
        yearPlaceholder="AAAA"
        maxDate={new Date()}
        // maxDate={new Date(2998, 12, 31)}
        calendarIcon={<Icon name="calendarOutline" className="calendar-icon" />}
        clearIcon={null}
        showLeadingZeros={true}
        openCalendarOnFocus={false}
        locale="fr" 
      />
      {props.children}
    </div>
  );
}

export default DateField;

// import { useState, PropsWithChildren } from "react";
// import DatePicker from "react-date-picker/dist/entry.nostyle";
// import Icon from "./Icon";

// import classes from "./DateField.module.css";
// import "./DateField.css";

// interface Props extends PropsWithChildren {
//   getState?: (value: string) => void;
//   state?: Date;
// }

// function DateField(props: Props) {
//   const [value, setValue] = useState<Date | undefined>(props.state);

//   function changeHandler(value?: Date) {
//     if (!value) {
//       return;
//     }

//     setValue(value);

//     if (props.getState) {
//       props.getState(value.toISOString());
//     }
//   }

//   return (
//     <div className={classes.container}>
//       <DatePicker
//         onChange={changeHandler}
//         value={value}
//         format="dd/MM/y" // Adjust the format as needed
//         dayPlaceholder="JJ"
//         monthPlaceholder="MM"
//         yearPlaceholder="AAAA"
//         maxDate={new Date()}
//         calendarIcon={<Icon name="calendarOutline" className="calendar-icon" />}
//         clearIcon={null}
//         showLeadingZeros={true}
//         openCalendarOnFocus={false}
//         locale="fr" // Apply the French locale
//       />
//       {props.children}
//     </div>
//   );
// }

// export default DateField;
