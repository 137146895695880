import { useEffect, useState } from "react";
import "./styles.css";
import lang from "../../utils/language/french";
const CustomModal = ({ show, title, onCancel, children, width, modayType }) => {
  const [fadeType, setFadeType] = useState("");

  useEffect(() => {
    if (show) {
      setFadeType("in");
    } else {
      setFadeType("out");
    }
  }, [show]);

  const handleAnimationEnd = () => {
    if (fadeType === "out") {
      onCancel();
    }
  };

  return (
    <div
      className={`modal fade ${
        show ? "show d-block" : "d-none"
      } fade-${fadeType} ${modayType}`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      onAnimationEnd={handleAnimationEnd}
      aria-hidden="true"
    >
      <div className={`modal-dialog modal-dialog-centered ${width}`} role="document">
        <div className="modal-content" >
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => setFadeType("out")}
            ></button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
