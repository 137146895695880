import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthActions } from "../../../../../store/appStateSlices/AuthCredentialsSlice";
import { useAppSelector } from "../../../../../store/stateHooks";
import Offers from "../../Offers";
import { navigationActions } from "../../../../../store/appStateSlices/navigationSlice";

const DevisContrats = ({ id }) => {
  const navigator = useNavigate();
  const [statusFilter, setStatusFilter] = useState("devis");
  const dispatch = useDispatch();

  // Function to update created_for
  const handleUpdateCreatedFor = () => {
    dispatch(AuthActions.updateCreatedFor(id));
    dispatch(navigationActions.setActiveAppPage(1));
    navigator("/");
  };

  const [offers, setOffers] = useState([]);
  const SERVER_URL = process.env.REACT_APP_API_SERVER;
  const { auth } = useAppSelector((state) => state.authCredential);

  useEffect(() => {
    // Create the headers
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.data.token}`);

    // Construct the URL with query parameters
    const url = new URL(
      `${SERVER_URL}/api/v1/confirm-offer/get-contract-offers/${auth.data.admin_id}`
    );
    url.searchParams.append("created_for", id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    // Make the fetch call
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setOffers(result.data);
      })
      .catch((error) => console.log("error", error));
  }, [auth.data.token, id]);

  return (
    <div
      className="p-4 bg-light"
      style={{
        border: "1px solid black",
        borderRadius: "5px",
      }}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="fw-bold">Devis et contrats</h3>
        <div
          className="d-flex"
          style={{ backgroundColor: "#eaeaee", borderRadius: 10 }}
        >
          <div className="d-flex p-1 gap-1">
            <button
              className={`btn btn-light p-1 ${
                statusFilter === "devis" ? "active" : ""
              }`}
              onClick={() => setStatusFilter("devis")}
            >
              Devis
            </button>
            <button
              className={`btn btn-light p-1 ${
                statusFilter === "contrats" ? "active" : ""
              }`}
              onClick={() => setStatusFilter("contrats")}
            >
              Contrats
            </button>
          </div>
        </div>
      </div>
      {offers?.length > 0 ? (
        <section className="section dashboard">
          <Offers tbody={offers} />
        </section>
      ) : (
        <div
          className="text-center mt-3 py-3"
          style={{ backgroundColor: "#eaeaee", borderRadius: 10 }}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: "100%" }}
          >
            <div className="d-flex align-items-center">
              <i className="bi bi-info-circle fs-4"></i>
              <p className="ms-2 mb-0 fs-5">
                Vous n'avez aucun contrats enregistré
              </p>
            </div>

            <button
              className="btn btn-outline-primary mt-5"
              onClick={() => handleUpdateCreatedFor()}
            >
              <i className="bi bi-plus"></i> Nouveau devis
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DevisContrats;
