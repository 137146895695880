import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Offers from "./Offers";
import { useAppSelector } from "../../../store/stateHooks";
import lang from "../../../utils/language/french"
export default function Entreprises() {
  const { auth } = useAppSelector((state) => state.authCredential);


  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.offer.Offres}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">{lang.common.home}</Link>
              </li>
              <li className="breadcrumb-item active">{'Entreprises'}</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <h1>Work on progress....</h1>
        </section>
      </main>
    </>
  );
}
