import { useState } from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Link, useLocation } from "react-router-dom";
import lang from "../../../../utils/language/french";
import { Documents } from "../contactScreens/components";
import { CustomModal } from "../../../modals";
import UpdateContactForm from "./forms/updateForm";
import CheckStatus from "./components/statusOption";
import { getAPI, putAPI } from "../../../../apis/Services";
import showToast from "../../../../utils/toast";
import { api } from "../../../../utils/storage";
import { useAppSelector } from "../../../../store/stateHooks";

const ContactDetails = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("informations");
  const [showModal, setShowModal] = useState(false);
  const [contrat, setContrat] = useState(location.state.data);
  const {
    _id,
    produit,
    fournisseur,
    entreprise,
    souscripteur,
    reference,
    montantHT,
    montantTTC,
    fraisSouscription,
    fraisRecurrents,
    fractionnement,
    dateEffet,
    tauxCommission,
    commissionAnnuelle,
    commissionAnnuelleFrais,
    modePaiement,
    civility,
    statutContrat,
    commercialReprésentant,
    firstName,
    lastName,
    email,
    phone,
    businessDeveloper,
    createdAt,
  } = contrat;
  const shortDateFormat = (params) => {
    const dateTimes = new Date(params);

    // Correct format for the year (yyyy instead of YYYY)
    const formattedEndDateTime = format(dateTimes, "dd MMM yyyy", {
      locale: fr,
    });
    return formattedEndDateTime;
  };
  const { auth } = useAppSelector((state) => state.authCredential);
  const [status, setStatus] = useState();
  const handleStatusChange = async (newStatus) => {
    setStatus(newStatus);
    try {
      // Update contrat if formData exists
      const res = await putAPI(`${api.contrat}/${auth.data.admin_id}`, {
        statutContrat: newStatus,
        id: _id,
      });
      showToast("Contact mis à jour avec succès", "success");
      console.log(res);
    } catch (err) {
      console.log(err);
      showToast(lang.common.error, "error");
    }
  };
  const handleEditContact = async () => {
      const res = await getAPI(
        `${api.contratById}/${auth.data.admin_id}?contracId=${_id}`
      );
      if (res.status === 200) {
        setContrat(res.data);
      } else {
        showToast(res.message, "error");
      }
    setShowModal(false);
  };
  return (
    <main className="main my-5" id="main">
      <CustomModal
        show={showModal}
        onCancel={() => setShowModal(false)}
        title="Modifier le souscripteur"
      >
        <UpdateContactForm
          handleCancel={() => setShowModal(false)}
          handleAddContact={handleEditContact}
          formData={location?.state?.data}
        />
      </CustomModal>
      <div className="pagetitle">
        <h1>{lang.offer.Offres}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard">{lang.common.home}</Link>
            </li>
            <li className="breadcrumb-item active">{"Contact"}</li>
          </ol>
        </nav>
      </div>
      {/* Header Section */}
      {/* <button className="btn btn-link">&larr; Retour</button> */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        {/* Contract Information */}
        <div className="border rounded p-3 mb-4 d-flex justify-content-between">
          <div>
            <h5 className="mb-1">
              Contrat - <span className="fw-bold">iardgen2104138995</span>
            </h5>
            <p className="mb-0">
              Entreprise: <span className="fw-bold">{entreprise}</span>
            </p>
          </div>
          <div>
            <span className="badge bg-light text-success">
              {status ? status : statutContrat}
            </span>
            <p>{shortDateFormat(createdAt)}</p>
          </div>
        </div>
        <button className="btn btn-primary">Supprimer le contrat</button>
      </div>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <button
            className={`nav-link ${
              activeTab === "informations" ? "active" : ""
            }`}
            onClick={() => setActiveTab("informations")}
          >
            Informations
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "documents" ? "active" : ""}`}
            onClick={() => setActiveTab("documents")}
          >
            Documents
          </button>
        </li>
      </ul>

      {/* Main Details Section */}
      {activeTab === "informations" ? (
        <div className="row">
          {/* Subscriber Information */}
          <div className="col-md-6 mb-4">
            <div className="border rounded p-3 h-100">
              <div className="d-flex justify-content-between">
                <h6>Souscripteur</h6>
                <button
                  className="btn btn-link text-primary p-0"
                  onClick={() => setShowModal(true)}
                >
                  Modifier
                </button>
              </div>
              <p className="mb-1">
                <strong>Civilité :</strong> {souscripteur.civility}
              </p>
              <p className="mb-1">
                <strong>Nom :</strong> {souscripteur.firstName}
              </p>
              <p className="mb-1">
                <strong>Prénom :</strong> {souscripteur.lastName}
              </p>
              <p className="mb-1">
                <strong>Email :</strong> {souscripteur.email}
              </p>
              <p className="mb-0">
                <strong>Téléphone :</strong> {souscripteur?.phone}
              </p>
            </div>
          </div>

          {/* Contract Information */}
          <div className="col-md-6 mb-4">
            <div className="border rounded p-3 h-100">
              <div className="d-flex justify-content-between">
                <h6>Contrat</h6>
                <CheckStatus
                  status={status ? status : statutContrat}
                  onStatusChange={handleStatusChange}
                />
              </div>
              <p className="mb-1">
                <strong>Statut:</strong>{" "}
                <span className="badge bg-light text-success">
                  {status ? status : statutContrat}
                </span>
              </p>
              <p className="mb-1">
                <strong>Date d'émission :</strong> {shortDateFormat(createdAt)}
              </p>
              <p className="mb-1">
                <strong>Assureur :</strong> +Simple
              </p>
              <p className="mb-1">
                <strong>Produit :</strong> {produit}
              </p>
              <p className="mb-1">
                <strong>Date d'effet :</strong> {shortDateFormat(dateEffet)}
              </p>
              <p className="mb-1">
                <strong>Fractionnement :</strong> Mensuel
              </p>
              <p className="mb-0">
                <strong>Prime TTC :</strong> {`${montantTTC}€`}
              </p>
            </div>
          </div>

          {/* Commercial Management Information */}
          <div className="col-12">
            <div className="border rounded p-3">
              <div className="d-flex justify-content-between">
                <h6>Gestion commerciale</h6>
                <button className="btn btn-link text-primary p-0">
                  Modifier
                </button>
              </div>
              <p className="mb-1">
                <strong>Apporteur d'affaire :</strong> -
              </p>
              <p className="mb-1">
                <strong>Mail :</strong> -
              </p>
              <p className="mb-1">
                <strong>Tél :</strong> -
              </p>
              <p className="mb-0">
                <strong>Commercial en charge :</strong> -
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Documents id={_id} />
        </>
      )}
    </main>
  );
};

export default ContactDetails;
