import React from "react";
import OffersNavbar from "./OffersNavbar";
// import Sidebar from "./Sidebar";
import Offer from "./Offer";
import { nanoid } from "nanoid";
import { useAppSelector, useAppDispatch } from "../../store/stateHooks";
// import OffersHeading from "./OffersHeading";
import classes from "../../index.css";
import Button from "./Button";
import { navigationActions } from "../../store/appStateSlices/navigationSlice";
import Footer from "./Footer";

function ConfirmOffer() {
  const dispatch = useAppDispatch();
  const { offer } = useAppSelector((state) => state.confirmOffer.selectedOffer);

  function backToOffers() {
    dispatch(navigationActions.setActiveAppPage(3));
  }
  return (
    <>
     <div className='container'> 
      <OffersNavbar />
      {/* <Sidebar /> */}
      <div
        className={classes.container}
        style={{ marginTop: "60px", paddingBottom: "20px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <h2 className="mb-0" style={{ fontSize: "1.5em", fontWeight: "700" }}>
            {" "}
            Offre Sélectionnée
          </h2>
          <Button onClick={() => backToOffers()}>Retour</Button>
        </div>

        <div className={classes.container}>
          <div className={classes["offers-container"]}>
            <Offer
              key={nanoid(6)}
              provider={offer.provider}
              monthlyPrice={offer.monthlyPrice}
              levels={offer.levels}
              name={offer.name}
              label={offer.label}
              code={offer.code}
              description={offer.description}
              check={1}
            />
          </div>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
}

export default ConfirmOffer;
