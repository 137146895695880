import React, { useState } from 'react'
import OfferPageTop from '../components/OfferPage/OfferPageTop'
import OfferPropoAssu from '../components/OfferPage/OfferPropoAssu'
import OfferDetailsDeLaGarantie from '../components/OfferPage/OfferDetailsDeLaGarantie'
import { RiArrowLeftLine } from "react-icons/ri";
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

export default function OfferPage() {
   
    return (
        <main id="main" className="main">
            <div className="mb-3 mt-2">

                <div className="card px-sm-3 px-1">
                    <div className="card-body">
                       
                        <Link to={-1} style={{ color: '#000' }}>
                            <Stack direction={'row'} spacing={1} sx={{
                                justifyContent: 'start',
                                alignItems: 'center',
                                mb: 2
                            }}>
                                <RiArrowLeftLine style={{ fontWeight: '700', fontSize: '25px' }} />
                                <h6 className="card-title">Retour</h6>
                            </Stack>
                        </Link>
                        <OfferPageTop />
                        <OfferPropoAssu />
                        <OfferDetailsDeLaGarantie />
                    </div>
                </div>
            </div >
        </main >
    )
}
