import {
  IMembers,
  membersActions,
} from "../../../../store/userDataSlices/membersSlice";
import { useAppDispatch } from "../../../../store/stateHooks";
import getRadioFieldRender from "../../../helpers/getRadioFieldRender";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import RadioFieldGroup from "../../../UI/RadioFieldGroup";

interface Props {
  value?: string;
}

function SocialRegime(props: Props) {
  const dispatch = useAppDispatch();

  const socialRegimes = [
    "Général",
    "Travailleur Non Salarié",
    "Agricole",
    "Alsace-Moselle",
  ];

  function answerHandler(value: IMembers["socialRegime"]) {
    dispatch(membersActions.setSocialRegime(value));
  }

  return (
    <QuestionBox
      targetState="members"
      action="setSocialRegime"
      scrollToIt={true}
    >
      <FieldLabel>Votre régime Social</FieldLabel>
      <RadioFieldGroup
        type="horizontal"
        items={socialRegimes}
        renderItem={getRadioFieldRender("horizontal")}
        getSelectedValue={answerHandler}
        defaultSelected={
          props.value ? socialRegimes.indexOf(props.value) : undefined
        }
      ></RadioFieldGroup>
    </QuestionBox>
  );
}

export default SocialRegime;
