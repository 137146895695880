import { useRef, useEffect, PropsWithChildren, KeyboardEvent } from "react";

import classes from "./TextField.module.css";

interface Props extends PropsWithChildren {
  placeHolder: string;
  getState?: (value: string) => void;
  value?: string;
}

function PhoneField(props: Props) {
  const theInput = useRef<HTMLInputElement>(null);

  const { value } = props;

  useEffect(() => {
    if (!value || !theInput.current) {
      return;
    }

    theInput.current.value = value;
  }, [value]);

  function changeHandler() {
    if (!props.getState) {
      return;
    }

    let currentValue = theInput?.current?.value;
    if (!currentValue) {
      return;
    }

    theInput!.current!.value = currentValue.replaceAll(" ", "");
    currentValue = theInput!.current!.value;

    if (currentValue.length < 10) {
      return;
    }

    const phoneNumber: string[] = [];
    for (let i = 0; i < currentValue.length; i++) {
      if (i === 0 || i % 2 === 0) {
        phoneNumber[i] = currentValue[i];
        continue;
      }

      phoneNumber[i - 1] = `${phoneNumber[i - 1]}${currentValue[i]}`;
    }

    theInput!.current!.value = phoneNumber.join(" ");
    props.getState(currentValue);
  }

  function keyDownHandler(e: KeyboardEvent) {
    const pressedKey = e.key;

    if (isNaN(Number(pressedKey)) && e.key !== "Backspace") {
      e.preventDefault();
      return false;
    }
  }

  return (
    <div className={classes.container}>
      <input
        ref={theInput}
        type="tel"
        maxLength={10}
        placeholder={props.placeHolder}
        className={classes.input}
        onChange={changeHandler}
        onKeyDown={keyDownHandler}
      />
      {props.children}
    </div>
  );
}

export default PhoneField;
