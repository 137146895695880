import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import "./styles.css";
import lang from "../../utils/language/french";
import Logo from "../UI/Logo";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/stateHooks";
import Icon from "../UI/Icon";
import { postAPI, putAPI } from "../../apis/Services";
import showToast from "../../utils/toast";

const ActivityModal = ({ show, onCancel, setShowAuthModal }) => {
  const subscriberData = useAppSelector((state) => state.userData.credentials);
  const { auth } = useAppSelector((state) => state.authCredential.auth);
  const [fadeType, setFadeType] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const SERVER_URL = process.env.REACT_APP_API_SERVER;
  const { register, handleSubmit, setValue } = useForm();
  useEffect(() => {
    if (show) {
      setFadeType("in");
    } else {
      setFadeType("out");
    }
  }, [show]);

  useEffect(() => {
    // Set default values from show.activity if available
    if (show && show.activity) {
      setValue("title", show.activity.title || ""); // Set default value for title
      setValue("carriedOut", show.activity.carriedOut || false); // Set default value for carriedOut
      setValue("allDay", show.activity.allDay || false); // Set default value for allDay
      setStartDate(new Date(show?.activity?.startDate) || new Date()); // Set default value for startDate
      setEndDate(new Date(show?.activity?.endDate) || new Date()); // Set default value for endDate
      setValue("startTime", show.activity.startTime || ""); // Set default value for startTime
      setValue("endTime", show.activity.endTime || ""); // Set default value for endTime
      setValue("activity_comment", show.activity.activity_comment || ""); // Set default value for activity_comment
    }
  }, [show, setValue]);

  const handleAnimationEnd = () => {
    if (fadeType === "out") {
      onCancel();
    }
  };

  const onSubmit = async (data) => {
    onCancel();

    // Prepare headers with authorization
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.data.token}`);
    myHeaders.append("Content-Type", "application/json");

    // Prepare the request options with the body
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        ...data,
        id: show?.activity?._id || null,
        offerId: show._id,
      }),
      redirect: "follow",
    };

    fetch(
      `${SERVER_URL}/api/v1/confirm-offer/activities/${auth.data.admin_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        showToast(result.message, "success");
      })
      .catch((error) => console.log("error", error));
  };

  // Update DatePicker fields in the form
  useEffect(() => {
    setValue("startDate", startDate);
    setValue("endDate", endDate);
  }, [startDate, endDate, setValue]);
  return (
    <div
      className={`modal fade ${
        show ? "show d-block" : "d-none"
      } fade-${fadeType}`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-6 text-center mb-3 mb-md-0">
                <Logo />
              </div>
            </div>
            <button
              type="button"
              className="btn-close"
              aria-label="Fermer"
              onClick={() => setFadeType("out")}
            ></button>
          </div>
          <div className="modal-body">
            <div className="" style={{ borderRadius: "4px" }}>
              <div className="card-header px-2 box_heading text-center">
                {show?.subscriber
                  ? `Activité de ${show.subscriber.firstName} ${show.subscriber.lastName}`
                  : "Activité"}
              </div>
              {show?.subscriber && (
                <div className="card border-light flex-shrink-0">
                  <div className="card-body d-flex justify-content-center row">
                    <div>
                      <p>
                        <strong>E-mail:</strong> {show.subscriber.email}
                      </p>
                      <p>
                        <strong>Numéro de téléphone:</strong>{" "}
                        {show.subscriber.phoneNumber}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <form className="cart-body" onSubmit={handleSubmit(onSubmit)}>
                <div className=" ">
                  <label className="form-label">Titre</label>
                  <select className="form-select" {...register("title")}>
                    <option>Choisir le titre</option>
                    {titles.map((title, i) => (
                      <option value={title} key={i}>
                        {title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="head_box d-flex justify-content-between align-items-center mt-2">
                  <div className="form-group mb-0 me-2 p-0">
                    <label className="form-label">Type d'activité</label>
                    <select className="form-select">
                      {options.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.title}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="d-flex gap-4 mt-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        {...register("carriedOut")}
                      />
                      <label className="form-check-label">Effectué</label>
                    </div>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        {...register("allDay")}
                      />
                      <label className="form-check-label">
                        Toute la journée
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Date de début</label>
                      <DatePicker
                        onChange={setStartDate}
                        value={startDate}
                        format="dd/MM/yyyy"
                        dayPlaceholder="JJ"
                        monthPlaceholder="MM"
                        yearPlaceholder="AAAA"
                        maxDate={new Date()}
                        calendarIcon={
                          <Icon
                            name="calendarOutline"
                            className="calendar-icon"
                          />
                        }
                        clearIcon={null}
                        showLeadingZeros={true}
                        openCalendarOnFocus={false}
                        locale="fr"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Heure de début</label>
                      <input
                        type="time"
                        className="form-control"
                        {...register("startTime")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Date de fin</label>
                      <DatePicker
                        onChange={setEndDate}
                        value={endDate}
                        format="dd/MM/yyyy"
                        dayPlaceholder="JJ"
                        monthPlaceholder="MM"
                        yearPlaceholder="AAAA"
                        maxDate={new Date()}
                        calendarIcon={
                          <Icon
                            name="calendarOutline"
                            className="calendar-icon"
                          />
                        }
                        clearIcon={null}
                        showLeadingZeros={true}
                        openCalendarOnFocus={false}
                        locale="fr"
                      />
                    </div>
                  </div>
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">Heure de fin</label>
                      <input
                        type="time"
                        className="form-control"
                        {...register("endTime")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mt-1">
                  <label htmlFor="comment">Note/Commentaire:</label>
                  <textarea
                    className="form-control"
                    placeholder="note/commentaire"
                    rows="3"
                    {...register("activity_comment")}
                  ></textarea>
                </div>

                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    className="btn btn-secondary me-2"
                    onClick={() => setFadeType("out")}
                  >
                    Annuler
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityModal;

const titles = [
  "À contacter",
  "Aucune réponse",
  "À recontacter",
  "Rendez-vous pris",
  "Loué",
  "Abandonner",
];

const options = [
  { value: "all", title: "Tous" },
  { value: "Reunion", title: "Réunion" },
  { value: "Tache", title: "Tâche" },
  { value: "Email", title: "Email" },
  { value: "Telephone", title: "Téléphone" },
  { value: "Telephone ping", title: "Téléphone Ping" },
  { value: "Note", title: "Note" },
];
