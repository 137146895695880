import { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import "./style.css";
import { CustomModal } from "../../../modals";
import { AddContactForm } from "../../../UI/forms";
import lang from "../../../../utils/language/french";
import { DevisContrats, Documents, CommentComponent } from "./components";
import { getAPI } from "../../../../apis/Services";
import { api } from "../../../../utils/storage";
import { useAppDispatch, useAppSelector } from "../../../../store/stateHooks";
import showToast from "../../../../utils/toast";
import { AuthActions } from "../../../../store/appStateSlices/AuthCredentialsSlice";
const ContactUpdate = () => {
  const location = useLocation();
  const { auth } = useAppSelector((state) => state.authCredential);
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const { data, id } = location?.state;
  const [contact, setContact] = useState(data || {});
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("informations");
  const {
    firstName = "",
    lastName = "",
    phone = "",
    status = "",
    entreprise = "",
    email = "",
    civility = "M",
    adminId = "",
    createdAt = "",
    _id = "",
    comments = "",
    type = "individual",
  } = contact || {};
  const contactId = _id || id;
  useEffect(() => {
    id && setActiveTab("devis-contrats");
    setIsLoading(true);
    const fetchData = async () => {
      const res = await getAPI(
        `${api.contactById}/${auth.data.admin_id}?contactId=${contactId}`
      );
      if (res.status === 200) {
        setContact(res.data);
        setIsLoading(false);
      } else {
        showToast(res.message, "error");
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleUpdateCreatedFor = () => {
    dispatch(AuthActions.updateCreatedFor(_id));
    navigator("/");
  };

  const shortDateFormat = (params) => {
    const dateTimes = new Date(params);

    // Correct format for the year (yyyy instead of YYYY)
    const formattedEndDateTime = format(dateTimes, "dd MMM yyyy", {
      locale: fr,
    });
    return formattedEndDateTime;
  };
  return (
    <main id="main" className="main">
      <CustomModal
        show={showModal}
        onCancel={() => setShowModal(false)}
        title="Ajouter un contact"
      >
        <AddContactForm
          handleCancel={() => setShowModal(false)}
          handleAddContact={() => setShowModal(false)}
          formData={contact}
        />
      </CustomModal>
      <div className="pagetitle">
        <h1>{lang.offer.Offres}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard">{lang.common.home}</Link>
            </li>
            <li className="breadcrumb-item active">{"Coordonnées"}</li>
          </ol>
        </nav>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-4">
        {!isLoading && (
          <div
            className="d-flex align-items-center p-2 gap-4"
            style={{
              border: "1px solid black",
              borderRadius: "5px",
            }}
          >
            <div className="text-start">
              <strong>{`${firstName} ${lastName}`}</strong>
              <p>
                <span className="text-muted small">Entreprise: </span>
                <span className="text-muted small">{entreprise}</span>
              </p>
            </div>
            <div className="text-end">
              <span className="badge bg-warning text-dark">{status}</span>
              <p className="text-muted small mt-1">
                {`Créée le ` + shortDateFormat(createdAt)}
              </p>
            </div>
          </div>
        )}

        <div className="dropdown">
          <button
            className="btn btn-primary"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            + Nouveau devis
          </button>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li onClick={handleUpdateCreatedFor}>
              <Link className="dropdown-item">
                Responsabilité civile professionnelle
              </Link>
            </li>
            <li onClick={handleUpdateCreatedFor}>
              <Link className="dropdown-item">Multirisque professionnelle</Link>
            </li>
          </ul>
        </div>
      </div>

      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <button
            className={`nav-link ${
              activeTab === "informations" ? "active" : ""
            }`}
            onClick={() => setActiveTab("informations")}
          >
            Informations
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${
              activeTab === "devis-contrats" ? "active" : ""
            }`}
            onClick={() => setActiveTab("devis-contrats")}
          >
            Devis & Contrats
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "documents" ? "active" : ""}`}
            onClick={() => setActiveTab("documents")}
          >
            Documents
          </button>
        </li>
      </ul>
      {isLoading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          {activeTab === "informations" && (
            <div className="row">
              <div className="col-md-6">
                <div className="card p-3 mb-4">
                  <div className="d-flex justify-content-between">
                    <strong className="fs-3">Informations</strong>
                    <button
                      className="btn btn-link"
                      onClick={() => setShowModal(true)}
                    >
                      Modifier
                    </button>
                  </div>
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <td style={{ color: "#5a606f" }}>Civilité:</td>
                          <td>{civility}</td>
                        </tr>
                        <tr>
                          <td style={{ color: "#5a606f" }}>Nom:</td>
                          <td>{firstName}</td>
                        </tr>
                        <tr>
                          <td style={{ color: "#5a606f" }}>Prénom:</td>
                          <td>{lastName}</td>
                        </tr>
                        <tr>
                          <td style={{ color: "#5a606f" }}>Email:</td>
                          <td>{email}</td>
                        </tr>
                        <tr>
                          <td style={{ color: "#5a606f" }}>Téléphone:</td>
                          <td>{phone}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <CommentComponent id={_id} comments={comments} />
              </div>
            </div>
          )}
          {activeTab === "devis-contrats" && <DevisContrats id={_id} />}
          {activeTab === "documents" && <Documents id={_id} />}
        </div>
      )}
    </main>
  );
};

export default ContactUpdate;
