const Styles = {
  notificationBag: {
    top: "-3px",
    right: "-8px",
    width: "20px",
    height: "20px",
    border: "2px solid white",
    fontSize: "12px",
  },
  dropdown: {
    height: "30rem", // Fixed height to enable scrolling
    overflowX: "auto", // Enable vertical scrolling
  },
  notificationIcon: {
    flexShrink: 0,
    marginRight: "10px",
    width: "40px",
    height: "40px",
    backgroundColor: "#012970",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontSize: "18px",
    fontWeight: "bold",
  },
};

export default Styles;
