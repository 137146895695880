 
import { Box, Typography } from '@mui/material'
import FaqAccordion from './FaqAccordion'

export default function Fag() {
    const FagData = [
        {
            HeadText: "Comment fonctionne un comparateur d'assurance santé ?",
            dsc: "Un comparateur d'assurance santé vous permet de comparer les différentes offres de mutuelles santé disponibles sur le marché. En répondant à quelques questions simples, nous analysons vos besoins pour vous proposer les meilleures options en fonction de vos critères : prix, garanties, niveaux de couverture, etc."
        },
        {
            HeadText: "Combien coûte l'utilisation de votre comparateur ?",
            dsc: "Notre comparateur est 100% gratuit et sans engagement. Vous pouvez obtenir une comparaison détaillée des meilleures offres sans aucun coût et choisir l'option qui vous convient, ou simplement décider plus tard."
        },
        {
            HeadText: "Quels sont les critères utilisés pour comparer les offres de mutuelle santé ?",
            dsc: `Nous comparons les offres en fonction de plusieurs critères essentiels :
Le prix : en fonction de votre âge, lieu de résidence, et votre profil.
Les garanties : les soins couverts, comme les consultations médicales, hospitalisations, soins dentaires, optiques, etc.
Les niveaux de remboursement : pour chaque type de soin.
Les services additionnels : comme l'assistance à domicile ou les réseaux de santé partenaires.`
        },
        {
            HeadText: "Puis-je modifier mes critères après avoir obtenu des résultats ?",
            dsc: "Oui, vous pouvez ajuster vos critères à tout moment. Notre comparateur est flexible : si vous souhaitez changer vos préférences ou explorer d'autres options, il suffit de mettre à jour vos réponses pour voir des résultats personnalisés."
        },
        {
            HeadText: "Est-ce que le devis fourni est définitif ?",
            dsc: "Le devis fourni par notre comparateur est une estimation basée sur les informations que vous avez fournies. Le montant final peut légèrement varier après un examen plus approfondi par l'assureur, notamment si des informations complémentaires sont demandées."
        },
        {
            HeadText: "Combien de temps faut-il pour obtenir une comparaison ?",
            dsc: "En moyenne, il ne faut que 2 minutes pour remplir le formulaire et obtenir une liste des meilleures offres adaptées à vos besoins."
        },
        {
            HeadText: "Comment souscrire à une assurance santé après la comparaison ?",
            dsc: "Une fois que vous avez choisi l’offre qui vous convient, vous pouvez souscrire directement en ligne en suivant les instructions de l'assureur sélectionné. Notre équipe est également disponible pour vous guider dans cette étape si nécessaire."
        },
        {
            HeadText: "Quelles informations dois-je fournir pour obtenir une comparaison ?",
            dsc: `Pour vous proposer les meilleures offres, nous vous demanderons des informations simples telles que :
- Votre âge.
- Votre lieu de résidence.
- Vos besoins spécifiques en matière de couverture (hospitalisation, soins dentaires, optiques, etc.).
- La composition de votre foyer (seul, en couple, avec ou sans enfants).`
        },
        {
            HeadText: "Puis-je changer de mutuelle à tout moment ?",
            dsc: "Oui, vous pouvez changer de mutuelle à tout moment. Toutefois, il est important de vérifier les termes de votre contrat actuel pour connaître les délais de résiliation, notamment s'il y a des périodes d'engagement."
        },
        {
            HeadText: "Quelles sont les garanties incluses dans une mutuelle santé ?",
            dsc: `Les garanties varient d'une mutuelle à l'autre, mais les principales incluent :
Consultations médicales : remboursement des consultations chez le médecin.
Hospitalisation : prise en charge des frais d’hospitalisation.
Dentaire et optique : couverture des soins dentaires et des frais liés aux lunettes ou lentilles.
Médecine douce : selon l’assureur, certaines mutuelles couvrent également les séances de chiropractie, d’ostéopathie, etc.`
        },

    ]
    return (
        <div className='container'>
            <Box sx={{ mt: { xs: 0, sm: 4 }, py: { xs: 1, sm: 5 } }}>
                <Typography variant='h5' sx={{ fontWeight: 600, textAlign: 'center', mb: 4, color: '#37474F' }}>Vos questions fréquentes</Typography>
                <FaqAccordion FagData={FagData} />
            </Box>
        </div>
    )
}
