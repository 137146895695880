import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Offers from "./Offers";
import { useAppSelector } from "../../../store/stateHooks";
import lang from "../../../utils/language/french"
export default function AdminOffers() {
  const [offers, setOffers] = useState([]);
  const SERVER_URL = process.env.REACT_APP_API_SERVER;
  const { auth } = useAppSelector((state) => state.authCredential);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.data.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${SERVER_URL}/api/v1/confirm-offer/get-admin-offers/${auth.data.admin_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        setOffers(result.data);
      })
      .catch((error) => console.log("error", error));
  }, []);
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.offer.Offres}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">{lang.common.home}</Link>
              </li>
              <li className="breadcrumb-item active">{'Mes offres'}</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <Offers tbody={offers} />
        </section>
      </main>
    </>
  );
}
