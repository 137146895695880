import { useState, useRef } from "react";
import { CustomModal } from "../../../../modals";
import baseAxios from "../../../../../apis/baseAxios";
import { api } from "../../../../../utils/storage";
import { useAppSelector } from "../../../../../store/stateHooks";
import showToast from "../../../../../utils/toast";
const Documents = ({ id }) => {
  const { auth } = useAppSelector((state) => state.authCredential);
  const [showModal, setShowModal] = useState(false);
  const uploadedImage = useRef();
  const [uploadImg, setUploadImg] = useState(null);
  function uploadImage() {
    uploadedImage.current.click();
  }
  const [uploadedImg, setUploadedImg] = useState(null);
  const handleImageData = (e) => {
    setUploadedImg(e.target.files[0]);
    setUploadImg(URL.createObjectURL(e.target.files[0]));
  };

  const handleUploadImage = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (uploadedImg) {
      formData.append("document", uploadedImg);

      try {
        const response = await baseAxios.postMedia(
          `${api.contatUploadFile}/${auth.data.admin_id}?contactId=${id}`,
          formData
        );
        if (response.status == 200) {
          setShowModal(false);
          showToast(response.message, "success");
        } else {
          showToast(response.message, "error");
        }
      } catch (error) {
        console.error("Error uploading the image:", error);
      }
    }
  };
  
  return (
    <div
      className="p-4 bg-light"
      style={{
        border: "1px solid black",
        borderRadius: "5px",
      }}
    >
      <CustomModal
        show={showModal}
        onCancel={() => setShowModal(false)}
        title="Ajouter un document"
        width={"modal-lg"}
      >
        <form className="m-4">
          <p>
            Parcourez vos fichiers ou glissez / déposez vos fichiers directement
            dans la zone prévue à cet effet. Vous pouvez ajouter plusieurs
            documents en simultanés.
          </p>
          <div className="text-center">
            <div
              className="text-center mt-3 py-3 d-flex flex-column justify-content-center align-items-center gap-1"
              style={{ backgroundColor: "#eaeaee", borderRadius: 10 }}
            >
              <i
                className="bi bi-cloud-upload-fill fs-1 p-0 m-0"
                style={{
                  color: "#3252e9",
                  fontFamily: "bold",
                }}
              ></i>
              <div style={{ position: "relative" }}>
                <input
                  type="file"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                  ref={uploadedImage}
                  name="image"
                  onChange={handleImageData}
                  accept=".jpg, .jpeg, .png"
                />
                <button type="button" className="btn btn-outline-primary w-100">
                  Parcourir mes fichiers
                </button>
              </div>
              <p className="p-0 m-0">Format accepté: pdf / Taille max: 2MB</p>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-4 gap-4">
            <button
              type="button"
              className="btn btn-outline-primary w-50"
              onClick={() => setShowModal(false)}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="btn btn-primary w-50"
              onClick={handleUploadImage}
            >
              valider
            </button>
          </div>
        </form>
      </CustomModal>
      <div className="text-center">
        <div
          className="text-center mt-3 py-5"
          style={{ backgroundColor: "#eaeaee", borderRadius: 10 }}
        >
          <p className="p-0 m-0">Aucun document enregistré</p>
          <p className="p-0 m-0">
            Vous pouvez ajouter un document manuellement à tout moment
          </p>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            className="btn btn-primary"
            onClick={() => setShowModal(true)}
          >
            Ajouter un document
          </button>
        </div>
      </div>
    </div>
  );
};

export default Documents;
