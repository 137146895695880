import React, { useEffect } from "react";
import Header from "./Layout/Header";
import SideBar from "./Layout/SideBar";
import Footer from "./Layout/Footer";

function AdminWrapper({ component }) {
  useEffect(() => {
    import("./assets/js/main.js");
    document.documentElement.style.setProperty("--white", "#f6f9ff");
  }, []);

  return (
    <>
      <Header />
      <SideBar />
      {component}
      <Footer />
    </>
  );
}

export default AdminWrapper;
