import { useState } from "react";
import { nanoid } from "nanoid";

import getRadioFieldRender from "../../../helpers/getRadioFieldRender";
import DateField from "../../../UI/DateField";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import RadioField from "../../../UI/RadioField";
import RadioFieldGroup from "../../../UI/RadioFieldGroup";
import { useAppDispatch } from "../../../../store/stateHooks";
import {
  IMembers,
  membersActions,
} from "../../../../store/userDataSlices/membersSlice";

type ChildNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

interface Props {
  values?: IMembers["children"];
}

function ChildrenBirthDates(props: Props) {
  const children = props.values ? Object.values(props.values) : undefined;

  const [childrenNumbers, setChildrenNumbers] = useState(
    children && children.length ? children.length : 1
  );

  const [showMoreChildrenNums, setShowMoreChildrenNums] = useState(
    childrenNumbers > 3
  );

  const dispatch = useAppDispatch();

  if (!children || !children.length) {
    dispatch(membersActions.setChilds(childrenNumbers as ChildNumber));
  }

  const childrenNums = [
    ["Un(e) seul(e)", "premier"],
    ["Deux", "deuxième"],
    ["Trois", "troisième"],
    ["Quatre", "quatrième"],
    ["Cinq", "cinquième"],
    ["Six", "sixième"],
    ["Sept", "septième"],
    ["Huit", "huitième"],
    ["Neuf", "neuvième"],
  ];

  function showMoreChildrenNumsHandler() {
    setShowMoreChildrenNums(true);
  }

  function renderChildrenQuestions() {
    const childrenQuestions = [];
    for (let i = 0; i < childrenNumbers; i++) {
      let numberAsText = `${childrenNums[i][1]} `;
      if (childrenNumbers < 2) {
        numberAsText = "";
      }

      const answerHandler = function (value: string) {
        dispatch(
          membersActions.setChildBirthDate({
            childNumber: (i + 1) as ChildNumber,
            birthDate: value,
          })
        );
        dispatch(membersActions.setChildsNumber(childrenNumbers));
      };

      const getInitialState = function () {
        if (!children || !children[i]?.birthDate) {
          return undefined;
        }

        return new Date(children[i].birthDate);
      };

      childrenQuestions.push(
        <QuestionBox
          targetState="members"
          action="setChildBirthDate"
          key={nanoid(6)}
        >
          <FieldLabel>{`Date de naissance de votre ${numberAsText}enfant`}</FieldLabel>
          <DateField getState={answerHandler} state={getInitialState()} />
        </QuestionBox>
      );
    }

    return childrenQuestions;
  }

  function getChildrenNums() {
    const nums = childrenNums.map((num) => num[0]);

    return nums.filter((num, i) => (!showMoreChildrenNums ? i < 3 : num));
  }

  function updateChildrenNumbers(value: string) {
    for (let i = 0; i < childrenNums.length; i++) {
      if (childrenNums[i][0] === value) {
        setChildrenNumbers(i + 1);
        dispatch(membersActions.setChildsNumber(i + 1));
        return;
      } 
    }
  }

  return (
    <>
      <QuestionBox targetState="members" scrollToIt={true}>
        <FieldLabel>Combien d'enfants avez-vous ?</FieldLabel>
        <RadioFieldGroup
          type="horizontal"
          items={getChildrenNums()}
          renderItem={getRadioFieldRender("horizontal")}
          getSelectedValue={updateChildrenNumbers}
          defaultSelected={childrenNumbers - 1}
        >
          {!showMoreChildrenNums && (
            <RadioField
              text="Voir plus"
              type="horizontal"
              key={nanoid(6)}
              onClick={showMoreChildrenNumsHandler}
              textAlign="center"
            />
          )}
        </RadioFieldGroup>
      </QuestionBox>
      {childrenNumbers > 0 && renderChildrenQuestions()}
    </>
  );
}

export default ChildrenBirthDates;
