import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'; 
import { ReactComponent as ServicesSvg } from '../icons/Services.svg';
import { ReactComponent as TimesSvg } from '../icons/Times.svg';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Slide, } from '@mui/material';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function PhoneModel(props) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            {
                props.type === 'one' ?
                    <Paper onClick={handleClickOpen} sx={{ width: { xs: '100%', sm: 'auto' }, display: 'flex', gap: 1, justifyContent: "center", alignItems: "center", cursor: 'pointer', px: { xs: 1, sm: 2 }, py: 0.6, borderRadius: 100, backgroundColor: '#E5F0F8' }}>
                        <ServicesSvg />
                        <Typography sx={{ color: '#37474F',display: 'flex',flexDirection: { xs: 'column', sm: 'row' }, fontSize: { xs: '12px', sm: '1rem' } }}><Box component='span' sx={{ fontSize: { xs: '12px', sm: '15px' }, fontWeight: 600, color: '#006CB7' }}>Appel & Service gratuit : </Box> Lundi au vendredi de 9h à 19h</Typography>


                    </Paper>
                    :
                    <Box onClick={handleClickOpen} sx={{
                        width: { xs: '100%', sm: 'auto' }, display: 'flex', gap: 1, justifyContent: { xs: 'start', sm: 'center' }, alignItems: "center", cursor: 'pointer', color: props.color,
                        fontSize: '14px',
                        fontWeight: 300,
                        fontFamily: `"Poppins", sans-serif`,
                        opacity: '75%'
                    }}>
                        <ServicesSvg />
                        <Typography sx={{
                           color: props.color,
                            fontSize: '14px',
                            fontWeight: 300,
                            fontFamily: `"Poppins", sans-serif`,

                        }}>01 84 20 00 60</Typography>
                    </Box >

            }

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                sx={{
                    textAlign: 'center', borderRadius: '24px', mx: 'auto',

                    '& .MuiDialog-paper': {
                        borderRadius: '24px',
                        maxWidth: '400px',
                        py: '20px',
                        px: '10px'
                    }
                }}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Box onClick={handleClose} sx={{ position: 'absolute', top: '10px', right: '20px', cursor: 'pointer' }}><TimesSvg /></Box>
                <DialogTitle sx={{ fontSize: '18px', color: '#37474F', pb: 0.9 }}>Besoin d'aide ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" sx={{ color: '#69757B', fontSize: '16px', fontWeight: '400', lineHeight: '20px' }}>
                        Notre service client est disponible du
                        <Box component='span' sx={{ color: '#006CB7' }}> Lundi au Vendredi, de 9h à 19h </Box> pour répondre à toutes vos questions.
                    </DialogContentText>
                    <Button href="tel:01 84 20 00 60" variant="contained" sx={{ mt: 3, width: '100%', py: 1, borderRadius: '100px', color: '#ffffff' }} startIcon={<ServicesSvg />}> 01 84 20 00 60</Button>
                </DialogContent>

            </Dialog>
        </>
    )
}
