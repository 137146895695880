import getRadioFieldRender from "../../../helpers/getRadioFieldRender";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import RadioFieldGroup from "../../../UI/RadioFieldGroup";
import {
  IMembers,
  membersActions,
} from "../../../../store/userDataSlices/membersSlice";
import { useAppDispatch } from "../../../../store/stateHooks";

interface Props {
  value?: "Homme" | "Femme" | "";
}

function Gender(props: Props) {
  const dispatch = useAppDispatch();

  const genders = ["Homme", "Femme"];

  function answerHandler(value: string) {
    dispatch(membersActions.setGender(value as IMembers["gender"]));
  }

  return (
    <QuestionBox targetState="members" action="setGender">
      <FieldLabel>Quelle est votre civilité ?</FieldLabel>
      <RadioFieldGroup
        type="vertical"
        items={genders}
        renderItem={getRadioFieldRender("vertical", [
          "manOutline",
          "womanOutline",
        ])}
        getSelectedValue={answerHandler}
        defaultSelected={props.value ? genders.indexOf(props.value) : undefined}
      />
    </QuestionBox>
  );
}

export default Gender;
