import * as React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ReactComponent as WorldSvg } from "./../../icons/World.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { fr } from "date-fns/locale";
import { format } from "date-fns";
import showToast from "../../../utils/toast";
import lang from "../../../utils/language/french";
export default function CalendarBlock({ confirmUsersOffer }: any) {
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [timeSlot, setTimeSlot] = React.useState<string[]>([]);
  const [halfTimeSlot, setHalfTimeSlot] = React.useState<string>("");
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState("");
  const formattedDate = format(selectedDate, "EEEE, MMMM d", { locale: fr });
  const [currentTime, setCurrentTime] = React.useState<string>("");
  React.useEffect(() => {
    const slots = [
      "09h - 10h",
      "10h - 11h",
      "11h - 12h",
      "12h - 13h",
      "13h - 14h",
      "14h - 15h",
      "15h - 16h",
      "16h - 17h",
      "17h - 18h",
      "18h - 19h",
      "19h - 20h",
    ];
    setTimeSlot(slots);

    // Get the current time and next hour
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();
    const nextHour = currentHour + 1;
    const currentFormattedTime =
      currentMinutes >= 30
        ? `${nextHour}h - ${nextHour + 1}h`
        : `${currentHour}h - ${nextHour}h`;

    setSelectedTimeSlot(currentFormattedTime);
    const parisTimeFormatter = new Intl.DateTimeFormat("fr-FR", {
      timeZone: "Europe/Paris",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    const updateCurrentTime = () => {
      const nowInParis = parisTimeFormatter.format(new Date());
      setCurrentTime(nowInParis);
    };

    // Initial time set
    updateCurrentTime();

    // Update time every minute
    const intervalId = setInterval(updateCurrentTime, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Handle the change in date selection
  const handleDateFieldChange = (value: Date | null) => {
    const newDate = value ? new Date(value) : new Date();
    setSelectedDate(newDate);
  };

  // Function to determine if the time slot should be disabled
  const isTimeSlotDisabled = (timeSlot: string): boolean => {
    const now = new Date();

    // If no date is selected, disable all time slots
    if (!selectedDate) {
      return true;
    }

    const selectedTime = new Date(selectedDate);
    if (selectedTime.toDateString() === now.toDateString()) {
      // If today is selected, disable past time slots
      const [startHour] = timeSlot.split("h");
      const slotStartTime = new Date(
        selectedDate.setHours(parseInt(startHour.trim()), 0, 0, 0)
      );
      return slotStartTime < now;
    }

    return false;
  };

  // Handle time slot selection
  const handleChange = (event: any) => {
    if (!isTimeSlotDisabled(event)) {
      setSelectedTimeSlot(event);
      setHalfTimeSlot("");
    }
  };

  // Function to calculate the dynamic time (e.g., 10:30 for 10h - 11h)

  const calculateDynamicTime = (timeSlot: string): string => {
    const [start, end] = timeSlot.split(" - ");
    const [hour, minute] = start.split("h");
    const date = new Date();
    date.setHours(parseInt(hour.trim()), 30, 0, 0); // Set the time to 30 minutes after the hour
    return `${date.getHours()}:${
      date.getMinutes() === 0 ? "00" : date.getMinutes()
    }`;
  };

  const handleSubmit = () => {
    if (selectedTimeSlot !== "") {
      const data = {
        date: selectedDate,
        timeSlot: selectedTimeSlot,
        dynamicTime: halfTimeSlot,
      };

      confirmUsersOffer(data);
    } else {
      showToast(lang.offer.notSelectSlot, "info");
    }
  };
  return (
    <React.Fragment>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: "start",
        }}
      >
        <Box
          sx={{
            borderLeft: 1,
            borderRight: 1,
            borderColor: "rgba(0, 0, 0, 0.1)",
            px: 2,
            py: 5,
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: "#1A1A1A", fontWeight: "500", fontSize: "18px" }}
          >
            Sélectionnez la date et l’heure
          </Typography>
          <Box sx={{ textAlign: "left" }}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={fr}
            >
              <DateCalendar
                sx={{ m: 0 }}
                value={selectedDate}
                onChange={(newValue) => handleDateFieldChange(newValue)}
                shouldDisableDate={(date) => {
                  const now = new Date();
                  return (
                    date.getTime() <
                    new Date(now.setHours(0, 0, 0, 0)).getTime()
                  );
                }}
              />
            </LocalizationProvider>
          </Box>

          <Box>
            <Typography
              variant="h5"
              sx={{ color: "#1A1A1A", fontWeight: "500", fontSize: "16px" }}
            >
              Fuseau horaire
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <WorldSvg />
              <Typography variant="body2" sx={{ color: "#1A1A1A" }}>
                Heure d'Europe centrale ({currentTime})
              </Typography>
            </Stack>
          </Box>
        </Box>

        <Box sx={{ textAlign: "center", px: 1, py: 5 }}>
          <Typography variant="body2" mb={2}>
            {formattedDate}
          </Typography>
          <Stack
            direction="column"
            spacing={1}
            sx={{
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            {timeSlot.map((timeSlot, index) => (
              <Button
                key={index}
                variant={
                  selectedTimeSlot === timeSlot ? "contained" : "outlined"
                }
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                  color: selectedTimeSlot === timeSlot ? "white" : "#0069FF",
                }}
                onClick={() => handleChange(timeSlot)}
                disabled={isTimeSlotDisabled(timeSlot)}
              >
                {timeSlot}
              </Button>
            ))}
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Button
                //   halfTimeSlot
                variant={halfTimeSlot !== "" ? "contained" : "outlined"}
                sx={{
                  width: "100%",
                  fontWeight: "bold",
                  color: halfTimeSlot !== "" ? "white" : "#0069FF",
                }}
                onClick={() =>
                  setHalfTimeSlot(calculateDynamicTime(selectedTimeSlot))
                }
              >
                {calculateDynamicTime(selectedTimeSlot)}
              </Button>
              <Button
                variant="contained"
                sx={{ px: 2, fontSize: "12px" }}
                onClick={handleSubmit}
              >
                Suivant
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </React.Fragment>
  );
}
