import {
  IMembers,
  membersActions,
} from "../../../../store/userDataSlices/membersSlice";
import { useAppDispatch } from "../../../../store/stateHooks";
import getRadioFieldRender from "../../../helpers/getRadioFieldRender";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import RadioFieldGroup from "../../../UI/RadioFieldGroup";

interface Props {
  value?: string;
}

function Status(props: Props) {
  const dispatch = useAppDispatch();

  const statuses = [
    "Un adulte",
    "Un couple",
    "Un adulte + enfant(s)",
    "Un couple + enfant(s)",
  ];

  function answerHandler(value: string) {
    dispatch(membersActions.setStatus(value as IMembers["status"]));
  }

  return (
    <QuestionBox targetState="members" action="setStatus" scrollToIt={true}>
      <FieldLabel>Qui souhaitez-vous assurez ?</FieldLabel>
      <RadioFieldGroup
        type="horizontal"
        items={statuses}
        renderItem={getRadioFieldRender("horizontal")}
        getSelectedValue={answerHandler}
        defaultSelected={
          props.value ? statuses.indexOf(props.value) : undefined
        }
      ></RadioFieldGroup>
    </QuestionBox>
  );
}

export default Status;
