import { useState } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

const AddContratsMultiStepForm = ({
  handleCancel,
  handleAddContact,
  formData,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formState, setFormState] = useState(formData || {});

  const handleNextStep = (newData) => {
    setFormState((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleFinalSubmit = (data) => {
    handleAddContact(data);
  };

  return (
    <>
      {currentStep === 1 && (
        <StepOne
          formState={formState}
          handleNextStep={handleNextStep}
          handleCancel={handleCancel}
        />
      )}
      {currentStep === 2 && (
        <StepTwo
          formState={formState}
          handleNextStep={handleNextStep}
          handlePrevStep={handlePrevStep}
          handleCancel={handleCancel}
        />
      )}
      {currentStep === 3 && (
        <StepThree
          formState={formState}
          handlePrevStep={handlePrevStep}
          handleFinalSubmit={handleFinalSubmit}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
};

export default AddContratsMultiStepForm;
