import { Link } from "react-router-dom";
import { useState } from "react";
import NavbarBox from "./NavbarBox";
import classes from "./FormNavbar.module.css";
import ProgressBar from "./ProgressBar";
import { useAppSelector, useAppDispatch } from "../../store/stateHooks";
import { AuthModal } from "../modals";
import { navigationActions } from "../../store/appStateSlices/navigationSlice";
import { Box, Button } from "@mui/material";

import { ReactComponent as RedemarrerSvg } from '../icons/Redemarrer.svg';


import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function FormNavbar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const { user, authCredential } = useAppSelector((state) => state);
  console.log(user.user)
  const dispatch = useAppDispatch();
  const isAuthenticated = user.user.auth;
  const [showAuthModal, setShowAuthModal] = useState(false);
  const handleCancel = () => {
    setShowAuthModal(false);
  };

  const { activeAppPage, activeFormPage } = useAppSelector(
    (state) => state.appState.navigation
  );

  function restartPage() {
    dispatch(navigationActions.setActiveFormPage(1));
  }
  return (
    <NavbarBox>
      <AuthModal
        show={showAuthModal}
        onCancel={handleCancel}
        setShowAuthModal={handleCancel}
        login={showAuthModal}
      />
      <Box sx={{ display: matches ? "flex" : "none" }}>
        <ProgressBar />
      </Box>
      <div style={{ display: 'flex', gap: '5px' }}>
        {activeFormPage != 1 &&
          <Button onClick={restartPage} sx={{ textTransform: 'capitalize', fontWeight: 400, color: '#006CB7' }} variant="text" startIcon={<RedemarrerSvg />}  >Redémarrer</Button>}
        {
          isAuthenticated || authCredential.auth.auth ? (<Link to={authCredential.auth.auth ? "/admin/dashboard" : "/dashboard"} className={classes.restart}>
            Tableau de bord
          </Link>) : (<Button variant="text" sx={{ textTransform: 'capitalize', fontWeight: 400, color: '#455A64' }} onClick={() => setShowAuthModal(true)}>
            se connecter
          </Button>)
        }
      </div>
    </NavbarBox>
  );
}

export default FormNavbar;
