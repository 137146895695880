import { PropsWithChildren, useRef, useEffect } from "react";
import { membersActions } from "../../store/userDataSlices/membersSlice";
import { warrantiesActions } from "../../store/userDataSlices/warrantiesSlice";
import { credentialsActions } from "../../store/userDataSlices/credentialsSlice";
import classes from "./QuestionBox.module.css";

type Actions =
  | keyof typeof membersActions
  | keyof typeof warrantiesActions
  | keyof typeof credentialsActions;

interface Props extends PropsWithChildren {
  targetState: "members" | "warranties" | "credentials";
  action?: Actions;
  scrollToIt?: boolean;
}

function QuestionBox(props: Props) {
  const questionRef = useRef<HTMLDivElement | null>(null);
  const { scrollToIt } = props;

  useEffect(() => {
    if (!questionRef || !scrollToIt) {
      return;
    }

    window.scrollTo(0, questionRef.current!.offsetTop);
  }, [scrollToIt]);

  return (
    <div className={classes.container + "  MainContainer"} ref={questionRef}>
      {props.children}
    </div>
  );
}

export default QuestionBox;
