import * as React from 'react';

import { ReactComponent as CheckSvg } from '../../icons/check.svg';
import { ReactComponent as TabDownSvg } from '../../icons/TabDown.svg';
import { ReactComponent as TimesSvg } from '../../icons/Times.svg';
import { ReactComponent as SonisSvg } from '../../icons/Sonis.svg';
import { ReactComponent as DentainSvg } from '../../icons/Dentain.svg';
import { ReactComponent as OptiqueSvg } from '../../icons/Optique.svg';
import { ReactComponent as AidesSvg } from '../../icons/Aides.svg';
import { ReactComponent as HospitalisationSvg } from '../../icons/Hospitalisation.svg';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Slide, Stack, Typography, } from '@mui/material';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailsDeLaGarantie() {
    const garantieData = [
        {
            name: 'Soins courants',
            icon: <SonisSvg />,
            Tabletext: [
                {
                    LeftText: 'Consultations chez généralistes',
                    RightText: '100 % (Optam / Non-Optam)',
                },
                {
                    LeftText: 'Consultations chez spécialistes',
                    RightText: '100 % (Optam / Non-Optam)',

                },
                {
                    LeftText: 'Pharmacie',
                    RightText: '100 % sur médicaments remboursés',
                },
                {
                    LeftText: 'Laboratoires (analyses médicales)',
                    RightText: '100%',
                },
            ]
        },
        {
            name: 'Dentaire',
            icon: <DentainSvg />,
            Tabletext: [
                {
                    LeftText: 'Soins dentaires courants',
                    RightText: `Jusqu'à 100 %`,
                },
                {
                    LeftText: 'Prothèses dentaires',
                    RightText: `70 % à 100 % selon contrat`,
                },
                {
                    LeftText: 'Orthodontie',
                    RightText: `Plafond annuel appliqué`,
                },
                {
                    LeftText: 'Implant dentaire',
                    RightText: `Partiellement pris en charge`,
                },


            ]

        },
        {
            name: 'Optique',
            icon: <OptiqueSvg />,
            Tabletext: [
                {
                    LeftText: 'Lunettes de vue (verres et montures)',
                    RightText: `Forfait annuel : 150 €`,
                },
                {
                    LeftText: 'Lentilles sur ordonnance',
                    RightText: `Selon justification médicale`,
                },
                {
                    LeftText: 'Chirurgie réfractive (myopie, astigmatisme)',
                    RightText: `Partielle selon contrat`,
                },
                {
                    LeftText: 'Réparation ou remplacement de lunettes',
                    RightText: `Inclus selon conditions`,
                },
            ]
        },
        {
            name: 'Aides auditives',
            icon: <AidesSvg />,
            Tabletext: [{
                LeftText: 'Appareils auditifs (1 par oreille)',
                RightText: `300 € par appareil tous les 4 ans`,
            },
            {
                LeftText: 'Réparations et accessoires',
                RightText: `Selon plafond annuel`,
            },
            {
                LeftText: 'Consultation ORL pour évaluation',
                RightText: `100 %`,
            },
            {
                LeftText: 'Entretien annuel des appareils',
                RightText: `Partiellement pris en charge`,
            },

            ]


        },
        {
            name: 'Hospitalisation',
            icon: <HospitalisationSvg />,
            Tabletext: [{
                LeftText: 'Honoraires Optam / N.Optam',
                RightText: `100% ** / 100% **`,
            },
            {
                LeftText: 'Chambre particulière',
                RightText: `-`,
            },
            {
                LeftText: 'Frais de séjour',
                RightText: `100%`,
            },
            ]


        }
    ]

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <>
                <Box
                    onClick={handleClickOpen}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "center",
                        alignItems: "center",
                        gap: '5px',
                        cursor: 'pointer',
                    }}>
                    <TabDownSvg />
                    <Typography sx={{ fontSize: '12px', color: '#455A64' }}>Détails de la garantie</Typography>

                </Box>


                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    sx={{
                        textAlign: 'center', borderRadius: '24px', mx: 'auto',
                        '& .MuiDialog-paper': {
                            borderRadius: '24px',
                            width: '770px',
                            maxWidth: '770px',
                            py: '20px',
                            px: '7px'
                        }
                    }}
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <Box onClick={handleClose} sx={{ position: 'absolute', top: '10px', right: '20px', cursor: 'pointer' }}><TimesSvg /></Box>
                    <DialogTitle sx={{ fontSize: '20px', fontWeight: '700', color: '#37474F', pb: 0.9, textAlign: 'left', mb: 4, }}>Détails de la garantie</DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">


                            {
                                garantieData.map((item, index) => {
                                    return (
                                        <Box key={index} mb={4}>
                                            <Stack spacing={{ xs: 1, sm: 1 }}
                                                mb={2}
                                                sx={{
                                                    alignItems: "center",
                                                }}
                                                direction="row">
                                                {item.icon}
                                                <Typography variant='body1' sx={{ fontWeight: '500' }} color={'#37474F'}>{item.name}</Typography>
                                            </Stack>
                                            {
                                                item.Tabletext?.map((tableItem, tindex) => {
                                                    return (
                                                        <Box key={tindex} sx={[{ bgcolor: '#32deac1c', borderRadius: '8px', py: 1, px: 2 }, {
                                                            '&:nth-of-type(odd)': {
                                                                bgcolor: '#fff',
                                                            }
                                                        }
                                                        ]}
                                                        >
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} md={7}>
                                                                    <Stack spacing={{ xs: 1, sm: 1 }}
                                                                        direction="row"
                                                                        sx={{
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <CheckSvg />
                                                                        <Typography variant='body1' sx={{ fontWeight: '400' }} color={'#37474F'}>{tableItem.LeftText}</Typography>
                                                                    </Stack>
                                                                </Grid>
                                                                <Grid item xs={12} md={5}>
                                                                    <Typography variant='body1' sx={{ fontWeight: '400', textAlign: 'left' }} color={'#37474F'}>
                                                                        {tableItem.RightText}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>

                                                        </Box >
                                                    )
                                                })
                                            }

                                        </Box>
                                    )
                                })
                            }

                        </DialogContentText>

                    </DialogContent>

                </Dialog>
            </>
        </React.Fragment >
    );
}
