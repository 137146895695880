import { nanoid } from "nanoid";
import Icon from "./Icon";
import classes from "./WarrantyLevel.module.css";

interface Props {
  icon: string;
  level: 1 | 2 | 3 | 4;
}

function WarrantyLevel(props: Props) {
  function renderLevels() {
    return [...Array(8)].map((num, i) => {
      const CSSClass = `${classes.level}${
        i + 1 <= props.level * 2 ? ` ${classes.filled}` : ""
      }`;

      return <div className={CSSClass} key={nanoid(6)}></div>;
    });
  }

  return (
    <div className={classes.container}>
      <div className={classes.levels}>{renderLevels()}</div>
      <Icon name={props.icon} className={classes.icon} />
    </div>
  );
}

export default WarrantyLevel;
