import React from "react";
import { useForm } from "react-hook-form";
import CustomAsyncSelect from "../../../../users/components/CustomAsyncSelect";
import InputField from "../../../../users/components/inputField";
import lang from "../../../../../utils/language/french";

import { postAPI, putAPI } from "../../../../../apis/Services"; // Assume updateAPI exists
import { api } from "../../../../../utils/storage";
import { useAppSelector } from "../../../../../store/stateHooks";
import showToast from "../../../../../utils/toast";
const UpdateContactForm = ({
  //   formState,
  //   handlePrevStep,
  //   handleFinalSubmit,
  //   handleCancel,
  handleCancel,
  handleAddContact,
  formData,
}) => {
  const {
    firstName = "",
    lastName = "",
    phone = "",
    status = "",
    entreprise = "",
    email = "",
    civility = "M",
    adminId = "",
    createdAt = "",
    type = "",
  } = formData || {};

  const { auth } = useAppSelector((state) => state.authCredential);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName,
      lastName,
      phone,
      email,
      civility,
    },
  });

  const onSubmit = async (data) => {
    try {
      // Update contact if formData exists
      const res = await putAPI(`${api.contrat}/${auth.data.admin_id}`, {
        ...data,
        id: formData._id,
      });
      showToast("Contact mis à jour avec succès", "success");
      console.log(res);
      handleAddContact()
      reset();
      handleCancel();
    } catch (err) {
      console.log(err);
      showToast(lang.common.error, "error");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="m-4">
      <div className="justify-content-between align-items-center">
        <p>
          {true
            ? "Modifier les informations du contact."
            : "Renseignez les champs obligatoires concernants les informations du contrat"}
        </p>
        <div className="mt-3 d-flex">
          <label className="form-label fw-normal" style={{ color: "#000" }}>
            Civilité :
          </label>
          <div className="ms-2">
            <input
              type="radio"
              id="male"
              value="M"
              {...register("civility", { required: true })}
              className="form-check-input"
            />
            <label
              className="form-check-label ms-2"
              htmlFor="male"
              style={{ color: "#5a606f" }}
            >
              M
            </label>

            <input
              type="radio"
              id="female"
              value="Mme"
              {...register("civility", { required: true })}
              className="form-check-input ms-5"
            />
            <label
              className="form-check-label ms-2"
              htmlFor="female"
              style={{ color: "#5a606f" }}
            >
              Mme
            </label>
          </div>

          {errors.civility && (
            <p className="text-danger">Civilité est vendeur</p>
          )}
        </div>
        <div className="row">
          <div className="col">
            <InputField
              key="firstName"
              label="* Nom du vendeur"
              name="firstName"
              type="text"
              placeholder="Nom"
              register={register}
              errors={errors}
              validation={{ required: "Nom est obligatoire" }}
            />
          </div>
          <div className="col">
            <InputField
              key="lastName"
              label="* Prénom du vendeur"
              name="lastName"
              type="text"
              placeholder="Prénom"
              register={register}
              errors={errors}
              validation={{ required: "Prénom est obligatoire" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <InputField
              key="email"
              label="* Email du vendeur"
              name="email"
              type="text"
              placeholder="Email du vendeur"
              register={register}
              errors={errors}
              validation={{ required: "Email est obligatoire" }}
            />
          </div>
          <div className="col">
            <InputField
              key="phone"
              label="* Numéro de téléphone du vendeur"
              name="phone"
              type="text"
              placeholder="Numéro de téléphone du vendeur"
              register={register}
              errors={errors}
              validation={{ required: "Prénom est obligatoire" }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between mt-4 gap-3 ">
          <button
            type="button"
            className="btn btn-outline-primary w-50"
            // onClick={() => handlePrevStep()}
          >
            Retour
          </button>
          <button type="submit" className="btn btn-primary w-50">
            {"soumettre"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default UpdateContactForm;
