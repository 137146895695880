import { PropsWithChildren } from "react";

import classes from "./FormPage.module.css";

function FormPage(props: PropsWithChildren) {
  return (
    <div className={classes.container}>
      <div className={classes.page}>{props.children}</div>
    </div>
  );
}

export default FormPage;
