import { IMembers } from "../../store/userDataSlices/membersSlice";

export default function getUsername(
  gender: IMembers["gender"],
  firstName: string,
  lastName: string
) {
  let prename = "Mr.";
  if (gender === "Femme") {
    prename = "Mme.";
  }

  return `${prename} ${firstName} ${lastName}`;
}
