import { PropsWithChildren } from "react";

import classes from "./FormBox.module.css";
import { Box } from "@mui/material";

function FormBox(props: PropsWithChildren) {
  return (
    <Box className={classes.container} sx={{ mt: { xs: 3, sm: '4rem' }, px: { xs: 1.2, sm: 0 } }}>
      <div className={classes.pageFormA}>{props.children}</div>
    </Box>

  )
}

export default FormBox;
