import { PropsWithChildren, ReactElement, useState } from "react";

import { RadioFieldGetState } from "./RadioField";

import classes from "./RadioFieldGroup.module.css";

interface Props extends PropsWithChildren {
  type: "horizontal" | "vertical";
  items: string[];
  renderItem: (
    item: string,
    index: number,
    isSelected: boolean,
    getState: RadioFieldGetState
  ) => ReactElement;
  getSelectedValue: (value: string, index: number) => void;
  defaultSelected?: number;
}

function RadioFieldGroup(props: Props) {
  const { items } = props;
  const [selectedItem, setSelectedItem] = useState<number | null>(
    props.defaultSelected !== undefined ? props.defaultSelected : null
  );

  function getState(state: {
    value: string;
    index: number;
    isSelected: boolean;
  }) {
    if (!state.isSelected) {
      return;
    }

    setSelectedItem(state.index);
    props.getSelectedValue(state.value, state.index);
  }

  function renderFields() {
    return items.map((item, i) => {
      const isSelected = i === selectedItem;
      return props.renderItem(item, i, isSelected, getState);
    });
  }

  return (
    <div
      className={"RadionBoxContainer  " + classes[`container-${props.type}`]}
    >
      {renderFields()}
      {props.children}
    </div>
  );
}

export default RadioFieldGroup;
