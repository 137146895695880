import { useEffect, useState } from "react";
import "./styles.css";
import lang from "../../utils/language/french";
import Logo from "../UI/Logo";
import { useNavigate } from "react-router-dom";
import { SignupForm, LoginForm } from "../UI/forms";
import { useAppSelector } from "../../store/stateHooks";
const AuthModal = ({ show, onCancel, setShowAuthModal, login = false }) => {
  const subscriberData = useAppSelector((state) => state.userData.credentials);
  const navigate = useNavigate();
  const [fadeType, setFadeType] = useState("");
  const [swichLogin, setSwichLogin] = useState(false);

  useEffect(() => {
    login ? setSwichLogin(true) : setSwichLogin(false);
    if (show) {
      setFadeType("in");
    } else {
      setFadeType("out");
    }
  }, [show]);

  const handleAnimationEnd = () => {
    if (fadeType === "out") {
      onCancel();
    }
  };
  return (
    <div
      className={`modal fade ${
        show ? "show d-block" : "d-none"
      } fade-${fadeType}`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-md-6 text-center mb-3 mb-md-0">
                <Logo />
              </div>
              {/* <div className="col-12 col-md-6 text-center">
                <h5 className="modal-title">
                  {swichLogin ? "Login" : "Sign Up"}
                </h5>
              </div> */}
            </div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                setFadeType("out");
                // setSwichLogin(false);
              }}
            ></button>
          </div>
          <div className="modal-body">
            {swichLogin ? (
              <>
                <LoginForm
                  user={subscriberData}
                  setShowAuthModal={setShowAuthModal}
                />
                <div className="text-center mt-3">
                  <p>
                    je n'ai pas de compte{" "}
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => setSwichLogin(false)}
                    >
                      S'inscrire
                    </button>
                  </p>
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={() =>
                      navigate("/forgot-password", { state: { role: "user" } })
                    }
                  >
                    {lang.admin.forgotPassword}
                  </button>
                </div>
              </>
            ) : (
              <>
                <SignupForm
                  user={subscriberData}
                  setShowAuthModal={setSwichLogin}
                />
                <div className="text-center mt-3">
                  <p>
                    Vous avez déjà un compte ?{" "}
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={() => setSwichLogin(true)}
                    >
                      {lang.admin.login}
                    </button>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
