import { nanoid } from "nanoid";

import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import WarrantyBox from "../../../UI/WarrantyBox";
import { useAppDispatch } from "../../../../store/stateHooks";
import {
  IWarranties,
  warrantiesActions,
  WarrantyLevel,
} from "../../../../store/userDataSlices/warrantiesSlice";

function Levels() {
  const dispatch = useAppDispatch();

  const warranties = [
    {
      title: "Soins médicaux courants",
      id: "medicalCare",
      icon: "fitnessOutline",
      label: "médecine générale, radios, pharmacie, etc.",
    },
    {
      title: "Dentaire",
      id: "dental",
      icon: "sparklesOutline",
      label: "dentiste, prothèses, soins, etc.",
    },
    {
      title: "Optique",
      id: "visual",
      icon: "glassesOutline",
      label: "lentilles, lunettes, chirurgie réfractive, etc.",
    },
    {
      title: "Aides auditives",
      id: "hearing",
      icon: "earOutline",
      label: "prothèse auditive, etc.",
    },
    {
      title: "Hospitalisation",
      id: "hospitalization",
      icon: "businessOutline",
      label: "frais de séjour, frais de transport, chirurgie, etc.",
    },
  ];

  function renderWarrantiesBoxes() {
    return warranties.map((warranty) => {
      function answerHandler(value: WarrantyLevel) {
        dispatch(
          warrantiesActions.setLevel({
            warranty: warranty.id as keyof IWarranties,
            level: value,
          })
        );
      }

      return (
        <WarrantyBox
          key={nanoid(6)}
          title={warranty.title}
          icon={warranty.icon}
          label={`(${warranty.label})`}
          id={warranty.id as keyof IWarranties}
          getState={answerHandler}
        />
      );
    });
  }

  return (
    <QuestionBox targetState="warranties" action="setLevel">
      <FieldLabel>Choisissez vos niveaux de garanties</FieldLabel>
      {renderWarrantiesBoxes()}
    </QuestionBox>
  );
}

export default Levels;
