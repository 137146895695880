import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { ReactComponent as ClockSvg } from '../icons/Clock.svg';

import { useMediaQuery, useTheme } from '@mui/material';
import PhoneModel from './PhoneModel';
export default function PageTopText(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Box sx={{ mt: matches ? 4 : 12, }}>
            <Stack
                sx={{
                    justifyContent: "center", alignItems: "center",
                    px: { xs: 1, sm: 0 }
                }}
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
            >
                <Paper sx={{ display: 'flex', width: { xs: '100%', sm: 'auto' }, gap: 1, justifyContent: "center", alignItems: "center", px: { xs: 1, sm: 2 }, py: { xs: 1, sm: 0.6 }, borderRadius: 100, backgroundColor: '#F6F6F9', borderRight: "1px solid #E6E6E6" }}>
                    <ClockSvg />
                    <Typography sx={{ color: '#37474F', fontSize: { xs: '12px', sm: '1rem' } }}>Résultat en 3 minutes</Typography>
                </Paper>
                <PhoneModel type="one" />

            </Stack>

        </Box>
    )
}
