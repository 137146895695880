import * as React from "react";
import { ReactComponent as PlusDinforSvg } from "../icons/plusDinfor.svg";
import { ReactComponent as TabDownSvg } from "../icons/TabDown.svg";
import { nanoid } from "nanoid";
import { navigationActions } from "../../store/appStateSlices/navigationSlice";

import WarrantyLevel from "./WarrantyLevel";
import { Offer as IOffer } from "../hooks/useAPI";
import { useAppDispatch, useAppSelector } from "../../store/stateHooks";
import classes from "./Offer.module.css";
import getWarrantiesIcons from "../helpers/getWarrantiesIcons";
import { offerActions } from "../../store/appStateSlices/selectedOffer";
import { useEffect, useState } from "react";
import lang from "../../utils/language/french";
import { ConfirmationModal, AuthModal } from "../modals";
import showToast from "../../utils/toast";
import { postAPI } from "../../apis/Services";
import { api } from "../../utils/storage";
import { useNavigate } from "react-router-dom";
import OfferLastButton from "./OfferLastButton";
import { Box, Stack, Typography } from "@mui/material";
import PropoAssurances from "./OfferSearch/PropoAssurances";
import DetailsDeLaGarantie from "./OfferSearch/DetailsDeLaGarantie";
import ThankYouModel from "./JeSouhaiteUnRappel/ThankYouModel";

interface Props {
  provider: IOffer["provider"];
  monthlyPrice: number;
  levels: (1 | 2 | 3 | 4)[];
  name?: string;
  code?: string;
  description?: string;
  label?: string;
  check?: Number;
}

function Offer(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const warranties = getWarrantiesIcons();

  function confirmOffer(offer: Props) {
    dispatch(offerActions.setOffer(offer));
    dispatch(navigationActions.setActiveAppPage(4));
  }

  // const [timeSlot, setTimeSlot] = useState<string[]>([]);
  const [timeSlot, setTimeSlot] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>("");
  const [halfTimeSlot, setHalfTimeSlot] = React.useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [thankYouModel, setThankYouModel] = React.useState(false);
  const [confirmationCallback, setConfirmationCallback] = useState<
    (() => void) | null
  >(null);
  const [isChecked, setIsChecked] = useState(false);
  const [JesouhaiteOpen, setJesouhaiteOpen] = useState(false);

  const showConfirmationModal = (callback: () => void) => {
    setConfirmationCallback(() => callback);
    setShowModal(true);
  };
  const subscriberData = useAppSelector((state) => state.userData);
  const { user, authCredential } = useAppSelector((state) => state);
  const role = user?.user?.role || authCredential.auth.role;
  const postOfferData = async (offer: Props) => {
    try {
      let dateTime = { date: selectedDate, timeSlot: timeSlot[0] };
      const data = {
        data: subscriberData,
        offer: offer,
        dateTime: dateTime,
        halfTimeSlot: halfTimeSlot,
        callEmailPermissionStatus: isChecked,
        role: role,
        created_for: authCredential.auth.created_for,
      };
      const response = await postAPI(
        authCredential?.auth?.data?.admin_id
          ? `${api.confirmOffer}/${authCredential.auth.data.admin_id}`
          : `${api.confirmOffer}`,
        data
      );
      window.scrollTo(0, 0);
      if (!response.error) {
        setTimeSlot([]);
        setSelectedDate("");
        setThankYouModel(true);
        if (
          authCredential.auth.created_for &&
          authCredential.auth.created_for != ""
        ) {
          // dispatch(AuthActions.updateCreatedFor(""));
          navigate("/admin/contact-update", {
            state: { id: authCredential.auth.created_for },
          });
          dispatch(navigationActions.setActiveAppPage(1));
        }
      } else {
        showToast(response.message, "info");
      }
    } catch (err) {
      showToast(lang.offer.errorMessage, "error");
      window.scrollTo(0, 0);
    }
  };
  const confirmUsersOffer = (offer: Props) => {
    if (!isChecked) {
      if (!user.user.auth && !authCredential.auth.auth) {
        setShowAuthModal(true);
      } else {
        showConfirmationModal(() => postOfferData(offer));
      }
    } else {
      showToast(
        "Veuillez cocher la case des autorisations pour continuer.",
        "info"
      );
    }
  };
  const confirmAllsOffer = (data: {
    name: string;
    phone: string;
    date: Date | string;
    timeSlot: string;
  }) => {
    if (data.timeSlot !== "" && data.date !== "") {
      // Set the selected items and date
      setTimeSlot([data.timeSlot]);
      setSelectedDate(data.date);
    } else {
      showToast(lang.offer.notSelectSlot, "info");
      setJesouhaiteOpen(!JesouhaiteOpen);
    }
  };
  const confirmConsultation = (data: {
    halfTimeSlot: string;
    date: Date | string;
    timeSlot: string;
  }) => {
    if (data.timeSlot !== "" && data.date !== "") {
      setTimeSlot([data.timeSlot]);
      setSelectedDate(data.date);
      setHalfTimeSlot(data.halfTimeSlot);
    } else {
      showToast(lang.offer.notSelectSlot, "info");
      setJesouhaiteOpen(!JesouhaiteOpen);
    }
  };

  // Use useEffect to call confirmUsersOffer once the timeSlot and selectedDate have been set
  useEffect(() => {
    if (timeSlot.length > 0 && selectedDate !== "") {
      confirmUsersOffer(props);
    }
  }, [selectedDate]);
  const handleConfirm = () => {
    if (confirmationCallback) {
      confirmationCallback();
      setShowModal(false);
      setShowAuthModal(false);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setShowAuthModal(false);
  };

  return (
    <>
      <ConfirmationModal
        show={showModal}
        title={lang.offer.confirmOffierModalTitle}
        message={lang.offer.confirmOfferModalBody}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <AuthModal
        show={showAuthModal}
        onCancel={handleCancel}
        setShowAuthModal={() => {
          postOfferData(props);
          setShowAuthModal(false);
        }}
      />
      <ThankYouModel
        thankYouModel={thankYouModel}
        setThankYouModel={setThankYouModel}
      />
      <div className={classes.container + " mb-4"}>
        <div className={classes.contentcontainer}>
          <div className={classes.provider} style={{ alignItems: "start" }}>
            <img
              className={classes.logo}
              src={`${process.env.PUBLIC_URL}/assets/img/${props.provider}-Logo.png`}
              alt={`${props.provider} Logo`}
            />
            <h4
              className={classes.label}
              style={{
                fontSize: "14px",
                color: "#37474F",
                width: "100%",
                textAlign: "left",
                marginTop: "15px",
                fontWeight: "700",
              }}
            >
              {props.name || props.label || props.provider}
            </h4>
            {props.code && <p className={classes.code}>{props.code}</p>}
          </div>
          <div className={classes.warranties}>
            {warranties.map((warranty, i) => (
              <WarrantyLevel
                key={nanoid(6)}
                icon={warranty.icon}
                level={props.levels[i]}
              />
            ))}
          </div>
          <div className={classes.more}>
            <div className={classes.price}>
              <div className={classes["monthly-price"]}>
                <span>{props.monthlyPrice} €</span>
                <span> /mois</span>
              </div>
              <div className={classes["yearly-price"]}>
                <p>
                  Budget estimé: {Math.round(props.monthlyPrice * 12)} €/ans
                </p>
              </div>

              <div className={classes["informations"]}>
                <Box>
                  <PlusDinforSvg />
                </Box>
                <p className="m-0">Plus d'informations</p>
              </div>
            </div>
            {/* {props.check === 0 ? (
              <Button onClick={() => confirmOffer(props)}>Savoir Plus</Button>
            ) : (
              <Button onClick={() => confirmUsersOffer(props)}>Confirmer</Button>
            )} */}
          </div>
          <div className={classes.more}>
            <div className={classes.buttonStack}>
              <OfferLastButton
                model={true}
                confirmUsersOffer={confirmAllsOffer}
                JesouhaiteOpen={JesouhaiteOpen}
                setJesouhaiteOpen={setJesouhaiteOpen}
                directSUbmitOffer={() => confirmUsersOffer(props)}
                confirmConsultation={confirmConsultation}
                offer={props}
              />
            </div>
          </div>
        </div>

        <Stack
          direction="row"
          spacing={4}
          mt={3}
          sx={{
            justifyContent: "start",
            alignItems: "center",
            opacity: "75%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <TabDownSvg />
            <Typography sx={{ fontSize: "12px", color: "#455A64" }}>
              Tableau de garanties
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <TabDownSvg />
            <Typography sx={{ fontSize: "12px", color: "#455A64" }}>
              Conditions générales
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <TabDownSvg />
            <Typography sx={{ fontSize: "12px", color: "#455A64" }}>
              Fiche d’information
            </Typography>
          </Box>
          <PropoAssurances
            confirmUsersOffer={confirmAllsOffer}
            directSUbmitOffer={() => confirmUsersOffer(props)}
            confirmConsultation={confirmConsultation}
            offer={props}
          />
          <DetailsDeLaGarantie />
        </Stack>
      </div>
    </>
  );
}

export default Offer;
