import SectionHeading from "../../UI/SectionHeading";

import Gender from "./questions/Gender";
import BirthDate from "./questions/BirthDate";
import Activity from "./questions/Activity";
import SocialRegime from "./questions/SocialRegime";
import Status from "./questions/Status";
import PartnerGender from "./questions/PartnerGender";
import PartnerbirthDate from "./questions/PartnerBirthDate";
import ChildrenBirthDates from "./questions/ChildrenBirthDates";

import { useAppDispatch, useAppSelector } from "../../../store/stateHooks";
import FormBox from "../../UI/FormBox";
import getMembersIsFulfilled from "../../helpers/getMembersIsFulfilled";
import Button from "../../UI/Button";
import { navigationActions } from "../../../store/appStateSlices/navigationSlice";
import lang from "../../../utils/language/french"
import ClientsSatisfaits from "../../UI/clientsview/ClientsSatisfaits";
import Fag from "../../UI/Faq/Fag";
import KnowMore from "../../UI/KnowMore";
import Footer from "../../UI/Footer";
import PageTopText from "../../UI/pageTopText";

function GatherMembers() {
  const subscriberData = useAppSelector((state) => state.userData.members);
  const dispatch = useAppDispatch();

  const hasPartner = subscriberData.hasPartner;

  const hasChildren =
    subscriberData.status === "Un adulte + enfant(s)" ||
    subscriberData.status === "Un couple + enfant(s)";

  const childrenBirthDatesRenderTime = hasPartner
    ? subscriberData.partnerBirthDate
    : subscriberData.status;

  function nextPageHandler() {
    dispatch(navigationActions.setActiveFormPage(2));
  }

  return (
    <>
     <PageTopText />
      <FormBox>
        <SectionHeading>{lang.common.members}</SectionHeading>
        <Gender value={subscriberData.gender} />
        {subscriberData.gender && <BirthDate value={subscriberData.birthDate} />}
        {subscriberData.birthDate && <Activity value={subscriberData.activity} />}
        {subscriberData.activity && (
          <SocialRegime value={subscriberData.socialRegime} />
        )}
        {subscriberData.socialRegime && <Status value={subscriberData.status} />}
        {hasPartner && subscriberData.status && (
          <>
            <PartnerGender value={subscriberData.partnerGender} />
            {subscriberData.partnerGender && (
              <PartnerbirthDate value={subscriberData.partnerBirthDate} />
            )}
          </>
        )}
        {hasChildren && childrenBirthDatesRenderTime && (
          <ChildrenBirthDates values={subscriberData.children} />
        )}
        {getMembersIsFulfilled(subscriberData) && (
          <Button onClick={nextPageHandler} scrollToIt={true}>
            {lang.common.continue}
          </Button>
        )}
      </FormBox>
      <ClientsSatisfaits />
      <Fag />
      <KnowMore />



      <Footer />
    </>
  );
}

export default GatherMembers;
