import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as TimeClock } from "../../icons/TimeClock.svg";
import { ReactComponent as AppleSvg } from "../../icons/phoneSecond.svg";
import classes from "../Offer.module.css";

export default function ConsultationBlock({ provider }: any) {
  return (
    <React.Fragment>
      <Box py={5}>
        <img
          className={classes.logo}
          src={`${process.env.PUBLIC_URL}/assets/img/${provider}-Logo.png`}
          alt={`${provider} Logo`}
        />

        <Typography
          variant="h5"
          sx={{ color: "#1A1A1A", fontWeight: "bold", mt: 3 }}
        >
          Consultation personnalisée
        </Typography>
        <Stack direction="row" spacing={2}>
          <TimeClock />
          <Typography
            variant="body1"
            sx={{ color: "rgba(26, 26, 26, 0.6)", fontWeight: "bold" }}
          >
            10 min
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <AppleSvg />
          <Typography
            variant="body1"
            sx={{ color: "rgba(26, 26, 26, 0.6)", fontWeight: "bold" }}
          >
            Appel téléphonique
          </Typography>
        </Stack>

        <Typography
          variant="body2"
          mt={3}
          mb={4}
          color={"#37474F"}
          sx={{ fontSize: "16px", fontWeight: "400", color: "#1A1A1A" }}
        >
          <Box component={"span"} sx={{ fontWeight: "500" }}>
            {" "}
            Planifiez un appel avec un expert{" "}
          </Box>{" "}
          pour discuter d'une{" "}
          <Box component={"span"} sx={{ fontWeight: "500" }}>
            {" "}
            étude personnalisée{" "}
          </Box>{" "}
          de votre assurance santé. Notre conseiller vous aidera à choisir{" "}
          <Box component={"span"} sx={{ fontWeight: "500" }}>
            {" "}
            l'offre la mieux adaptée{" "}
          </Box>{" "}
          à vos besoins.
        </Typography>
        <Link
          to={"/"}
          style={{ fontSize: "11px", position: "absolute", bottom: "20px" }}
        >
          Paramètres des cookies
        </Link>
      </Box>
    </React.Fragment>
  );
}
