import React from "react";
import { useForm } from "react-hook-form";
import CustomAsyncSelect from "../../../../users/components/CustomAsyncSelect";
import InputField from "../../../../users/components/inputField";

import { postAPI, putAPI } from "../../../../../apis/Services"; // Assume updateAPI exists
import { api } from "../../../../../utils/storage";
import { useAppSelector } from "../../../../../store/stateHooks";
import showToast from "../../../../../utils/toast";
const StepThree = ({
  formState,
  handlePrevStep,
  handleFinalSubmit,
  handleCancel,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...formState, civility: "M" },
  });
  const { auth } = useAppSelector((state) => state.authCredential);

  const onSubmit = async (data) => {
    console.log(data);
    try {
      let res = await postAPI(`${api.contrat}/${auth.data.admin_id}`, data);
      showToast("Contrat créé avec succès", "success");
      handleCancel();
    } catch (err) {
      console.error(err);
    }
  };

  const loadEntrepriseOptions = (inputValue, callback) => {
    setTimeout(() => {
      const options = [
        { value: "1", label: "Entreprise 1" },
        { value: "2", label: "Entreprise 2" },
        { value: "3", label: "Entreprise 3" },
      ].filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(options);
    }, 1000);
  };
  const [isDocument, setDocument] = React.useState();
  const [uploadedImg, setUploadedImg] = React.useState(null);
  const uploadedImage = React.useRef();
  const [uploadImg, setUploadImg] = React.useState(null);
  const handleImageData = (e) => {
    setUploadedImg(e.target.files[0]);
    setUploadImg(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="m-4">
      <div className="justify-content-between align-items-center">
        <p>
          {formState
            ? "Modifier les informations du contact."
            : "Renseignez les champs obligatoires concernants les informations du contrat"}
        </p>
        <strong className="mb-4">{`Acteurs et documents (3/3)`}</strong>
        <CustomAsyncSelect
          label="Représentant commercial en charge de la"
          name="commercialReprésentant"
          loadOptions={loadEntrepriseOptions}
          onChange={(selected) =>
            setValue("commercialReprésentant", selected?.label)
          }
          register={register}
          validation={{ required: "Fournisseur est obligatoire" }}
          errors={errors}
        />
        <div className="mt-3 d-flex">
          <label className="form-label fw-normal" style={{ color: "#000" }}>
            Civilité :
          </label>
          <div className="ms-2">
            <input
              type="radio"
              id="male"
              value="M"
              {...register("civility", { required: true })}
              className="form-check-input"
            />
            <label
              className="form-check-label ms-2"
              htmlFor="male"
              style={{ color: "#5a606f" }}
            >
              M
            </label>

            <input
              type="radio"
              id="female"
              value="Mme"
              {...register("civility", { required: true })}
              className="form-check-input ms-5"
            />
            <label
              className="form-check-label ms-2"
              htmlFor="female"
              style={{ color: "#5a606f" }}
            >
              Mme
            </label>
          </div>

          {errors.civility && (
            <p className="text-danger">Civilité est vendeur</p>
          )}
        </div>
        <div className="row">
          <div className="col">
            <InputField
              key="firstName"
              label="* Nom du vendeur"
              name="firstName"
              type="text"
              placeholder="Nom"
              register={register}
              errors={errors}
              validation={{ required: "Nom est obligatoire" }}
            />
          </div>
          <div className="col">
            <InputField
              key="lastName"
              label="* Prénom du vendeur"
              name="lastName"
              type="text"
              placeholder="Prénom"
              register={register}
              errors={errors}
              validation={{ required: "Prénom est obligatoire" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <InputField
              key="email"
              label="* Email du vendeur"
              name="email"
              type="text"
              placeholder="Email du vendeur"
              register={register}
              errors={errors}
              validation={{ required: "Email est obligatoire" }}
            />
          </div>
          <div className="col">
            <InputField
              key="phone"
              label="* Numéro de téléphone du vendeur"
              name="phone"
              type="text"
              placeholder="Numéro de téléphone du vendeur"
              register={register}
              errors={errors}
              validation={{ required: "Prénom est obligatoire" }}
            />
          </div>
        </div>
        <CustomAsyncSelect
          label="Apporteur d’affaire"
          name="businessDeveloper"
          loadOptions={loadEntrepriseOptions}
          onChange={(selected) =>
            setValue("businessDeveloper", selected?.label)
          }
          register={register}
          //   validation={{ required: "Fournisseur est obligatoire" }}
          errors={errors}
        />

        <div className="mt-3">
          <label className="form-label fw-normal" style={{ color: "#000" }}>
            Souhaitez-vous ajouter un document ?
          </label>
          <div className="ms-2">
            <button
              type="button"
              className={`btn ${
                isDocument ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => setDocument(true)}
            >
              Oui
            </button>
            <button
              type="button"
              className={`btn ${
                !isDocument ? "btn-primary" : "btn-outline-secondary"
              } ms-2`}
              onClick={() => setDocument(false)}
            >
              Non
            </button>
          </div>

          {errors.civility && (
            <p className="text-danger">Civilité est vendeur</p>
          )}
        </div>
        {isDocument && (
          <div className="text-center">
            <div
              className="text-center mt-3 py-3 d-flex flex-column justify-content-center align-items-center gap-1"
              style={{ backgroundColor: "#eaeaee", borderRadius: 10 }}
            >
              <i
                className="bi bi-cloud-upload-fill fs-1 p-0 m-0"
                style={{
                  color: "#3252e9",
                  fontFamily: "bold",
                }}
              ></i>
              <div style={{ position: "relative" }}>
                <input
                  type="file"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                  }}
                  ref={uploadedImage}
                  name="image"
                  onChange={handleImageData}
                  accept=".jpg, .jpeg, .png"
                />
                <button type="button" className="btn btn-outline-primary w-100">
                  Parcourir mes fichiers
                </button>
              </div>
              <p className="p-0 m-0">Format accepté: pdf / Taille max: 2MB</p>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between mt-4 gap-3 ">
          <button
            type="button"
            className="btn btn-outline-primary w-50"
            onClick={() => handlePrevStep()}
          >
            Retour
          </button>
          <button type="submit" className="btn btn-primary w-50">
            {"soumettre"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default StepThree;
