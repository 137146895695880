import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { api } from "../utils/storage";
import { postAPI } from "./Services";
import { setRefrashToken, setSessionData } from "./auth.atom";
import { useAppDispatch } from "../store/stateHooks";
import { UserAuthActions } from "../store/appStateSlices/userAuthSlice";
import { AuthActions } from "../store/appStateSlices/AuthCredentialsSlice";
/**
 * Perform a login operation and handle the side effects such as updating local storage,
 * setting refresh tokens, dispatching actions, and invalidating relevant queries.
 * @returns {MutationFunction} - The login mutation function.
 */
const useLoginMutation = () => {
  // Access the query client and navigation function from react-query and react-router-dom
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  /**
   * Mutation function that performs the login.
   * @param {Object} credentials - User credentials for login.
   * @param {string} credentials.username - User's username.
   * @param {string} credentials.password - User's password.
   * @returns {Promise<Object>} - A promise that resolves to the login response.
   */
  const loginMutation = async (credentials) => {
    try {
      const res = await postAPI(api.login, credentials);
      const loginDate = new Date();
      const loginInfo = {
        userName: res?.user?.name,
        loginDate: loginDate.toISOString(),
      };
      localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
      res.user.auth = true;
      dispatch(UserAuthActions.setUserAuth(res.user));
      setRefrashToken(res.refreshToken.token);
      setSessionData(res.access.token);

      return res;
    } catch (error) {
      console.log(error)
      // Throw the error so that it can be caught by the onError callback in useMutation
      throw (
        error?.response?.data?.message ||
        error.message ||
        "Une erreur inconnue s'est produite" // An unknown error occurred
      );
    }
  };

  // Use the useMutation hook to define a login mutation
  return useMutation(loginMutation, {
    /**
     * onSuccess callback executed after a successful login.
     */
    onSuccess: () => {
      // Invalidate relevant queries to trigger a refetch after successful login
      queryClient.invalidateQueries("userData");
      queryClient.invalidateQueries("otherData");
      toast.success("connexion réussie.");
    },
    /**
     * onError callback executed if an error occurs during the login mutation.
     */
    onError: (error) => {
      // Display a more detailed error message
      const errorMessage =
        typeof error === "string"
          ? error
          : error?.message || "Une erreur s'est produite";
      toast.error(errorMessage);
    },
  });
};

/**
 * Perform a signup operation and handle the side effects such as updating local storage,
 * setting refresh tokens, dispatching actions, and invalidating relevant queries.
 * @returns {MutationFunction} - The signup mutation function.
 */

const useSignupMutation = () => {
  // Access the query client and navigation function from react-query and react-router-dom
  const queryClient = useQueryClient();
  /**
   * Mutation function that performs the signup.
   * @param {Object} credentials - User credentials for login.
   * @param {string} credentials.fullName - User's full name.
   * @param {string} credentials.useremailname - User's email.
   * @param {string} credentials.phoneNumber - User's phone number.
   * @param {string} credentials.password - User's password.
   * @param {string} credentials.confirmPassword - User's confirm password.
   * @returns {Promise<Object>} - A promise that resolves to the login response.
   */
  const signupMutation = async (credentials) => {
    const res = await postAPI(api.signup, credentials);
    return res;
  };

  // Use the useMutation hook to define a signup mutation
  return useMutation(signupMutation, {
    /**
     * onSuccess callback executed after a successful signup.
     */
    onSuccess: () => {
      toast.info("inscrivez-vous avec succès !");
    },
    onError: (error) => {
      const errorMessage = error?.message || "Une erreur s'est produite";
      toast.error(errorMessage);
    },
  });
};

export { useSignupMutation, useLoginMutation };
