import { useEffect } from "react";
import { Offer } from "../../hooks/useAPI";
import EmptyBox from "../../UI/EmptyBox";
import OffersBox from "../../UI/OffersBox";
import OffersNavbar from "../../UI/OffersNavbar";
import Sidebar from "../../UI/Sidebar";

import classes from "./index.module.css";
import Footer from "../../UI/Footer";
import OfferCategoryStack from "../../UI/OfferCategoryStack";
import Offerheadtext from "../../UI/Offerheadtext";

interface Props {
  offers: Offer[];
}

function DisplayOffers(props: Props) {
  const offers = props?.offers && [...props.offers].sort(
    (a, b) => a.monthlyPrice - b.monthlyPrice
  );
  useEffect(() => {
    document.documentElement.style.setProperty("--white", "#fff");
  });
  return (
    <>
      <div className='container'>
        <OffersNavbar />
        <div className="row displayOffersContainer">
           

            <div className="col-md-12 col-lg-6">
              <Offerheadtext offerLength={offers?.length ? offers.length : 0}/>
            </div>
            <div className="col-md-12 col-lg-6">
              <Sidebar />
            </div>
          
          <div className="col-md-12 col-lg-12">
            <OfferCategoryStack />
          </div>

          <div className="col-md-12 col-lg-12">
            <div className={classes.container}>
              <OffersBox offers={offers} />
            </div>
          </div>
        </div>
      </div >
      <Footer />
    </>
  );
}

export default DisplayOffers;
