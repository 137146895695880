import * as React from "react";

import { ReactComponent as TabDownSvg } from "../../icons/TabDown.svg";
import { ReactComponent as TimesSvg } from "../../icons/Times.svg";

import { TransitionProps } from "@mui/material/transitions";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface PropsThankYouModel {
  thankYouModel: boolean;
  setThankYouModel: (value: boolean) => void;
}
export default function ThankYouModel({
  thankYouModel,
  setThankYouModel,
}: PropsThankYouModel) {

  return (
    <React.Fragment>
      <>
        {/* <Box
          onClick={handleClickOpen}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "5px",
            cursor: "pointer",
          }}
        >
          <TabDownSvg />
          <Typography sx={{ fontSize: "12px", color: "#455A64" }}>
            Merci beaucoup !
          </Typography>
        </Box> */}

        <Dialog
          open={thankYouModel}
          TransitionComponent={Transition}
          keepMounted
          sx={{
            borderRadius: "24px",
            mx: "auto",
            "& .MuiDialog-paper": {
              borderRadius: "24px",
              width: "438px",
              maxWidth: "438px",
              py: "24px",
              px: "24px",
            },
          }}
          onClose={() => setThankYouModel(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <Box
            onClick={() => setThankYouModel(false)}
            sx={{
              position: "absolute",
              top: "10px",
              right: "20px",
              cursor: "pointer",
            }}
          >
            <TimesSvg />
          </Box>

          <DialogTitle sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontFamily: `"Nunito", sans-serif`,
                fontSize: "28px",
                fontWeight: "700",
                color: "#37474F",
              }}
            >
              Merci beaucoup !
            </Typography>
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Typography
                variant="body2"
                textAlign={"center"}
                color={"#37474F"}
                sx={{ fontSize: "16px", color: "#37474F", opacity: "75%" }}
              >
                Votre demande de rappel a bien été enregistrée. Un de nos
                experts vous rappellera très bientôt.
              </Typography>
            </DialogContentText>

            <Button
              onClick={() => setThankYouModel(false)}
              variant="contained"
              color="primary"
              sx={{
                mt: 4,
                borderRadius: "26.5px",
                width: "100%",
                padding: "12px 30px",
                "&:hover": {
                  color: "#ffffff",
                },
              }}
            >
              Super, merci !
            </Button>
          </DialogContent>
        </Dialog>
      </>
    </React.Fragment>
  );
}
