import { useState } from "react";
import { navigationActions } from "../../store/appStateSlices/navigationSlice";
import { useAppDispatch, useAppSelector } from "../../store/stateHooks";




import { Offer } from "../hooks/useAPI";
import FormNavbar from "../UI/FormNavbar";
import FormPage from "../UI/FormPage";
import DisplayOffers from "./DisplayOffers";
import GatherCredentials from "./GatherCredentials";
import GatherMembers from "./GatherMembers";
import LoadOffers from "./LoadOffers";
import SelectWarranties from "./SelectWarranties";

import ConfirmOffer from "../UI/ConfirmOffer";
import ChatBot from "./ChatBot";
import ClientsSatisfaits from "../UI/clientsview/ClientsSatisfaits";
import Footer from "../UI/Footer";
import KnowMore from "../UI/KnowMore";
import Fag from "../UI/Faq/Fag";
import PageTopText from "../UI/pageTopText";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ProgressBar from "../UI/ProgressBar";

function PageManager() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  const [offers, setOffers] = useState<Offer[] | null>(null);

  const { activeAppPage, activeFormPage } = useAppSelector(
    (state) => state.appState.navigation
  );

  const dispatch = useAppDispatch();

  function offersLoadedHandler(offers: Offer[]) {
    setOffers(offers);
    dispatch(navigationActions.setActiveAppPage(3));
  }

  return (
    <>
 
        <Box sx={{ display: matches ? "flex" : "none", mt: 10, justifyContent: 'center', alignItems: 'center' }}>
          <ProgressBar />
        </Box>
       
         
  

        {activeAppPage === 1 && (
          <FormPage>
            <FormNavbar />
            {activeFormPage === 1 && <GatherMembers />}
            {activeFormPage === 2 && <SelectWarranties />}
            {activeFormPage === 3 && <GatherCredentials />}
          </FormPage>
        )}
        {activeAppPage === 2 && <LoadOffers onLoad={offersLoadedHandler} />}
        {activeAppPage === 3 && <DisplayOffers offers={offers!} />}
        {activeAppPage === 4 && <ConfirmOffer />}
      

        <ChatBot /> 
 
    </>
  );
}

export default PageManager;
