import { useState } from "react";

import { credentialsActions } from "../../../../store/userDataSlices/credentialsSlice";
import { useAppDispatch } from "../../../../store/stateHooks";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import QuestionError from "../../../UI/QuestionError";
import PhoneField from "../../../UI/PhoneField";

interface Props {
  value?: string;
}

function PhoneNumber(props: Props) {
  const [error, setError] = useState<string>();
  const dispatch = useAppDispatch();

  function registerAnswerHandler(value?: string) {
    if (!value) {
      return;
    }

    dispatch(credentialsActions.setPhoneNumber(value));
  }

  function checkAnswerHandler(value?: string) {
    registerAnswerHandler(value);
  }

  return (
    <QuestionBox
      targetState="credentials"
      action="setPhoneNumber"
      scrollToIt={true}
    >
      <FieldLabel>Votre téléphone</FieldLabel>
      <PhoneField
        placeHolder="Ex: 06 10 00 10 00"
        getState={checkAnswerHandler}
        value={props.value ? props.value : undefined}
      >
        {error && <QuestionError error={error} />}
      </PhoneField>
    </QuestionBox>
  );
}

export default PhoneNumber;
