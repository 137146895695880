import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import TextFieldGroup from "../../../UI/TextFieldGroup";
import TextField from "../../../UI/TextField";
import { useAppDispatch } from "../../../../store/stateHooks";
import { credentialsActions } from "../../../../store/userDataSlices/credentialsSlice";

interface Props {
  values: string[];
}

function Name(props: Props) {
  const dispatch = useAppDispatch();

  function firstAnswerHandler(value: string) {
    dispatch(credentialsActions.setFirstName(value));
  }

  function secondAnswerHandler(value: string) {
    dispatch(credentialsActions.setLastName(value));
  }

  return (
    <QuestionBox targetState="credentials">
      <FieldLabel>Votre prénom et nom</FieldLabel>
      <TextFieldGroup>
        <TextField
          placeHolder="Prénom"  
          getState={firstAnswerHandler}
          value={props.values[0]}
        />
        <TextField
          placeHolder="nom de famille"
          getState={secondAnswerHandler}
          value={props.values[1]}
        />
      </TextFieldGroup>
    </QuestionBox>
  );
}

export default Name;
