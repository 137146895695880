import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMemo, useState } from "react";
import lang from "../../../utils/language/french";
import { navigationActions } from "../../../store/appStateSlices/navigationSlice";
import { useAppDispatch } from "../../../store/stateHooks";

function SideBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [activeMenu, setActiveMenu] = useState(null);

  const sidebarData = useMemo(
    () => [
      {
        name: lang.common.dashboard, // Dashboard
        icon: "bi bi-grid",
        path: "/admin/dashboard",
      },
      {
        name: "Mes offres",
        icon: "bi bi-database",
        path: "/admin/mes-offres",
      },
      {
        name: lang.common.manageOffers, // Manage offers
        icon: "bi bi-columns",
        path: "/admin/submitted-offers",
      },
      {
        name: lang.common.manageUsersQueries, // Manage Users Queries
        icon: "bi bi-layout-text-window-reverse",
        path: "/admin/users/queries",
      },
      {
        name: "CRM",
        icon: "bi bi-journal-medical",
        submenu: [
          {
            name: "Entreprises",
            icon: "bi bi-person",
            path: "/admin/entreprise",
          },
          {
            name: "Contacts",
            icon: "bi bi-people",
            path: "/admin/contact",
          },
        ],
      },
      {
        name: "Portefeuille",
        icon: "bi bi-bricks",
        submenu: [
          {
            name: "Contrats",
            icon: "bi bi-card-checklist",
            path: "/admin/portefeuille-contrats",
          },
          {
            name: "Portefeuille",
            icon: "bi bi-file-earmark-diff-fill",
            path: "/admin/devis",
          },
        ],
      },
    ],
    []
  );

  const toggleSubMenu = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        <div className="mt-2">
          <button
            type="button"
            className="btn btn-primary mx-1 fw-bold creeeUn"
            onClick={() => {
              dispatch(navigationActions.setActiveAppPage(1));
              navigate("/");
            }}
          >
            + <span>Nouveau devis</span>
          </button>
        </div>
        {sidebarData.map((menu) => (
          <li className="nav-item" key={menu.name}>
            {menu.submenu ? (
              <>
                <div
                  // href="#!"
                  className={`nav-link ${
                    activeMenu === menu.name ? "" : "collapsed"
                  }`}
                  onClick={() => toggleSubMenu(menu.name)}
                >
                  <i className={menu.icon} />
                  <span>{menu.name}</span>
                  <i
                    className={`bi ${
                      activeMenu === menu.name
                        ? "bi-chevron-up"
                        : "bi-chevron-down"
                    } ms-auto`}
                  ></i>
                </div>
                <ul
                  className={`submenu ${
                    activeMenu === menu.name ? "active" : "collapse"
                  }`}
                >
                  {menu.submenu.map((submenuItem) => (
                    <li key={submenuItem.path}>
                      <Link
                        to={submenuItem.path}
                        className={`nav-link ${
                          location.pathname === submenuItem.path
                            ? ""
                            : "collapsed"
                        }`}
                      >
                        <i className={submenuItem.icon} />
                        <span>{submenuItem.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <Link
                to={menu.path}
                className={`nav-link ${
                  location.pathname === menu.path ? "" : "collapsed"
                }`}
              >
                <i className={menu.icon} />
                <span>{menu.name}</span>
              </Link>
            )}
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default SideBar;
