import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import InputField from "../../../../users/components/inputField";
import CustomAsyncSelect from "../../../../users/components/CustomAsyncSelect";
import Icon from "../../../../UI/Icon";
import { parse } from "date-fns";
import DateField from "../../../../UI/DateField";
import "./styles.css";
import { api } from "../../../../../utils/storage";
import { useAppSelector } from "../../../../../store/stateHooks";
import { getAPI } from "../../../../../apis/Services";
const StepTwo = ({
  formState = {},
  handleNextStep,
  handlePrevStep,
  handleCancel,
}) => {
  const { auth } = useAppSelector((state) => state.authCredential);
  const defaultValues = {
    entreprise: formState?.entreprise || "",
    souscripteur: formState.souscripteur || "",
    fournisseur: formState?.fournisseur || "Abeille Assurances",
    produit: formState?.produit || "RC Professionnelle",
    reference: formState.reference || "",
    montantHT: formState.montantHT || "",
    montantTTC: formState.montantTTC || "",
    fraisSouscription: formState.fraisSouscription || "",
    fraisRecurrents: formState.fraisRecurrents || "",
    fractionnement: formState.fractionnement || "",
    dateEffet: formState.dateEffet || "",
    tauxCommission: formState.tauxCommission || "",
    commissionAnnuelle: formState.commissionAnnuelle || "",
    commissionAnnuelleFrais: formState.commissionAnnuelleFrais || "",
    statutContrat: formState.statutContrat || "",
    modePaiement: formState.modePaiement || "",
  };
  const [isSouscripteur, setSouscripteur] = useState(false);
  const [commissionAnnuelle, setCommissionAnnuelle] = useState(0);
  const [commissionAnnuelleFrais, setCommissionAnnuelleFrais] = useState(0);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    handleNextStep({ ...data, isSouscripteur: isSouscripteur });
  };

  const loadFractionnementOptions = (inputValue, callback) => {
    // Simulate async call with a setTimeout or replace with an API call
    setTimeout(async () => {
      const options = [
        { value: "1", label: "NeoLiane" },
        { value: "2", label: "FMA" },
        { value: "3", label: "April" },
        { value: "4", label: "Alptis" },
        { value: "5", label: "praeconis" },
      ].filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(options);
    }, 1000);
  };

  const loadEntrepriseOptions = async (inputValue, callback) => {
    try {
      // Fetch options from the API using the subscriber's admin_id
      const res = await getAPI(
        `${api.subscriberSearch}/${auth.data.admin_id}?query=${inputValue}`
      );

      if (res.status === 200) {
        // Map the response data to label-value pairs expected by the select component
        const options = res.data.map((item) => ({
          value: item._id,
          label: `${item.firstName} ${item.lastName}`,
        }));

        // Filter options locally based on inputValue
        const filteredOptions = options.filter((option) =>
          option.label.toLowerCase().includes(inputValue.toLowerCase())
        );

        callback(filteredOptions);
      } else {
        console.log(res);
      }
    } catch (err) {
      console.log(err);
      callback([]); // Return an empty array in case of error
    }
  };

  const loadFournisseurOptions = (inputValue, callback) => {
    // Simulate async call with a setTimeout or replace with an API call
    setTimeout(() => {
      const options = [
        { value: "mensuel", label: "Mensuel" },
        { value: "trimestriel", label: "Trimestriel" },
        { value: "annuel", label: "Annuel" },
      ].filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(options);
    }, 1000);
  };

  const loadStatusOptions = (inputValue, callback) => {
    setTimeout(() => {
      const options = [
        { value: "1", label: "Actif" },
        { value: "2", label: "Annulé" },
        { value: "3", label: "En attenuate de signature" },
        { value: "4", label: "En attente de paiement" },
        { value: "5", label: "Résilié" },
        { value: "6", label: "Devis" },
        { value: "7", label: "Expire" },
      ].filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(options);
    }, 1000);
  };
  const [date, setDate] = React.useState();
  const answerHandler = function (value) {
    if (!value) {
      return;
    }

    setValue("dateEffet", value.toISOString());
    setDate(value);
    // if (props.getState) {
    //   props.getState(value.toISOString());
    // }
  };
  // Watch values for montantHT, tauxCommission, fraisRecurrents
  const montantHT = watch("montantHT");
  const tauxCommission = watch("tauxCommission");
  const fraisRecurrents = watch("fraisRecurrents");

  // Calculate Commission Annuelle
  useEffect(() => {
    if (montantHT && tauxCommission) {
      const calculatedCommission = (montantHT * (tauxCommission / 100)).toFixed(
        2
      );
      setCommissionAnnuelle(calculatedCommission);
      setValue("commissionAnnuelle", calculatedCommission);
    }
  }, [montantHT, tauxCommission, setValue]);

  // Calculate Commission Annuelle + Frais Récurrents
  useEffect(() => {
    const totalCommissionFrais = (
      parseFloat(commissionAnnuelle) + parseFloat(fraisRecurrents || 0)
    ).toFixed(2);
    setCommissionAnnuelleFrais(totalCommissionFrais);
    setValue("commissionAnnuelleFrais", totalCommissionFrais);
  }, [commissionAnnuelle, fraisRecurrents, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="m-4">
      <div className="justify-content-between align-items-center">
        <p>
          {formState
            ? "Modifier les informations du contact."
            : "Renseignez les champs obligatoires concernants les informations du contrat"}
        </p>
        <strong>{`Le produit (2/3)`}</strong>
        <CustomAsyncSelect
          label="L'entreprise assurée"
          name="entreprise"
          loadOptions={loadFractionnementOptions}
          onChange={(selected) => setValue("entreprise", selected?.label)}
          register={register}
          validation={{ required: "L'entreprise assurée est obligatoire" }}
          errors={errors}
        />
        {isSouscripteur ? (
          <>
            <div className="mt-3 d-flex">
              <label className="form-label fw-normal" style={{ color: "#000" }}>
                Civilité :
              </label>
              <div className="ms-2">
                <input
                  type="radio"
                  id="male"
                  value="M"
                  {...register("souscripteurCivility", { required: true })}
                  className="form-check-input"
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="male"
                  style={{ color: "#5a606f" }}
                >
                  M
                </label>

                <input
                  type="radio"
                  id="female"
                  value="Mme"
                  {...register("souscripteurCivility", { required: true })}
                  className="form-check-input ms-5"
                />
                <label
                  className="form-check-label ms-2"
                  htmlFor="female"
                  style={{ color: "#5a606f" }}
                >
                  Mme
                </label>
              </div>

              {errors.civility && (
                <p className="text-danger">Civilité est vendeur</p>
              )}
            </div>
            <div className="row">
              <div className="col">
                <InputField
                  key="souscripteurFirstName"
                  label="* Nom du souscripteur"
                  name="souscripteurFirstName"
                  type="text"
                  placeholder="Nom"
                  register={register}
                  errors={errors}
                  validation={{ required: "Veuillez saisir le nom." }}
                />
              </div>
              <div className="col">
                <InputField
                  key="souscripteurLastName"
                  label="* Prénom du souscripteur"
                  name="souscripteurLastName"
                  type="text"
                  placeholder="Prénom"
                  register={register}
                  errors={errors}
                  validation={{ required: "Veuillez saisir le prénom." }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <InputField
                  key="souscripteurEmail"
                  label="* Email du souscripteur"
                  name="souscripteurEmail"
                  type="text"
                  placeholder="Email du souscripteur"
                  register={register}
                  errors={errors}
                  validation={{ required: "Veuillez saisir l'email." }}
                />
              </div>
              <div className="col">
                <InputField
                  key="souscripteurPhone"
                  label="* Téléphone du souscripteur"
                  name="souscripteurPhone"
                  type="text"
                  placeholder="Téléphone du souscripteur"
                  register={register}
                  errors={errors}
                  // validation={{ required: "Prénom est obligatoire" }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <CustomAsyncSelect
              label="Souscripteur"
              name="souscripteur"
              loadOptions={loadEntrepriseOptions}
              onChange={(selected) => {
                setValue("souscripteur", selected?.label);
                setValue("souscripteurId", selected?.value);
              }}
              register={register}
              validation={{ required: "Souscripteur est obligatoire" }}
              errors={errors}
            />
          </>
        )}

        <button
          type="button"
          className="btn btn-link"
          onClick={() => setSouscripteur(!isSouscripteur)}
        >
          {isSouscripteur
            ? "Rechercher un contact existant depuis le CRM"
            : "Ajouter manuellement le contact"}
        </button>

        {/* Fournisseur */}
        {/* <InputField
        label="Fournisseur"
        name="fournisseur"
        type="text"
        register={register}
        disabled
        defaultValue="Abeille Assurances"
      />

      <InputField
        label="Produit"
        name="produit"
        type="text"
        register={register}
        disabled
        defaultValue="RC Professionnelle"
      /> */}

        {/* Référence du contrat */}
        <InputField
          label="Référence du contrat"
          name="reference"
          type="text"
          register={register}
          validation={{ required: "Référence du contrat est obligatoire" }}
          errors={errors}
        />
        <div className="row">
          {/* Montant HT annuel */}
          <div className="col">
            <InputField
              label="Montant HT annuel"
              name="montantHT"
              type="number"
              register={register}
              validation={{ required: "Montant HT annuel est obligatoire" }}
              errors={errors}
            />
          </div>
          <div className="col">
            <InputField
              label="Montant TTC annuel"
              name="montantTTC"
              type="number"
              register={register}
              validation={{ required: "Montant TTC annuel est obligatoire" }}
              errors={errors}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <InputField
              label="Frais de souscription"
              name="fraisSouscription"
              type="number"
              register={register}
              validation={{ required: "Frais de souscription est obligatoire" }}
              errors={errors}
            />
          </div>
          <div className="col">
            <InputField
              label="Frais récurrents"
              name="fraisRecurrents"
              type="number"
              register={register}
              validation={{ required: "Frais récurrents est obligatoire" }}
              errors={errors}
            />
          </div>
        </div>

        <div className="row">
          <div className="col">
            <CustomAsyncSelect
              label="Fractionnement"
              name="fractionnement"
              loadOptions={loadFournisseurOptions}
              onChange={(selected) =>
                setValue("fractionnement", selected?.label)
              }
              register={register}
              validation={{ required: "Fractionnement est obligatoire" }}
              errors={errors}
            />
          </div>
          <div className="col">
            <strong
              className="form-label  "
              style={{ color: "#37474F", fontWeight: "300", fontSize: "12px" }}
            >
              {"Date d'effet"}
            </strong>
            <DatePicker
              onChange={answerHandler}
              value={date}
              format="dd/MM/yyyy"
              dayPlaceholder="JJ"
              monthPlaceholder="MM"
              yearPlaceholder="AAAA"
              minDate={new Date()}
              maxDate={new Date(2998, 11, 31)}
              calendarIcon={
                <Icon name="calendarOutline" className="calendar-icon" />
              }
              clearIcon={null}
              showLeadingZeros={true}
              openCalendarOnFocus={false}
              locale="fr"
            />
          </div>
        </div>
        <InputField
          label="Taux de commission"
          name="tauxCommission"
          type="number"
          step="0.01"
          register={register}
          validation={{ required: "Taux de commission est obligatoire" }}
          errors={errors}
          append="%"
        />
        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="commissionLineaire"
          />
          <label className="form-check-label" htmlFor="commissionLineaire">
            Le taux de commission est linéaire
          </label>
        </div>

        <div className="row">
          <div className="col">
            <InputField
              label="Commission annuelle"
              name="commissionAnnuelle"
              type="number"
              register={register}
              validation={{ required: "Commission annuelle est obligatoire" }}
              errors={errors}
              disabled
            />
          </div>
          <div className="col">
            <InputField
              label="Commission annuelle + frais récurrents"
              name="commissionAnnuelleFrais"
              type="number"
              register={register}
              validation={{
                required:
                  "Commission annuelle + frais récurrents est obligatoire",
              }}
              errors={errors}
              disabled
            />
          </div>
        </div>
        <CustomAsyncSelect
          label="Statut du contrat"
          name="statutContrat"
          loadOptions={loadStatusOptions}
          onChange={(selected) => setValue("statutContrat", selected?.label)}
          register={register}
          validation={{ required: "Statut du contrat est obligatoire" }}
          errors={errors}
        />

        <InputField
          label="Mode de paiement"
          name="modePaiement"
          type="text"
          register={register}
          validation={{ required: "Mode de paiement est obligatoire" }}
          errors={errors}
        />
        <div className="d-flex justify-content-between mt-4 gap-3 ">
          <button
            type="button"
            className="btn btn-outline-primary w-50"
            onClick={() => handlePrevStep()}
          >
            Retour
          </button>
          <button type="submit" className="btn btn-primary w-50">
            {"Suivant"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default StepTwo;
