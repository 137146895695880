import React from 'react'
import Grid from '@mui/material/Grid';
import { Box, Paper, styled, Typography } from '@mui/material';

import { ReactComponent as PeopleSvg } from '../../icons/people.svg';
import { ReactComponent as SecuritySvg } from '../../icons/security.svg';
import { ReactComponent as MoneySvg } from '../../icons/money.svg';
import { ReactComponent as CotesSvg } from '../../icons/cotes.svg';




export default function ClientsSatisfaits() {

    const BOxData = [
        {
            Icons: <PeopleSvg />,
            bgColor: '#EBF4FA',
            text: 'Rejoignez plus de 8,000 clients satisfaits'
        },
        {
            Icons: <SecuritySvg />,
            bgColor: '#E0FAF3',
            text: 'Votre expérience 100% sécurisée'
        },
        {
            Icons: <MoneySvg />,
            bgColor: '#EBF4FA',
            text: 'Économisez 300€ en moyenne'
        },
        {
            Icons: <CotesSvg />,
            bgColor: '#E0FAF3',
            text: 'Nos experts, toujours à vos côtés'
        }
    ]
    return (
        <div className='container'> 
            <Box sx={{ flexGrow: 1, my: {xs: 3, sm: 5} }}>
                <Grid container spacing={4}>
                    {
                        BOxData.map((item, index) => {
                            return (
                                <Grid key={index} item xs={12} sm={6} md={3}>
                                    <Paper sx={{ textAlign: 'center', height: '180px', borderRadius: '16px', bgcolor: item.bgColor, display: 'flex', flexDirection: 'column', gap: 1.5, justifyContent: 'center', alignItems: 'center', px: '12px' }}>

                                        <Box sx={{ backgroundColor: '#006CB7', mx: 'auto', width: 48, height: 48, borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {item.Icons}
                                        </Box>

                                        <Typography sx={{ fontWeight: '500', color: '#37474F', fontFamily: '"Poppins", sans-serif' }}>{item.text}</Typography>
                                    </Paper>

                                </Grid>
                            )
                        })
                    }


                </Grid>

            </Box>
        </div>

    )
}
