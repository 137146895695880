import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import { color } from 'framer-motion';
import { useAppSelector } from '../../store/stateHooks';

export default function Offerheadtext({offerLength}: number | any) {
    const subscriberData = useAppSelector((state) => state.userData);


    return (
        <Stack spacing={2} direction="column">
            <Typography
                sx={{ fontSize: '28px', fontWeight: '700', color: '#37474F', }}>
                {`${subscriberData.credentials.firstName}, ${subscriberData.credentials.lastName} avons sélectionné les`} <Box component='span' sx={{ color: '#006CB7' }}> {`${offerLength} meilleures offres`}</Box>  pour vous, <Box component='span' sx={{ color: '#006CB7' }}> adaptées à vos besoins.</Box>
            </Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: '400', opacity: '75%', color: '#37474F' }}>
                Vous avez trouvé une offre qui vous convient ? Souscrivez en quelques clics, prenez un rendez-vous ou laissez-vous rappeler par un conseiller.
            </Typography>
        </Stack>
    );
}