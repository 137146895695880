// src/components/Dashboard.js
import React from "react";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { getAPI } from "../../../apis/Services";
import lang from "../../../utils/language/french";
import showToast from "../../../utils/toast";
import { removeSessionData } from "../../../apis/auth.atom";
import classes from "../../UI/Offer.module.css";
import getWarrantiesIcons from "../../helpers/getWarrantiesIcons";
import WarrantyLevel from "../../UI/WarrantyLevel";
import { api } from "../../../utils/storage";
import { UserAuthActions } from "../../../store/appStateSlices/userAuthSlice";
import { useAppDispatch } from "../../../store/stateHooks";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { getWarrantyLevelName } from "../../../utils/warrantyLevel";

import { ReactComponent as EmailSvg } from '../icons/Email.svg';
import { ReactComponent as PhoneSvg } from '../icons/Phone.svg';
import { ReactComponent as CalSvg } from '../icons/Cal.svg';
import { ReactComponent as CardSvg } from '../icons/card.svg';
import { ReactComponent as LocationSvg } from '../icons/Location.svg';
import { ReactComponent as GenderSvg } from '../icons/gender.svg';
import { ReactComponent as TabDownSvg } from "../../icons/TabDown.svg";
import { ReactComponent as PlusDinforSvg } from "../../icons/plusDinfor.svg";

import { ReactComponent as SocialSvg } from '../icons/Social.svg';
import "./style.css";
import { Box, Stack, Typography } from "@mui/material";

function Dashboard() {
  const [rowData, setRowData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const warranties = getWarrantiesIcons();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  React.useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await getAPI(api.myOffer);
        if (res.status === 200) {
          // Function to update offers with warranties
          const result = res.offers.map((offer) => {
            // Clone the offer to avoid mutating the original object
            const controlledOffer = { ...offer };
            // Check if subscriber data exists and is valid
            if (
              controlledOffer.subscribers &&
              controlledOffer.subscribers.warranties
            ) {
              controlledOffer.warranties = {
                medicalCare: {
                  level: getWarrantyLevelName(
                    controlledOffer.subscribers.warranties.medicalCare?.level
                  ),
                },
                dental: {
                  level: getWarrantyLevelName(
                    controlledOffer.subscribers.warranties.dental?.level
                  ),
                },
                visual: {
                  level: getWarrantyLevelName(
                    controlledOffer.subscribers.warranties.visual?.level
                  ),
                },
                hearing: {
                  level: getWarrantyLevelName(
                    controlledOffer.subscribers.warranties.hearing?.level
                  ),
                },
                hospitalization: {
                  level: getWarrantyLevelName(
                    controlledOffer.subscribers.warranties.hospitalization
                      ?.level
                  ),
                },
              };
            }
            return controlledOffer;
          });

          setRowData(result);
        } else {
          showToast(res.message, "error");
        }
      } catch (err) {
        showToast(err.message, "error");
        const user = {
          auth: false,
        };
        dispatch(UserAuthActions.setUserAuth(user));
        removeSessionData();
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const OfferCard = ({ props }) => {
    const levels = Object.values(props?.warranties).map((warranty) => {
      return warranty.level;
    });
    return (
      <motion.div
        className="card shadow-sm bg-white p-1 mb-4"
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 300 }}
        onClick={() => navigate(`/offer-details/${props._id}`)}
      >
        <div className="d-flex justify-content-between flex-column flex-lg-row align-items-center gap-3">
          {/* Subscriber Details */}
          <div
            className="card border-light p-2"
            style={{ backgroundColor: '#F6F6F9', boxShadow: 'none', marginBottom: 0, minWidth: '280px' }}
          >
            <div className="card-body">
              {props.subscribers ? (
                <>
                  <h5 className="card-title mb-2 p-0">
                    {props.subscribers.firstName} {props.subscribers.lastName} :{" "}
                    {props.subscribers.status}
                  </h5>
                  <div className="d-flex gap-2 mb-2 align-items-center">
                    <EmailSvg />
                    <p className="card-text">
                      <strong>Email:</strong> {props.subscribers.email}
                    </p>
                  </div>

                  <div className="d-flex gap-2 mb-2 align-items-center">
                    <PhoneSvg />
                    <p className="card-text">
                      <strong>Téléphone:</strong> {props.subscribers.phoneNumber}
                    </p>
                  </div>
                  <div className="d-flex gap-2 mb-2 align-items-center">
                    <CalSvg />
                    <p className="card-text ">
                      <strong>Date de naissance:</strong>{" "}
                      {new Date(props.subscribers.birthDate).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="d-flex gap-2 mb-2 align-items-center">
                    <GenderSvg />
                    <p className="card-text">
                      <strong style={{ marginLeft: 5 }}>Genre:</strong>{" "}
                      {props.subscribers.gender}
                    </p>
                  </div>
                  <div className="d-flex gap-2 mb-2 align-items-center">
                    <LocationSvg />
                    <p className="card-text">
                      <strong>Ville:</strong> {props.subscribers.city},{" "}
                      {props.subscribers.postalCode}
                    </p>
                  </div>
                  <div className="d-flex gap-2 mb-2 align-items-center">
                    <CardSvg />
                    <p className="card-text">
                      <strong>Activité:</strong> {props.subscribers.activity}
                    </p>
                  </div>
                  <div className="d-flex gap-2 mb-2 align-items-center">
                    <SocialSvg />
                    <p className="card-text">
                      <strong>Régime social:</strong>{" "}
                      {props.subscribers.socialRegime}
                    </p>
                  </div>




                  {/* <p className="card-text">
                    <strong>Gender:</strong> {props.subscribers.gender}
                  </p> */}

                  {/* <p className="card-text">
                    <strong>Status:</strong> {props.subscribers.status}
                  </p> */}

                </>
              ) : (
                <p className="text-center">No subscribers available.</p>
              )}
            </div>

          </div>
          <div className="d-flex flex-column w-100 align-items-start gap-3 px-lg-0 px-4 ">
            <div className="w-100 d-flex justify-content-between  flex-column flex-sm-row align-items-sm-center align-items-start gap-3">

              {/* Provider Information */}
              <div className="d-flex flex-column flex-grow-1 ">
                <img
                  className={classes.logo}
                  style={{ width: "10rem" }}
                  src={`${process.env.PUBLIC_URL}/assets/img/${props.provider}-Logo.png`}
                  alt={`${props.provider} Logo`}
                />
                <h6 className="mt-3 mb-2 text-left">
                  {props.name || props.label || props.provider}
                </h6>
                {props.code && <p className="text-muted codeFse text-center">{props.code}</p>}
              </div>

              {/* Warranty Information */}
              <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
                <div className="d-flex align-items-center justify-content-center gap-2">
                  {warranties.map((warranty, i) => (
                    <WarrantyLevel
                      key={nanoid(6)}
                      icon={warranty.icon}
                      level={levels[i]}
                    />
                  ))}
                </div>
              </div>

              {/* Price Information */}
              <div className="text-end flex-shrink-0 pe-4">
                <div className="d-flex justify-content-center align-items-baseline" style={{ gap: '4px' }}>
                  <h4 className="fw-bold" style={{ color: '#2D4E6F' }}>
                    {props.monthlyPrice} €
                  </h4>
                  <small style={{ color: '#2D4E6F', fontSize: '12px' }}> / mois</small>
                </div>
                <small style={{ color: '#37474F', fontSize: '12px' }}>
                  Budget estimé: {Math.round(props.monthlyPrice * 12)} €/ans
                </small>
                <div className={classes["informations"]}>
                  <Stack
                    direction={'row'}
                    spacing={{ xs: 2, sm: 4 }}
                    mt={5}
                    sx={{
                      justifyContent: "start",
                      alignItems: "center",
                      opacity: "75%",
                    }}
                  >
                    <Box>
                      <PlusDinforSvg />
                    </Box>
                    <p className="mb-0 ms-2">Plus d'informations</p>
                  </Stack>
                </div>
              </div>

            </div>

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 2, sm: 4 }}
              mt={3}
              sx={{
                justifyContent: "start",
                alignItems: "center",
                opacity: "75%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <TabDownSvg />
                <Typography sx={{ fontSize: "12px", color: "#455A64" }}>
                  Tableau de garanties
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <TabDownSvg />
                <Typography sx={{ fontSize: "12px", color: "#455A64" }}>
                  Conditions générales
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <TabDownSvg />
                <Typography sx={{ fontSize: "12px", color: "#455A64" }}>
                  Fiche d’information
                </Typography>
              </Box>

            </Stack>

          </div>
        </div>
      </motion.div >
    );
  };
  return (
    <main id="main" className="main">
      <div className="justify-content-center">
        <div className="">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <LoadingSpinner />
            </div>
          ) : rowData.length === 0 ? (
            <div>No Offer you have seleceted now....</div>
          ) : (
            rowData.map((offer) => (
              <div key={offer._id}>
                <OfferCard props={offer} />
              </div>
            ))
          )}
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
